import React                           from 'react';
import { useTranslation }              from 'react-i18next';
import useMerchantWhitelistManagement  from 'pages/CardDetailsPage/tabs/ThreeDSTab/useMerchantWhitelistManagement';
import Dialog                          from '@mui/material/Dialog';
import DialogTitle                     from '@mui/material/DialogTitle';
import DialogContent                   from '@mui/material/DialogContent';
import DialogActions                   from '@mui/material/DialogActions';
import Button                          from '@mui/material/Button';
import Alert                           from 'components/Alert';
import PropTypes                       from 'prop-types';
import AddMerchantForm                 from './AddMerchantForm';
import FormWithOtpField                from 'pages/CardDetailsPage/tabs/DetailsAndOperationsTab/FormWithOtpField';
import { OTP_TYPES }                   from 'pages/CardDetailsPage/tabs/DetailsAndOperationsTab/detailsAndOperationsTabConst';
import useStyles                       from './addMerchantDialogStyles';
import { OTP_FIELD_NAME }              from 'pages/CardDetailsPage/tabs/DetailsAndOperationsTab/OTPField/OTPField';
import useDialogCommonStateAndHandlers from 'pages/CardDetailsPage/tabs/useDialogCommonStateAndHandlers';


function AddMerchantDialog(props) {

  const { classes } = useStyles();
  const { t }       = useTranslation(['common', 'cardDetails']);

  const [selectedMerchantOption, setSelectedMerchantOption] = React.useState(undefined);
  const [maxAmount, setMaxAmount]                           = React.useState(undefined);

  const { addMerchantToWhitelist, inProgress } = useMerchantWhitelistManagement();

  const translKeys = {
    onSuccess: 'addingToWhitelistSuccessful',
    onError:   'addingToWhitelistFailed',
  };

  const {
          changeWasSuccessful,
          alert,

          showValidityMessages,
          setShowValidityMessages,

          formRef,

          setAlertWrapper,
          onSuccess,
          onError,
          onFormInvalid,
        } = useDialogCommonStateAndHandlers(props.closeDialog, translKeys);

  /**
   * Using `<form>` even though values are _controlled_ to leverage
   * browser's automated validation.
   */
  function onSubmit(evt) {
    evt.preventDefault();

    const form     = evt.target;
    const formData = new FormData(form);
    const otp      = formData.get(OTP_FIELD_NAME);

    const toBeSubmitted = {
      merchant: {
        ...selectedMerchantOption.value,
      },
      otp,
    };
    if (maxAmount) {
      toBeSubmitted.merchant.maxAmount = maxAmount;
    }

    addMerchantToWhitelist(toBeSubmitted, onSuccess, onError);
  }


  return (
    <Dialog
      open={true}
      fullWidth={true}
      maxWidth={'md'}
      onClose={props.closeDialog}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle>
        {t('cardDetails:addMerchantToWhitelist.title')}
      </DialogTitle>
      {!alert &&
        <>
          <DialogContent dividers>
            <FormWithOtpField
              formRef={formRef}
              onSubmit={onSubmit}
              onInvalid={onFormInvalid}
              onChange={() => setShowValidityMessages(true)}
              showValidityMessages={showValidityMessages}
              disableSubmit={!Boolean(selectedMerchantOption) || inProgress}
              otpType={OTP_TYPES.addMerchantToWhitelist}
            >
              <AddMerchantForm
                merchantOption={selectedMerchantOption}
                setSelectedMerchantOption={setSelectedMerchantOption}
                maxAmount={maxAmount}
                setMaxAmount={setMaxAmount}
                showValidityMessages={showValidityMessages}
              />
            </FormWithOtpField>
          </DialogContent>
          <DialogActions>
            <Button
              id='cancelButton'
              onClick={props.closeDialog}
              color='primary'
              variant='outlined'
            >
              {changeWasSuccessful === undefined ? t('common:cancelButton') : t('common:closeButton')}
            </Button>
          </DialogActions>
        </>
      }

      {alert &&
        <DialogContent>
          <Alert alert={alert} setAlert={setAlertWrapper}/>
        </DialogContent>
      }
    </Dialog>
  );
}

AddMerchantDialog.propTypes = {
  closeDialog: PropTypes.func.isRequired,
};

export default AddMerchantDialog;