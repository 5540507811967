import React                             from 'react';
import { commonOTPErrorHandledWithForm } from 'rest/const';
import { useTranslation }                from 'react-i18next';


/**
 * This hook allows to remove code duplication.
 * @param closeDialog function which closes dialog window
 * @param alertTranslKeys translation keys for alerts, `'success'`
 * and `'failure'` by default
 * @returns {{
 * changeWasSuccessful: boolean || undefined,
 * setChangeWasSuccessful: (value: boolean || undefined) => void,
 * formRef: React.MutableRefObject<undefined>,
 * onError: function,
 * onFormInvalid: function,
 * alert: object || undefined,
 * setAlertWrapper: function,
 * showValidityMessages: boolean,
 * setShowValidityMessages: (value: (((prevState: boolean) => boolean) | boolean)) => void,
 * }}
 */
export default function useDialogCommonStateAndHandlers(closeDialog, alertTranslKeys = undefined) {

  const { t } = useTranslation(['common', 'cardDetails']);

  /**
   * contract: undefined -> no change was made
   */
  const [changeWasSuccessful, setChangeWasSuccessful] = React.useState(undefined);
  const [alert, setAlert]                             = React.useState(undefined);

  const [showValidityMessages, setShowValidityMessages] = React.useState(false);

  const formRef = React.useRef();

  function setAlertWrapper() {
    if (changeWasSuccessful === true)
      closeDialog();
    else
      setAlert(undefined);
  }

  function onSuccess() {
    setChangeWasSuccessful(true);
    setAlert({
      msgTranslKey: alertTranslKeys?.onSuccess || 'success',
      type:         'success',
    });
  }

  function onError(err) {
    setChangeWasSuccessful(false);

    if (commonOTPErrorHandledWithForm(err, formRef, t, setAlert)) {
      return;
    }

    setAlert({
      msgTranslKey: alertTranslKeys?.onError || 'failure',
      type:         'error',
    });
  }

  function onFormInvalid(evt) {
    evt.preventDefault();  //stops tooltips displayed by browser
    setShowValidityMessages(true);
  }


  return {
    changeWasSuccessful,
    setChangeWasSuccessful,

    alert,

    showValidityMessages,
    setShowValidityMessages,

    formRef,

    setAlertWrapper,
    onSuccess,
    onError,
    onFormInvalid,
  };
}