import { getCookie } from 'utils/cookieUtils';
import { setCookie } from 'utils/cookieUtils';


export const TAB_ID_SS_KEY      = 'tabId';
export const TAB_ID_COOKIE_NAME = TAB_ID_SS_KEY;

//Testing for disabled in Firefox:
// disabling sessionStorage: about:config -> dom.storage.enabled
// disabling all cookies: search for cookies in Preferences, pick
// custom protection and set blocking all cookies


/**
 * Checks if current browser's tab is the one that is allowed to
 * work. Doesn't create or save new tabId.
 * @throws SessionError
 */
export function checkIfItsTheAllowedTab() {
  checkAssumptions();
  const { tabIdFromSS, tabIdFromCookies } = getTabIds();

  if (!tabIdFromSS && !tabIdFromCookies) {
    throw new FreshTabError('Tab IDs from both session storage and cookies are falsey')
  }
  if (tabIdFromSS !== tabIdFromCookies) {
    throw new MultipleTabsError('The app is opened in more than one tab')
  }
}

export function replaceTabSessionId() {
  const tabId = generateId();
  window.sessionStorage.setItem(TAB_ID_SS_KEY, tabId);
  setCookie(TAB_ID_COOKIE_NAME, tabId);
}

function checkAssumptions() {
  if (window.sessionStorage == null)
    throw new SessionStorageError('Session storage is disabled');
  if (!navigator.cookieEnabled)
    throw new CookiesDisabledError('Cookies are disabled');
}

function getTabIds() {
  const tabIdFromSS      = window.sessionStorage.getItem(TAB_ID_SS_KEY) || null;
  const tabIdFromCookies = getCookie(TAB_ID_COOKIE_NAME) || null; // '|| null' for consistency
  return { tabIdFromSS, tabIdFromCookies };
}

export class SessionError extends Error {
  constructor(message) {
    super(message);
    this.name = 'SessionError';
  }
}

export class SessionStorageError extends SessionError {
  constructor(message) {
    super(message);
    this.name = 'SessionStorageError';
  }
}

export class CookiesDisabledError extends SessionError {
  constructor(message) {
    super(message);
    this.name = 'CookiesDisabledError';
  }
}

export class MultipleTabsError extends SessionError {
  constructor(message) {
    super(message);
    this.name = 'MultipleTabsError';
  }
}

export class FreshTabError extends SessionError {
  constructor(message) {
    super(message);
    this.name = 'FreshTabError';
  }
}

//https://stackoverflow.com/a/27747377/12003949
function generateId(len = 40) {
  const arr = new Uint8Array((len) / 2);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, dec2hex).join('');
}

function dec2hex(dec) {
  return dec.toString(16).padStart(2, "0")
}

