import React              from 'react';
import Button             from '@mui/material/Button';
import Tooltip            from '@mui/material/Tooltip';
import useLogout          from 'utils/useLogout';
import { useTranslation } from 'react-i18next';
import PropTypes          from 'prop-types';


function LogoutButton({ setIsLogoutInProgress }) {

  const { t } = useTranslation();

  const { logout, isLogoutInProgress } = useLogout();

  React.useEffect(() => {
    setIsLogoutInProgress?.(isLogoutInProgress);
  }, [isLogoutInProgress, setIsLogoutInProgress]);

  const tooltip = isLogoutInProgress ? t('logoutInProgress') : null;

  return (
    <Tooltip title={tooltip} open={isLogoutInProgress}>
      <span>{/* Disabled elements can't hold a ref required by Tooltip */}
        <Button
          color='secondary'
          onClick={logout}
          disabled={isLogoutInProgress}
        >
          {t('logoutButton')}
        </Button>
      </span>
    </Tooltip>
  );
}

LogoutButton.propTypes = {
  setIsLogoutInProgress: PropTypes.func
}

export default LogoutButton;