import React     from 'react';
import useGET    from 'rest/useGET';
import useStyles from './downloadsPageStyles'

import CenteredSpinner         from 'components/CenteredSpinner';
import DownloadableFileOrAlert from 'pages/DownloadsPage/DownloadableFileOrAlert';
import Grid                    from '@mui/material/Grid';
import NavBar                  from 'NavBar';
import Paper                   from '@mui/material/Paper';

import createRestRequestConfig from 'rest/createRestRequestConfig';

import { FINTECH_PATHS } from 'rest/const';
import { AuthContext }   from 'App';


export const FILE_TYPES = {
  terms:        'TERMS',
  cardContract: 'CARD_CONTRACT',
  contactForm:  'CONTACT_FORM',
};

export default function DownloadsPage() {

  const { classes }  = useStyles();
  const { userInfo } = React.useContext(AuthContext);

  const termsPath    = FINTECH_PATHS.latestDocuments.replace('{type}', FILE_TYPES.terms);
  const contractPath = FINTECH_PATHS.latestDocuments.replace('{type}', FILE_TYPES.cardContract);
  const contactPath  = FINTECH_PATHS.latestDocuments.replace('{type}', FILE_TYPES.contactForm);

  const axiosCfg = createRestRequestConfig(userInfo.accessToken, true);

  const swrOpts = {
    revalidateIfStale:     false,
    revalidateOnFocus:     false,
    revalidateOnReconnect: false,
    shouldRetryOnError:    false,
    onError:               err => {
      console.error("Error while fetching information about a file: ", err);
    },
  };

  const termsGet    = useGET(termsPath, axiosCfg, swrOpts);
  const contractGet = useGET(contractPath, axiosCfg, swrOpts);
  const contactGet  = useGET(contactPath, axiosCfg, swrOpts);

  //imitating isLoading flag from SWR v2+
  const isTermsLoading    = !termsGet.data && !termsGet.error;
  const isContractLoading = !contractGet.data && !contractGet.error;
  const isContactLoading  = !contactGet.data && !contactGet.error;
  const isAnyLoading      = isTermsLoading || isContractLoading || isContactLoading;

  if (isAnyLoading) {
    return (
      <>
        <NavBar/>
        <Paper className={classes.paper}>
          <CenteredSpinner/>
        </Paper>
      </>
    );
  }

  //can't add spacing to grid container here because some items can become empty
  // which would leave additional empty space between remaining items
  return (
    <>
      <NavBar/>
      <Paper className={classes.paper}>
        <Grid container direction='column' paddingLeft={2}>
          <Grid item>
            <DownloadableFileOrAlert
              fileInfo={termsGet.data?.data}
              restError={termsGet.error}
              fileType='TERMS'
            />
          </Grid>
          <Grid item>
            <DownloadableFileOrAlert
              fileInfo={contractGet.data?.data}
              restError={contractGet.error}
              fileType='CARD_CONTRACT'
            />
          </Grid>
          <Grid item>
            <DownloadableFileOrAlert
              fileInfo={contactGet.data?.data}
              restError={contactGet.error}
              fileType='CONTACT_FORM'
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
