import React          from 'react';
import { useContext } from 'react';

import CardsTable      from './CardsTable';
import CenteredSpinner from 'components/CenteredSpinner';
import NavBar          from 'NavBar';
import jwt_decode      from 'jwt-decode';
import Paper           from '@mui/material/Paper';
import { AuthContext } from 'App';
import TableAccordion  from 'pages/MyCardsPage/TableAccordion';


function getAvailableOrgs(accessToken) {
  return () => {
    if (!accessToken) {
      return [];
    }
    const token = typeof accessToken === 'string' ? jwt_decode(accessToken) : accessToken;
    const orgs  = token.clientCodes;
    if (!orgs?.length) { //Not array or empty
      return [];
    }

    orgs.sort((current, next) => {
      const leftCompare  = (current.orgDesc || current.org).toLowerCase();
      const rightCompare = (next.orgDesc || next.org).toLowerCase();

      return leftCompare.localeCompare(rightCompare);
    }); //Alphabetical order

    const result = {}; //Map will prevent from making any duplicates
    for (const i in orgs) {
      result[orgs[i].org] = orgs[i].orgDesc;
    }
    return result;
  };
}

function MyCardsPage() {

  const { userInfo } = useContext(AuthContext);

  const [availableOrgs, setAvailableOrgs] = React.useState({});

  const accessToken = userInfo.accessToken;

  React.useEffect(() => {
    setAvailableOrgs(getAvailableOrgs(accessToken));
  }, [accessToken]);

  //Separate suspense fallback is necessary - Accordion's transition
  // component will throw an error if it's unmounted while it's working
  // and that can happen if table translation file hasn't been fetched yet
  return (
    <>
      <NavBar/>
      <Paper elevation={3}>
        {Object.keys(availableOrgs).map(org => (
          <TableAccordion key={org} summary={availableOrgs[org]}>
            <React.Suspense fallback={<CenteredSpinner/>}>
              <CardsTable organization={org}/>
            </React.Suspense>
          </TableAccordion>
        ))}
      </Paper>
    </>
  );
}

export default MyCardsPage;
