import React from 'react';

import { BACKEND_PATHS }         from 'rest/const';
import CssBaseline               from '@mui/material/CssBaseline';
import { FINTECH_PATHS }         from 'rest/const';
import { isJWTExpiredError }     from 'rest/sendRequest';
import { RouterProvider }        from 'react-router-dom';
import { setAutoRefreshSession } from 'utils/setAutoRefreshSession';
import { SnackbarProvider }      from 'notistack';
import { SWRConfig }             from 'swr'
import { ThemeProvider }         from '@mui/material/styles';

import sendRequest from 'rest/sendRequest';
import useGET      from 'rest/useGET';
import useREST     from 'rest/useREST';

import axios     from 'axios';
import router    from 'routing/router';
import theme     from './theme';
import { FLAGS } from 'rest/const';

export const AuthContext = React.createContext(null);

export const PREPAID_PATH_KEY = 'prepaid-WWW-Path';


function App() {

  const [userInfo, setUserInfo]                 = React.useState(null);
  const [refreshTimeoutId, setRefreshTimeoutId] = React.useState(undefined);

  useGET(BACKEND_PATHS.configuration, undefined, {
    revalidateIfStale:     false,
    revalidateOnFocus:     false,
    revalidateOnReconnect: false,
    fetcher: useREST(false), //SWRConfig doesn't apply here yet
    onSuccess: (res) => {
      //TODO once encryption is added we have to decrypt it there first
      axios.defaults.baseURL = res.data?.baseUrl;
      axios.defaults.timeout = res.data?.frontEndTimeout || 0;
      FINTECH_PATHS[PREPAID_PATH_KEY] = res.data?.prepaidUrl;
      if (!res.data?.paths) {
        console.error("Backend didn't provide paths necessary for the application to work properly");
        return;
      }
      const paths = res.data.paths;
      for (const path in paths) {
        FINTECH_PATHS[path] = paths[path];
      }
      const flags = res.data.flags;
      for (const flag in flags) {
        FLAGS[flag] = flags[flag];
      }

      //Trigger re-render in case there are delayed pages with broken paths (happens on docker on chrome)
      if (userInfo) {
        setUserInfo({ ...userInfo });
      } else {
        setUserInfo(userInfo === null ? undefined : null);
      }
    },
  });

  React.useEffect(
    () => {
      if (!userInfo) {
        return;
      }
      if (userInfo.fromRefreshPromise) { //Login sets cookies, no need to update them
        //This hook triggers each time setUserInfo() is called
        //This way we update cookies only there and not in each place it's called at
        try {
          sendRequest(BACKEND_PATHS.updateCookies, 'post', userInfo);
        } catch (ignored) {
          // These are SessionErrors, they will be handled somewhere else
        }
      }
      setAutoRefreshSession(userInfo, setUserInfo, setRefreshTimeoutId);
    }, [userInfo]
  );

  const refreshPromise = userInfo?.refreshPromise; //?. cannot be used in dependency array, so it's set there
  const hasJWTExpired = React.useCallback(
    (error) => {
      if (refreshPromise) { //Session refresh is already in progress
        return true;
      }
      return isJWTExpiredError(error);
    }, [refreshPromise]
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <SWRConfig
        value={{
          errorRetryCount: 3,
          fetcher:         useREST(false),
        }}
      >
        <SnackbarProvider maxSnack={1}>
          <AuthContext.Provider value={{ userInfo, setUserInfo, refreshTimeoutId, hasJWTExpired }}>
            <RouterProvider router={router}/>
          </AuthContext.Provider>
        </SnackbarProvider>
      </SWRConfig>
    </ThemeProvider>
  );
}

export default App;
