import React                   from 'react';
import { AuthContext }         from 'App';
import { useSWRConfig }        from 'swr';
import { FINTECH_PATHS }       from 'rest/const';
import useREST                 from 'rest/useREST';
import createRestRequestConfig from 'rest/createRestRequestConfig';
import { useParams }           from 'react-router-dom';


export default function useMerchantWhitelistManagement() {

  const urlParams    = useParams();
  const cardId       = urlParams.cardId;
  const { userInfo } = React.useContext(AuthContext);

  const [inProgress, setInProgress] = React.useState(false);

  const { mutate } = useSWRConfig();
  const rest       = useREST();

  /**
   * Tells SWR to clear all merchant whitelist data from cache.
   * @param key array passed to any SWR hook anywhere in the app
   * @returns {false|true} `true` if the cache for given key should be cleared, `false` otherwise
   */
  function keyMatcherForMutate(key) {
    if (!Array.isArray(key)) {
      return false;
    }
    const path = key[0];
    // clear cache for all combinations of parameters since it's difficult
    // to determine which entries are affected
    const pathToMutate = FINTECH_PATHS.merchantWhitelist.replace('{cardId}', cardId);
    return typeof path === 'string' && path.startsWith(pathToMutate);
  }

  function resetStateAndMutate() {
    setInProgress(false);
    mutate(keyMatcherForMutate, undefined, { revalidate: true });
  }

  function sendMerchantWhitelistRequest(path, method, body, onSuccess, onError) {
    const axiosCfg = createRestRequestConfig(userInfo.accessToken, true);

    setInProgress(true);

    rest([path, method, body, axiosCfg])
      .then(onSuccess)
      .catch(err => {
        console.error(`Error while performing ${method} operation on whitelisted merchant`, err);
        onError(err);
      })
      .then(resetStateAndMutate);
  }

  function addMerchantToWhitelist(body, onSuccess, onError) {
    const path = FINTECH_PATHS.addMerchantToWhitelist.replace('{cardId}', cardId);
    sendMerchantWhitelistRequest(path, 'put', body, onSuccess, onError);
  }

  function deleteMerchantFromWhitelist(merchantToDelete, onSuccess, onError) {
    const path = FINTECH_PATHS.deleteMerchantFromWhitelist.replace('{cardId}', cardId);
    sendMerchantWhitelistRequest(path, 'delete', merchantToDelete, onSuccess, onError);
  }

  return {
    inProgress,
    addMerchantToWhitelist,
    deleteMerchantFromWhitelist,
  };
}