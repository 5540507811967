import React from 'react';

import IconButton from '@mui/material/IconButton';
import MaskInput  from 'react-input-mask';
import TextField  from '@mui/material/TextField';
import TodayIcon  from '@mui/icons-material/Today';

import { AdapterDayjs }         from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker }           from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker }       from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker }           from '@mui/x-date-pickers/TimePicker';

import reformatDate     from './reformatDate';
import reformatDateTime from './reformatDateTime';

import dayjs     from 'dayjs';
import 'dayjs/locale/pl';
import PropTypes from 'prop-types';

/**
 * Default char displayed in the place of empty chars
 *
 * @type {String}
 */
export const DEFAULT_PLACEHOLDER_CHAR = "_";

/**
 * Default format of characters in mask
 *
 * @type {Object}
 */
export const DEFAULT_FORMAT_CHARS = {
  '9': '[0-9]',
  'a': '[A-Za-z]',
  '*': '[A-Za-z0-9]',
};

/**
 * <pre>
 * Masks use format defined in "formatChars"
 * In order to ignore format escape char twice "\\9" for "9"
 * </pre>
 *
 * @type {Object}
 */
export const MASKS = {
  DATE:           '9999-99-99',
  TIME:           '99:99:99',
  DATETIME_SHORT: '9999-99-99 99:99',
  DATETIME:       '9999-99-99 99:99:99',
};

export const DAYJS_FORMATS = {
  DATE:           'YYYY-MM-DD',
  TIME:           'HH:mm:ss',
  DATETIME_SHORT: 'YYYY-MM-DD HH:mm',
  DATETIME:       'YYYY-MM-DD HH:mm:ss',
}

/**
 * Component copied from GUI NONSTOP and adjusted.
 */
function MaskedDateField(props) {

  const [pickerOpen, setPickerOpen] = React.useState(false);

  const currentMask            = props.mask || MASKS.DATE;
  const currentFormatChars     = props.formatChars || DEFAULT_FORMAT_CHARS;
  const currentPlaceholderChar = props.placeholderChar || DEFAULT_PLACEHOLDER_CHAR;

  let valueStr = props.value;

  //MaskInput runs .toString() on value
  if (valueStr == null) {
    valueStr = '';
  } else if (valueStr instanceof Date) {
    //Ensure date format:
    valueStr = convertDateObjectToFieldValue(valueStr, currentMask);
  }

  if (valueStr.length > currentMask.length)
    valueStr = valueStr.slice(0, currentMask.length);

  function onMaskedChange(evt) {
    const value = evt.target.value;
    props.setter(value);
  }

  function onPickerChange(date) {
    const fabricatedEvent = {
      target: {
        value: convertDateObjectToFieldValue(date.toDate(), currentMask),
      },
    };
    onMaskedChange(fabricatedEvent, true);
  }

  const [PickerComponent, views] = React.useMemo(() => {
    switch (props.mask) {
      case MASKS.DATE:
        return [DatePicker];
      case MASKS.TIME:
        return [TimePicker, ['hours', 'minutes', 'seconds']];
      case MASKS.DATETIME_SHORT:
        return [DateTimePicker, ['year', 'day', 'hours', 'minutes']];
      default:
        return [DateTimePicker, ['year', 'day', 'hours', 'minutes', 'seconds']];
    }
  }, [props.mask])

  const InputPropsEndAdornment = { endAdornment: undefined };
  if (!props.disabled && props.onlyDisplay !== true) {
    const fromProps                     = props?.InputProps?.endAdornment;
    InputPropsEndAdornment.endAdornment = (
      <>
        {fromProps}
        <IconButton onClick={() => setPickerOpen(true)}>
          <TodayIcon/>
        </IconButton>
      </>
    );
  }

  const InputProps = {
    ...props?.InputProps,
    ...InputPropsEndAdornment,
  };

  const pickerValueStr = props.mask === MASKS.TIME ? "2000-01-01T" + valueStr : valueStr;
  const pickerValue    = dayjs(pickerValueStr);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pl'>
      <PickerComponent
        value={pickerValue}
        onChange={onPickerChange}
        open={pickerOpen}
        onClose={() => setPickerOpen(false)}
        ampm={false}
        views={views}
        minDate={props.minDate}
        maxDate={props.maxDate}
        disableFuture={props.disableFuture}
        disablePast={props.disablePast}
        renderInput={renderProps =>
          <MaskInput
            mask={currentMask}
            formatChars={currentFormatChars}
            maskChar={currentPlaceholderChar}
            value={valueStr}
            disabled={props.disabled || props.onlyDisplay === true}
            onChange={onMaskedChange}
            onBlur={props.onBlur}
          >
            {/*Throws error if you use any setter() inside '() => (<TextField/>)'*/}
            {/*Uncaught Error: Maximum update depth exceeded.*/}
            {/*The above error occurred in the <ForwardRef(InputBase)> component:*/}
            {/*Also: value of the TextField and MaskInput must always be the same*/}
            {() => (
              <TextField
                required
                fullWidth
                inputRef={renderProps.inputRef}
                label={props.label}
                InputProps={InputProps}
                disabled={props.disabled || props.onlyDisplay === true}
                value={valueStr}
                error={props.error}
                helperText={props.helperText || ' '}
                InputLabelProps={props.InputLabelProps}
              />
            )}
          </MaskInput>
        }
      />
    </LocalizationProvider>
  );
}

function convertDateObjectToFieldValue(date, mask) {
  if (isNaN(date)) {
    return '';
  } else {
    switch (mask) {
      case MASKS.DATE:
        return reformatDate(date);
      case MASKS.DATETIME:
      case MASKS.DATETIME_SHORT:
        return reformatDateTime(date);
      case MASKS.TIME:
        return date.toLocaleTimeString();
      default:
        return date.toISOString();
    }
  }
}

MaskedDateField.propTypes = {
  value:      PropTypes.oneOfType([
    PropTypes.string, PropTypes.instanceOf(Date)
  ]),
  setter:     PropTypes.func.isRequired,
  onBlur:     PropTypes.func,
  error:      PropTypes.bool,
  helperText: PropTypes.string,

  mask:            PropTypes.oneOf(Object.values(MASKS)),
  formatChars:     PropTypes.object,
  placeholderChar: PropTypes.string,
  disabled:        PropTypes.bool,
  onlyDisplay:     PropTypes.bool,
  label:           PropTypes.string.isRequired,
  /**
   * Max date provided to the picker component. Applicable only to
   * calendar - time is ignored.
   */
  maxDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  /**
   * Min value provided to the picker component. Applicable only to
   * calendar - time is ignored.
   */
  minDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  /**
   * Flag passed down to the picker.
   */
  disableFuture: PropTypes.bool,
  /**
   * Flag passed down to the picker.
   */
  disablePast: PropTypes.bool,
};

export default React.memo(MaskedDateField);