import React                               from 'react';
import { AuthContext }                     from 'App';
import createRestRequestConfig             from 'rest/createRestRequestConfig';
import { FINTECH_PATHS }                   from 'rest/const';
import usePOST                             from 'rest/usePOST';
import isBlank                             from 'utils/isBlank';
import { decrypt }                         from './ellipticCurveCryptoWrapper';
import { generateKeyPairWithPromise }      from './ellipticCurveCryptoWrapper';
import { getSendablePublicKeyWithPromise } from './ellipticCurveCryptoWrapper';
import getUrlAndPubKeyFromToken            from 'pages/CardDetailsPage/tabs/DetailsAndOperationsTab/OperationsDialogs/PinChangeDialog/getUrlAndPubKeyFromToken';


export default function useEmbossInfoAccess(cardId, expDate, onError) {

  const { userInfo } = React.useContext(AuthContext);

  const [keyPair, setKeyPair]                                 = React.useState(undefined);
  const [jwtGenReq, setJwtGenReq]                             = React.useState(undefined);
  const [middlewareAccessTokens, setMiddlewareAccessTokens]   = React.useState(undefined);
  const [middlewareEmbossInfoReq, setMiddlewareEmbossInfoReq] = React.useState(undefined);

  const [cvv2, setCvv2]             = React.useState(undefined);
  const [cardNumber, setCardNumber] = React.useState(undefined);
  //cvv2 and number together, ready to be returned
  const [embossInfo, setEmbossInfo] = React.useState(undefined);

  function resetState() {
    setKeyPair(undefined);
    setJwtGenReq(undefined);
    setMiddlewareAccessTokens(undefined);
    setMiddlewareEmbossInfoReq(undefined);

    setCvv2(undefined);
    setCardNumber(undefined);
    setEmbossInfo(undefined);
  }

  const jwtGenAxiosCfg = createRestRequestConfig(userInfo.accessToken, true);

  const commonSwrOpts = {
    revalidateIfStale:     false,
    revalidateOnFocus:     false,
    revalidateOnReconnect: false,
    shouldRetryOnError:    false,   //todo perhaps retry on error but only when it's not a bad otp? onErrorRetry
  }

  const jwtGetSwrOpts = {
    ...commonSwrOpts,
    onSuccess: (res) => {
      setMiddlewareAccessTokens(res.data.accessTokens);
      setJwtGenReq(undefined);
    },
    onError:   (err) => {
      resetState();
      if (onError) {
        onError?.(err);
        return;
      }
      console.error("Error while sending OTP code: ", err);
    },
  }

  const { isValidating: isFetchingJWT } =
          usePOST(jwtGenReq?.path, jwtGenReq?.body, jwtGenAxiosCfg, jwtGetSwrOpts);

  async function trigger(otpCode) {
    if (isBlank(otpCode))
      throw new Error("useEmbossInfoAccess trigger error: otpCode is blank");

    function getErrorHandler(message) {
      return err => {
        console.error(message, err);
        onError?.(err);
        resetState();
      };
    }

    const keyPairErrorHandler     = getErrorHandler("error while generating key pair");
    const sendableKeyErrorHandler = getErrorHandler("error while generating sendable key");

    const keyPair = await generateKeyPairWithPromise()
      .catch(keyPairErrorHandler);

    setKeyPair(keyPair);

    const sendable = await getSendablePublicKeyWithPromise(keyPair)
      .catch(sendableKeyErrorHandler);

    setJwtGenReq({
      path: FINTECH_PATHS.embossInfoJwtGenerator.replace('{cardId}', cardId),
      body: {
        otp:         otpCode,
        securityKey: sendable,
      }
    });
  }


  React.useEffect(() => {
    if (!middlewareAccessTokens)
      return;

    //these are actually full URLs, not just a paths
    const { url } = getUrlAndPubKeyFromToken(middlewareAccessTokens.CVV2);

    setMiddlewareEmbossInfoReq({
      path: url,
      body: {
        expDate: expDate,
      }
    })
  }, [cardId, expDate, middlewareAccessTokens]);

  //not setting baseURL since absolute URL will be provided in the
  // token payload and then passed to axios as path which will cause
  // baseURL to be ignored
  const middlewareCvvAxiosCfg      = createRestRequestConfig(middlewareAccessTokens?.CVV2 || '', true);
  const middlewareNumberAxiosCfg   = createRestRequestConfig(middlewareAccessTokens?.CARD_NUMBER || '', true);
  middlewareCvvAxiosCfg.baseURL    = undefined;
  middlewareNumberAxiosCfg.baseURL = undefined;

  const middlewareCvvSwrOpts = {
    ...commonSwrOpts,
    onSuccess: (res) => {
      decrypt(res.data.cvv2, keyPair, res.data.securityKey, res.data.iv)
        .then(setCvv2)
        .catch(err => {
          console.error("error while decrypting CVV2", err);
          resetState();
        })
    },
    onError:   (err) => {
      console.error("Error while fetching CVV2", err)
      resetState();
      onError?.(err);
    }
  }

  const middlewareCardSwrOpts = {
    ...commonSwrOpts,
    onSuccess: (res) => {
      decrypt(res.data.cardNumber, keyPair, res.data.securityKey, res.data.iv)
        .then(setCardNumber)
        .catch(err => {
          console.error("error while decrypting card number", err);
          resetState();
        })
    },
    onError:   (err) => {
      console.error("Error while fetching cardNumber", err)
      resetState();
      onError?.(err);
    }
  }

  //url and body are the same for both requests, it's the token that makes the difference
  const { isValidating: isFetchingCvv }        =
          usePOST(middlewareEmbossInfoReq?.path, middlewareEmbossInfoReq?.body, middlewareCvvAxiosCfg, middlewareCvvSwrOpts);
  const { isValidating: isFetchingCardNumber } =
          usePOST(middlewareEmbossInfoReq?.path, middlewareEmbossInfoReq?.body, middlewareNumberAxiosCfg, middlewareCardSwrOpts);


  React.useEffect(() => {
    if (!cardNumber || !cvv2)
      return;
    setEmbossInfo({
      cvv2:       cvv2,
      cardNumber: cardNumber
    });
  }, [cardNumber, cvv2]);

  const isInProgress = isFetchingJWT || isFetchingCvv || isFetchingCardNumber;

  return { trigger, embossInfo, isInProgress }

}