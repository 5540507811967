import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  //Styles used inside of Form
  field: {
    input: {
      '&:user-invalid': { //Special action when <form> returns invalid field
        color: theme.palette.error.main,
      },
    },
    '& [hidenumberadornment=true]': { //Custom attribute, hide adornment for <TextField type=number/>
      MozAppearance: 'textfield', //Firefox
      '& ::-webkit-outer-spin-button,& ::-webkit-inner-spin-button': {
        WebkitAppearance: 'none', //Chrome, Edge, Opera, Safari
      },
    },
    marginBottom: '0.5rem', //This way fields don't touch other fields when they are under each other
  },
  submitButton: {
    fontWeight: theme.typography.fontWeightSemibold,
    textDecoration: 'none',
  },
  centerText: {
    textAlign: 'center',
  },


  //Styles used by Form's Container
  mainPaper:  {
    minWidth: 'fit-content',
  },
  titlePaper: {
    backgroundColor: theme.palette.primary.main,
    paddingLeft:     theme.spacing(3),
    paddingRight:    theme.spacing(3),
    paddingTop:      theme.spacing(2),
    paddingBottom:   theme.spacing(2),
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  title: {
    color: theme.palette.primary.contrastText,
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

export default useStyles;
