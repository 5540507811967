import React from 'react';

import PropTypes           from 'prop-types';
import { GRID_SPACING }    from 'pages/CardDetailsPage/tabs/DetailsAndOperationsTab/detailsAndOperationsTabConst';
import { GRID_MARGIN_TOP } from 'pages/CardDetailsPage/tabs/DetailsAndOperationsTab/detailsAndOperationsTabConst';
import Grid                from '@mui/material/Grid';
import Typography          from '@mui/material/Typography';
import Button              from '@mui/material/Button';
import { useTranslation }  from 'react-i18next';


function BlockCardConfirmationButton(props) {

  const { t } = useTranslation('cardDetails');

  return (
    <Grid
      container
      alignItems='center'
      justifyContent='center'
      spacing={GRID_SPACING}
      marginTop={GRID_MARGIN_TOP}
    >
      <Grid item>
        <Typography>
          {t('areYouSure.blockCard')}
        </Typography>
      </Grid>
      <Grid item>
        <Button onClick={props.onConfirmation}>
          {t('iConfirm')}
        </Button>
      </Grid>
    </Grid>
  );
}

BlockCardConfirmationButton.propTypes = {
  onConfirmation: PropTypes.func.isRequired,
};

export default BlockCardConfirmationButton;