import React                           from 'react';
import DialogTitle                     from '@mui/material/DialogTitle';
import { useTranslation }              from 'react-i18next';
import Dialog                          from '@mui/material/Dialog';
import PropTypes                       from 'prop-types';
import DialogActions                   from '@mui/material/DialogActions';
import DialogContent                   from '@mui/material/DialogContent';
import FormWithOtpField                from 'pages/CardDetailsPage/tabs/DetailsAndOperationsTab/FormWithOtpField';
import { OTP_TYPES }                   from 'pages/CardDetailsPage/tabs/DetailsAndOperationsTab/detailsAndOperationsTabConst';
import useChange3DSAnswer              from 'pages/CardDetailsPage/tabs/ThreeDSTab/ThreeDSActions/useChange3DSAnswer';
import useStyles                       from './change3DSAnswerDialogStyles';
import Button                          from '@mui/material/Button';
import ThreeDsAnswerForm               from './ThreeDSAnswerForm';
import { OTP_FIELD_NAME }              from 'pages/CardDetailsPage/tabs/DetailsAndOperationsTab/OTPField/OTPField';
import validateAnswerFields            from './ThreeDSAnswerForm/validateAnswerFields';
import { ANSWER_FIELD_NAME }           from './ThreeDSAnswerForm/ThreeDSAnswerForm';
import Alert                           from 'components/Alert';
import useDialogCommonStateAndHandlers from 'pages/CardDetailsPage/tabs/useDialogCommonStateAndHandlers';


function Change3DSAnswerDialog(props) {

  const { t }       = useTranslation(['common', 'cardDetails']);
  const { classes } = useStyles();

  const [answerInputValidationMessage, setAnswerInputValidationMessage]             = React.useState(undefined);
  const [answerRepeatInputValidationMessage, setAnswerRepeatInputValidationMessage] = React.useState(undefined);

  const { inProgress, change3DSAnswer } = useChange3DSAnswer();

  const answerInputRef       = React.useRef();
  const answerRepeatInputRef = React.useRef();

  const refs    = {
    answerInputRef:       answerInputRef,
    answerRepeatInputRef: answerRepeatInputRef,
  };
  const setters = {
    setAnswerInputValidationMessage:       setAnswerInputValidationMessage,
    setAnswerRepeatInputValidationMessage: setAnswerRepeatInputValidationMessage,
  };

  const {
          changeWasSuccessful,
          alert,

          showValidityMessages,
          setShowValidityMessages,

          formRef,

          setAlertWrapper,
          onSuccess,
          onError,
          onFormInvalid,
        } = useDialogCommonStateAndHandlers(props.closeDialog);

  function onSubmit(evt) {
    evt.preventDefault();
    setShowValidityMessages(true);  //to display mismatch message or wrong otp message

    const form     = evt.target;
    const formData = new FormData(form);
    const otp      = formData.get(OTP_FIELD_NAME);
    const answer   = formData.get(ANSWER_FIELD_NAME);

    validateAnswerFields(refs, setters, t);
    if (form.checkValidity()) {
      change3DSAnswer(otp, answer)
        .then(onSuccess)
        .catch(onError);
    }
  }


  return (
    <Dialog
      open={true}
      onClose={props.closeDialog}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle>
        {t('cardDetails:change3DSAnswerDialogTitle')}
      </DialogTitle>
      <DialogContent>
        {!alert &&
          <FormWithOtpField
            formRef={formRef}
            onSubmit={onSubmit}
            onInvalid={onFormInvalid}
            onChange={() => setShowValidityMessages(true)}
            showValidityMessages={showValidityMessages}
            disableSubmit={inProgress}
            otpType={OTP_TYPES.change3DSAnswer}
          >
            <ThreeDsAnswerForm
              showValidityMessages={showValidityMessages}

              refs={refs}
              setters={setters}

              answerInputValidationMessage={answerInputValidationMessage}
              answerRepeatInputValidationMessage={answerRepeatInputValidationMessage}
            />
          </FormWithOtpField>
        }

        {alert &&
          <Alert
            alert={alert}
            setAlert={setAlertWrapper}
          />
        }
      </DialogContent>
      <DialogActions>
        <Button
          id='cancelButton'
          onClick={props.closeDialog}
          color='primary'
          variant='outlined'
        >
          {changeWasSuccessful === undefined ? t('common:cancelButton') : t('common:closeButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

Change3DSAnswerDialog.propTypes = {
  closeDialog: PropTypes.func.isRequired,
};

export default Change3DSAnswerDialog;