import React              from 'react';
import { useNavigate }    from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useStyles          from './navBarStyles'

import AppBar        from '@mui/material/AppBar';
import Button        from '@mui/material/Button';
import ButtonNavLink from 'NavBar/ButtonNavLink';
import Grid          from '@mui/material/Grid';
import LogoutButton  from 'components/LogoutButton';

import { AuthContext }        from 'App';
import internalPaths          from 'routing/internalPaths';
import { TOKEN_SEARCH_PARAM } from 'utils/useURL';


function NavBar() {

  const { classes }  = useStyles();
  const { t }        = useTranslation();
  const navigate     = useNavigate();
  const { userInfo } = React.useContext(AuthContext);

  const [isLogoutInProgress, setIsLogoutInProgress] = React.useState(false);

  const leftAlignedButtons = [
    {
      path:        internalPaths.myCards,
      buttonLabel: t('myCardsNavButton'),
    },
    {
      path:        internalPaths.downloads,
      buttonLabel: t('downloadsNavButton'),
    },
  ];

  return (
    <AppBar
      position='static'
      elevation={2}
      className={classes.appBar}
    >
      <Grid container spacing={2} justifyContent='space-between' alignItems='center'>
        <Grid item>
          <Grid container spacing={2} alignItems='center'>
            {leftAlignedButtons.map((btn => (
              <Grid item key={btn.buttonLabel}>
                <ButtonNavLink disabled={isLogoutInProgress} to={btn.path}>
                  {btn.buttonLabel}
                </ButtonNavLink>
              </Grid>
            )))}
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item>
              <Button
                color='secondary'
                onClick={() => navigate(internalPaths.userData)}
              >
                {t('userDataButton')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                color='secondary'
                onClick={() => {
                  navigate(internalPaths.changePassword + '?' + TOKEN_SEARCH_PARAM + '=' + userInfo.accessToken);
                }}
              >
                {t('changePasswordButton')}
              </Button>
            </Grid>
            <Grid item>
              <LogoutButton setIsLogoutInProgress={setIsLogoutInProgress}/>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AppBar>
  );
}

export default NavBar;