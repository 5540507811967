import React from 'react';

import PropTypes          from 'prop-types';
import Grid               from '@mui/material/Grid';
import Typography         from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Button             from '@mui/material/Button';


function ErrorAlertWithRetry(props) {

  const { t } = useTranslation('common');

  return (
    <Grid
      container
      direction='column'
      alignItems='center'
      spacing={3}
    >
      <Grid item>
        <Typography color='error' fontWeight='bold'>
          {props.errorMessage}
        </Typography>
      </Grid>
      <Grid item>
        <Button
          disabled={props.disabled}
          onClick={props.onTryAgain}
        >
          {t('tryAgain')}
        </Button>
      </Grid>
    </Grid>
  );
}

ErrorAlertWithRetry.propTypes = {
  errorMessage: PropTypes.node.isRequired,
  onTryAgain:   PropTypes.func.isRequired,
  disabled:     PropTypes.bool
};

export default ErrorAlertWithRetry;