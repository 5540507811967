import React from 'react';

import PropTypes                 from 'prop-types';
import UntrustedDeviceChoiceForm from './UntrustedDeviceChoiceForm';
import DeviceDescriptionForm     from './DeviceDescriptionForm';


function UntrustedDevice(props) {

  const [showDeviceDescriptionForm, setShowDeviceDescriptionForm] = React.useState(false);

  if (showDeviceDescriptionForm)
    return (
      <DeviceDescriptionForm
        onAddToTrusted={props.onAddToTrusted}
      />
    );

  return (
    <UntrustedDeviceChoiceForm
      chooseAddToTrusted={() => setShowDeviceDescriptionForm(true)}
      chooseUseOneTimeLogin={props.onUseOneTimeLogin}
    />
  );
}

UntrustedDevice.propTypes = {
  onAddToTrusted:    PropTypes.func.isRequired,
  onUseOneTimeLogin: PropTypes.func.isRequired,
};

export default UntrustedDevice;