import React             from 'react';
import Grid              from '@mui/material/Grid';
import Change3DSMethod   from './Change3DSMethod';
import Change3DSAnswer   from './Change3DSAnswer';
import CurrentMethodInfo from './CurrentMethodInfo';


function ThreeDsActions() {


  return (
    <Grid container item spacing={2} marginBottom={2}>
      <Grid item xs={12}>
        <CurrentMethodInfo/>
      </Grid>

      <Grid item>
        <Change3DSMethod/>
      </Grid>
      <Grid item>
        <Change3DSAnswer/>
      </Grid>
    </Grid>
  );
}

export default ThreeDsActions;