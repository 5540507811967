import { getErrorMessage } from './sendRequest';
import { OTP_FIELD_NAME }  from 'pages/CardDetailsPage/tabs/DetailsAndOperationsTab/OTPField/OTPField';

let baseURL = window.location.origin;

//Workaround only for development with react on node.js server
if (window.location.hostname === 'localhost') {
  const location = window.location;
  baseURL = location.protocol + "//" + location.hostname + ':9092';
}

export const AXIOS_BASE_CONFIG = {
  baseURL: baseURL,
  //Automatically send cookies cross-origin, workaround only for development with react on node.js server
  withCredentials: process.env.NODE_ENV === 'development',
  throwOnSessionError: true,  //this is not an actual axios setting
};

export const AUTHORIZATION_HEADER_NAME = "Authorization";

const BACKEND_BASE_PATH = '/api';

export const BACKEND_PATHS = {
  configuration:          BACKEND_BASE_PATH + '/configuration',
  loginWithTrustedDevice: BACKEND_BASE_PATH + '/login',
  loginAndTrustDevice:    BACKEND_BASE_PATH + '/login/trustDevice',
  loginOneTime:           BACKEND_BASE_PATH + '/login/onetime',
  autoLogin:              BACKEND_BASE_PATH + '/login/auto',
  clearCookies:           BACKEND_BASE_PATH + '/login/clearCookies',
  updateCookies:          BACKEND_BASE_PATH + '/login/updateCookies',
  reports:                BACKEND_BASE_PATH + '/reports.json',
  register:               BACKEND_BASE_PATH + '/register',
  forgotPassword:         BACKEND_BASE_PATH + '/register/reset', //Don't ask why it's in register... I don't know
};

/**
 * <pre>
 * Object of paths that send REST directly from frontend to gateway
 *
 * It will be filled automatically in the 'App.js' using paths returned from backend
 * Keys of this object are the same as names of fields in 'FrontEndPathsConfiguration.java'
 * </pre>
 *
 * @type {Object.<String>}
 */
export const FINTECH_PATHS = {};

/**
 * Object containing configuration flags.
 *
 * It will be filled automatically in the 'App.js' using values returned from backend
 * Keys of this object are the same as names of fields in 'FrontendFlags.java'
 * @type {Object.<Boolean>}
 */
export const FLAGS = {};

/**
 * Values of error.response.data.message
 */
export const GATEWAY_ERRORS = {
  wrongOTP: 'Incorrect OTP code',
  tooManyOtpAttempts: 'Too many OTP failed attempts',
};

/**
 * <pre>
 * Returns true if OTP error was handled
 * Returns false otherwise
 * This way it helps to decide what to do next
 * </pre>
 */
export function commonOTPErrorHandled(err, handler) {
  const msg = getErrorMessage(err);
  if (!msg) {
    return false;
  }
  for (const err in GATEWAY_ERRORS) {
    if (msg.includes(GATEWAY_ERRORS[err])) {
      handler?.(err);
      return true;
    }
  }
  return false;
}

/**
 * Useful to handle OTP errors within a `<form>` tag.
 * @param err error object from axios
 * @param formRef a reference to the form
 * @param t translation function with `common` namespace loaded
 * @param setAlert called on errors which can't be simply fixed with
 * a validity message
 * @returns {boolean} true if error was handled, false otherwise
 */
export function commonOTPErrorHandledWithForm(err, formRef, t, setAlert) {
  const otpErrorHandler = (errKey) => {
    formRef.current.elements[OTP_FIELD_NAME].setCustomValidity(t(`common:${errKey}`));
    const isFatalError = errKey === 'tooManyOtpAttempts';
    if (isFatalError) {
      //setting error message on the field isn't enough, retrying won't help
      setAlert({
        msgTranslKey: 'tooManyOtpAttempts',
        type:         'error',
      });
    }
  };
  return commonOTPErrorHandled(err, otpErrorHandler);
}
