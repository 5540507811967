import React              from 'react';
import Grid               from '@mui/material/Grid';
import TextField          from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import validatePinFields  from './validatePinFields';
import useStyles          from './pinFieldsStyles';
import isBlank            from 'utils/isBlank';
import PropTypes          from 'prop-types';


export const PIN_FIELD_NAME        = 'pin';
export const REPEAT_PIN_FIELD_NAME = 'pin-repeat';

function PinFields(props) {

  const { classes } = useStyles();
  const { t }       = useTranslation('cardDetails');


  const inputProps = {
    autoComplete: 'off',
    type:         'password',
    inputMode:    'numeric',
    pattern:      '[0-9]{4}',
    minLength:    4,
    maxLength:    4,
  };

  //spaces allow to preserve field height
  let pinHelperText       = props.pinInputValidationMessage || ' ';
  let pinRepeatHelperText = props.pinRepeatInputValidationMessage || ' ';
  if (!props.showValidityMessages) {
    pinHelperText       = ' ';
    pinRepeatHelperText = ' ';
  }

  return (
    <>
      <Grid item>
        <TextField
          required
          label={t('pinFieldLabel')}
          name={PIN_FIELD_NAME}
          onBlur={() => validatePinFields(props.refs, props.setters, t)}
          error={props.showValidityMessages && !isBlank(props.pinInputValidationMessage)}
          helperText={pinHelperText}
          className={classes.field}
          inputRef={props.refs?.pinInputRef}
          inputProps={inputProps}
        />
      </Grid>
      <Grid item>
        <TextField
          required
          label={t('repeatPinFieldLabel')}
          name={REPEAT_PIN_FIELD_NAME}
          onChange={() => validatePinFields(props.refs, props.setters, t)}
          error={props.showValidityMessages && !isBlank(props.pinRepeatInputValidationMessage)}
          helperText={pinRepeatHelperText}
          className={classes.field}
          inputRef={props.refs?.pinRepeatInputRef}
          inputProps={inputProps}
        />
      </Grid>
    </>
  );

}

PinFields.propTypes = {
  refs: PropTypes.shape({
    pinInputRef:       PropTypes.object.isRequired,
    pinRepeatInputRef: PropTypes.object.isRequired,
  }).isRequired,

  setters: PropTypes.shape({
    setPinInputValidationMessage:       PropTypes.func.isRequired,
    setPinRepeatInputValidationMessage: PropTypes.func.isRequired,
  }).isRequired,

  showValidityMessages:            PropTypes.bool,
  pinInputValidationMessage:       PropTypes.string,
  pinRepeatInputValidationMessage: PropTypes.string,
};

export default PinFields;
