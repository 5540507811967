import React                   from 'react';
import useGET                  from 'rest/useGET';
import CenteredSpinner         from 'components/CenteredSpinner';
import { FINTECH_PATHS }       from 'rest/const';
import createRestRequestConfig from 'rest/createRestRequestConfig';
import { AuthContext }         from 'App';
import ErrorAlertWithRetry     from 'pages/CardDetailsPage/tabs/AccountInfoTab/ErrorAlertWithRetry';
import CreditLimits            from 'pages/CardDetailsPage/tabs/AccountInfoTab/CreditLimits';
import CurrentPeriod           from 'pages/CardDetailsPage/tabs/AccountInfoTab/CurrentPeriod';
import PreviousPeriod          from 'pages/CardDetailsPage/tabs/AccountInfoTab/PreviousPeriod';
import Grid                    from '@mui/material/Grid';
import isBlank                 from 'utils/isBlank';
import { useTranslation }      from 'react-i18next';


function AccountInfoTab(props) {

  const { t }        = useTranslation('common');
  const { userInfo } = React.useContext(AuthContext);

  const axiosCfg = createRestRequestConfig(userInfo.accessToken, true);

  const swrOpts = {
    revalidateIfStale:     true,
    revalidateOnFocus:     false,
    revalidateOnReconnect: false,
    shouldRetryOnError:    false,
  };

  const path = FINTECH_PATHS.accountInfo.replace('{cardId}', props.cardId);

  const { data: response, error, mutate, isValidating } = useGET(path, axiosCfg, swrOpts);

  const accountData = response?.data;

  if (error) {
    return (
      <ErrorAlertWithRetry
        errorMessage={t('common:failure')}
        onTryAgain={mutate}
        disabled={isValidating}
      />
    );
  }

  if (!accountData)
    return <CenteredSpinner/>;

  const currencyName = isBlank(accountData.currencyName) ? '' : (' ' + accountData.currencyName);

  return (
    <Grid container spacing={7} direction='column'>
      <Grid item>
        <CreditLimits
          holdsAmount={accountData.holdsAmount}
          creditLimit={accountData.creditLimit}
          currentBalance={accountData.currentBalance}
          currencyName={currencyName}
        />
      </Grid>
      <Grid item>
        <CurrentPeriod
          period={accountData.periods[0]}
          currencyName={currencyName}
        />
      </Grid>
      <Grid item>
        <PreviousPeriod
          period={accountData.periods[1]}
          currencyName={currencyName}
        />
      </Grid>
    </Grid>
  );
}

export default AccountInfoTab;