import { makeStyles }           from 'tss-react/mui';
import { PAPER_PADDING_FACTOR } from 'pages/CardDetailsPage/cardDetailsDialogStyles';

const useStyles = makeStyles()(theme => ({
  negativeMargin: {
    // negative margin allows the table to take more space while also
    // leaving predecessor's padding as it is (which is good because
    // removing it and adding everywhere else except for the table
    // would be tedious)
    marginLeft:   theme.spacing(-PAPER_PADDING_FACTOR),
    marginRight:  theme.spacing(-PAPER_PADDING_FACTOR),
  },
  paginationStyles: {
    backgroundColor: '#FFF',
  },
  fetchingToolbar: {
    textAlign: 'center',
    width:     '100%',
  },
  alertContainer: {
    width:         '100%',
    minHeight:     0,
    maxHeight:     '2.5vh',
    paddingTop:    0,
    paddingBottom: 0,
    marginTop:     0,
    marginBottom:  0,
  },
  alertPaper: {
    padding: 0,
  },

  dateHeadCell: {
    //targeting an inner component of MUI TableCell
    '& .Mui-TableHeadCell-Content-Wrapper': {
      whiteSpace: 'normal',
      wordWrap:   'break-word',
    },
  },

  wrapping: {
    // !important is necessary due to MUI TableCell also using it
    whiteSpace: 'normal !important',
    wordWrap:   'break-word',
  },
}));

export default useStyles;