import React                      from 'react';
import PropTypes                  from 'prop-types';
import Grid                       from '@mui/material/Grid';
import useMerchantOptions         from './useMerchantOptions';
import AutocompleteWithHighlights from 'components/AutocompleteWithHighlights';
import { useTranslation }         from 'react-i18next';
import TextField                  from '@mui/material/TextField';
import Typography                 from '@mui/material/Typography';
import { preventNumberChange }    from 'utils/preventNumberChange';
import useStyles                  from './addMerchantFormStyles';
import isBlank                    from 'utils/isBlank';


function AddMerchantForm(props) {

  const { classes } = useStyles();
  const { t }       = useTranslation('cardDetails');

  const { merchantOptions, isLoading } = useMerchantOptions();

  // MUI TextField has trouble determining when to shrink label in number input
  // it causes label to sometimes overlap with input, so we shrink them all for consistency
  const shrinkLabels = {
    InputLabelProps: { shrink: true },
  };

  return (
    <Grid
      item
      container
      spacing={2}
      alignItems='center'
    >
      <Grid item xs={12}>
        <Typography>
          {t('addMerchantToWhitelist.addMerchantToWhitelistInstruction')}
        </Typography>
        <Typography>
          {t('addMerchantToWhitelist.availableListOfMerchantsInfo')}
        </Typography>
      </Grid>
      <Grid item>
        <AutocompleteWithHighlights
          value={props.merchantOption || null}  //null to not switch between controlled and uncontrolled
          setSelectedOption={props.setSelectedMerchantOption}
          label={t('addMerchantToWhitelist.merchant')}
          options={merchantOptions}
          loading={isLoading}
          textFieldParams={{
            name:       'merchant',
            required:   true,
            helperText: ' ',  // to align the field with maxAmount
            error:      props.showValidityMessages && isBlank(props.merchantOption),
            ...shrinkLabels,
          }}
        />
      </Grid>
      <Grid item>
        <TextField
          value={props.maxAmount || ''}
          onChange={evt => props.setMaxAmount(evt.target.value)}
          label={t('addMerchantToWhitelist.maxAmount')}
          onWheel={preventNumberChange}
          type='number'
          helperText={t('addMerchantToWhitelist.maxAmountTooltip')}
          inputProps={{
            inputMode:           'numeric',
            min:                 1,
            hidenumberadornment: 'true', //Firefox
          }}
          InputProps={{
            hidenumberadornment: 'true', //Chrome, Edge, Opera, Safari
          }}
          {...shrinkLabels}
          className={classes.maxAmountField}
        />
      </Grid>
    </Grid>
  );
}

AddMerchantForm.propTypes = {
  maxAmount:                 PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setMaxAmount:              PropTypes.func.isRequired,
  merchantOption:            PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.shape({
      merchantId:     PropTypes.string.isRequired,
      merchantAcqBin: PropTypes.string.isRequired,
      merchantName:   PropTypes.string.isRequired,
    }).isRequired,
  }),
  setSelectedMerchantOption: PropTypes.func.isRequired,
  showValidityMessages:      PropTypes.bool,
};

export default AddMerchantForm;