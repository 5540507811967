/**
 * Spacing attribute for grid container - a space between buttons,
 * including buttons in dialog.
 * @type {number}
 */
export const GRID_SPACING = 2;

/**
 * Attribute for grid container in dialog.
 * @type {number}
 */
export const GRID_MARGIN_TOP    = 1;
/**
 * Attribute for grid container in dialog.
 * @type {number}
 */
export const GRID_MARGIN_BOTTOM = 3;

export const OTP_TYPES = {
  activateCard:           'OTP_ACTIVATE_CARD',
  temporarilyBlockCard:   'OTP_TEMPORARILY_BLOCK_CARD',
  unblockCard:            'OTP_UNBLOCK_CARD',
  changePin:              'OTP_CHANGE_PIN',
  changeLimits:           'OTP_CHANGE_LIMITS',
  enableContactless:      'OTP_ENABLE_CONTACTLESS',
  disableContactless:     'OTP_DISABLE_CONTACTLESS',
  embossInfoAccess:       'OTP_EMBOSS_INFO',
  addMerchantToWhitelist: 'OTP_ADD_MERCHANT_3DS_WHITELIST',
  change3DSMethod:        'OTP_CHANGE_3DS_METHOD',
  change3DSAnswer:        'OTP_CHANGE_3DS_ANSWER',
  suspendToken:           'OTP_SUSPEND_CARD_TOKEN',
  unsuspendToken:         'OTP_UNSUSPEND_CARD_TOKEN',
  deleteToken:            'OTP_DELETE_CARD_TOKEN',
};

/**
 * Constants to communicate action between DetailsAndOperationsTab and OperationsDialogs components.
 *
 * @type {{blockLostCard: string, activateCard: string, blockStolenCard: string, temporarilyBlockCard: string,
 * unblockCard: string, changePin: string, enableContactless: string, disableContactless: string,
 * embossInfoAccess: string}}
 */
export const OPERATIONS = {
  activateCard:         'activateCard',
  temporarilyBlockCard: 'temporarilyBlockCard',
  unblockCard:          'unblockCard',
  blockLostCard:        'blockLostCard',
  blockStolenCard:      'blockStolenCard',
  changePin:            'changePin',
  enableContactless:    'enableContactless',
  disableContactless:   'disableContactless',
  embossInfoAccess:     'embossInfoAccess',
}

/**
 * Virtual cards don't support contactless.
 */
export const VIRTUAL_TECH_MODE = 'VIRTUAL';