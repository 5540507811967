import React     from 'react';
import useStyles from './commonFormStyles';

import Box       from '@mui/material/Box';
import Button    from '@mui/material/Button';
import Grid      from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Tooltip   from '@mui/material/Tooltip';

import classnames from 'classnames';
import PropTypes  from 'prop-types';

/**
 * Form Component
 *
 * @param t {function(String): String} - result of useTranslation() from 'react-i18next'
 * @param fields {formFields} - fields to display in the form
 * @param onSubmit {function(): void} - function that handles form's onSubmit
 * @param submitBtnText {String =} - either text displayed on submit button or key to get text from 'f', default: t('defaultSubmitBtn')
 * @param submitBtnTooltipFn {function(): String =} - function that generates tooltip for submit button
 * @param extraButtons {JSX.Element | Object =} - component that totally replaces submit button
 * @param defaultFieldProps {Object =} - default props added to all fields BEFORE CommonForm's fieldProps are applied
 * @param overrideFieldProps {Object =} - default props added to all fields AFTER CommonForm's fieldProps are applied
 * @param everythingDisabled {Boolean =} - all fields and submit button are disabled?
 * @param classes {Object =} - result of useStyles()
 * @param gridSizes {formGridSizes =} - sizes of grids in form, numbers are applied to prop 'xs', objects are passed as props to grid
 * @return {JSX.Element}
 */
export default function CommonForm({
  t, fields, onSubmit, submitBtnText, //Required
  submitBtnTooltipFn, extraButtons, defaultFieldProps, overrideFieldProps,
  everythingDisabled, classes, gridSizes, //Optional
}) {

  const formClasses = { ...useStyles().classes, ...classes };

  // !! converts null or undefined or '' or 0 to false, and everything else to true
  everythingDisabled = !!everythingDisabled;

  const [fieldErrors, setFieldErrors] = React.useState({});

  function clearFieldError(fieldName) {
    setFieldErrors((prevState) => {
      const newState      = { ...prevState };
      newState[fieldName] = false;
      return newState;
    });
  }

  function handleInvalid(evt) {
    //corresponds to name attribute inside the <form>
    const invalidFieldName = evt.target.name;
    setFieldErrors((prevState) => {
      const newState             = { ...prevState };
      newState[invalidFieldName] = true;
      return newState;
    });
  }

  function fieldProps(fieldName, label, fieldType, disabled, helperText, className, required, fullWidth, onChange) {
    const fieldClassName = classnames(
      defaultFieldProps?.className, formClasses.field, className, overrideFieldProps?.className,
    );

    return {
      ...defaultFieldProps,
      required:   required !== false, //Default: true, because currently, there are no optional fields
      fullWidth:  fullWidth !== false,
      name:       fieldName,
      label:      t(label || ''),
      type:       fieldType || 'text',
      disabled:   !!(everythingDisabled || disabled),
      helperText: helperText, //Maybe some support in the future?
      error:      fieldErrors[fieldName],
      onChange:   (evt) => {
        if (typeof onChange === 'function') {
          onChange(evt);
        }
        clearFieldError(fieldName);
      },
      ...overrideFieldProps,
      className: fieldClassName,
    };
  }

  const submitBtnTooltipTitle = typeof submitBtnTooltipFn === 'function'
    ? submitBtnTooltipFn() || '' //If it returned null/undefined then turn it back into ''
    : '';

  const { fieldsContainerGridSizes, submitButtonGridSizes, fieldsGridSizes } = prepareGridSizes(gridSizes);

  const formFields = [];
  for (const fieldName in fields) {
    const { component, name, label, fieldType, disabled, helperText, className, required, fullWidth, children, onChange,
      ...inputProps } = fields[fieldName];
    const Component = component || TextField;

    formFields.push(
      <Grid key={fieldName} item xs={12} {...fieldsGridSizes[fieldName]}>
        <Component
          {...inputProps}
          {...fieldProps(name || fieldName, label, fieldType, disabled, helperText, className, required, fullWidth, onChange)}
        >
          {children}
        </Component>
      </Grid>
    );
  }

  return (
    <Box component='form' onSubmit={onSubmit} onInvalid={handleInvalid}>
      <Grid
        container
        spacing={3}
        paddingTop={2}
        paddingBottom={3}
        paddingLeft={4}
        paddingRight={4}
        justifyContent='center'
        alignItems='stretch'
      >
        <Grid container item xs={12} {...fieldsContainerGridSizes}>
          {formFields}
        </Grid>

        <Grid item xs={12} {...submitButtonGridSizes}>
          <Tooltip title={submitBtnTooltipTitle}>
            <span> {/* Disabled elements can't hold a ref required by Tooltip */}
              <Button
                type='submit'
                fullWidth
                color='secondary'
                className={formClasses.submitButton}
                disabled={everythingDisabled}
              >
                {t(submitBtnText || 'defaultSubmitBtn')}
              </Button>
            </span>
          </Tooltip>
        </Grid>

        {extraButtons}
      </Grid>
    </Box>
  );
}

function prepareGridSizes(gridSizes) {
  const fieldsGridSizes = {};
  if (!gridSizes) {
    return {
      fieldsGridSizes: fieldsGridSizes, //Return this one to avoid NPE
    };
  }

  if (gridSizes?.fields) {
    for (const field in gridSizes.fields) {
      fieldsGridSizes[field] = prepareGridSize(gridSizes.fields[field]);
    }
  }

  return {
    fieldsContainerGridSizes: prepareGridSize(gridSizes?.fieldsContainer),
    submitButtonGridSizes: prepareGridSize(gridSizes?.submitButton),
    fieldsGridSizes: fieldsGridSizes,
  };
}

function prepareGridSize(gridSize) {
  if (!gridSize) {
    return undefined;
  }
  if (typeof gridSize === 'number') {
    return {
      xs: gridSize,
    };
  }
  if (typeof gridSize === 'object') {
    return gridSize;
  }
  return undefined;
}

CommonForm.propTypes = {
  t:        PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  fields:   PropTypes.objectOf(
    PropTypes.shape({
      /**
       * <pre>
       * Displayed React Component
       * For example: TextField, SelectField, etc.
       * Default: TextField
       * </pre>
       */
      component:  PropTypes.oneOfType([PropTypes.elementType, PropTypes.object]),
      name:       PropTypes.string,
      label:      PropTypes.string,
      fieldType:  PropTypes.string,
      helperText: PropTypes.string,
      className:  PropTypes.string,
      disabled:   PropTypes.bool,
      /**
       * Provided component's children
       */
      children:   PropTypes.node,
    })
  ).isRequired,

  classes: PropTypes.object,
  everythingDisabled: PropTypes.bool,
  /**
   * Default: t('defaultSubmitBtn')
   */
  submitBtnText: PropTypes.string,
  extraButtons: PropTypes.oneOfType([PropTypes.elementType, PropTypes.object]),
  /**
   * <pre>
   * Sizes of grids in form
   * Numbers are applied to prop 'xs'
   * Objects are applied as props to grid
   *
   * By default, all values are 'xs={12}'
   * </pre>
   */
  gridSizes: PropTypes.shape({
    fieldsContainer: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    submitButton:    PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    /**
     * Keys of this object are 'fieldName' of the fields
     */
    fields:          PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.object])),
  }),
  /**
   * Function that generates tooltip for submit button
   */
  submitBtnTooltipFn: PropTypes.func,
  /**
   * Default props added to all fields BEFORE CommonForm's fieldProps are applied
   */
  defaultFieldProps: PropTypes.object,
  /**
   * Default props added to all fields AFTER CommonForm's fieldProps are applied
   */
  overrideFieldProps: PropTypes.object,
};

/**
 * <pre>
 * Fields displayed in the form
 * Object mapped {fieldName -> formField's object}
 * </pre>
 *
 * @typedef {Object.<String, formField>} formFields
 */

/**
 * Field displayed in the form
 *
 * @typedef {Object} formField
 * @property {Element =} component - displayed React Component, default: TextField
 * @property {String =} name - 'name' attribute of field if it has to be different from key of object
 * @property {String =} label - label of field or key of the label in 't'
 * @property {String =} fieldType - type of field, default 'text'
 * @property {String =} helperText - text displayed under field, it's painted red if error=true
 * @property {String =} className - class applied to component
 * @property {Boolean =} disabled - is field disabled?
 * @property {Node =} children - children of the component
 */

/**
 * <pre>
 * Sizes of grids in form
 * Numbers are applied to prop 'xs'
 * Objects are applied as props to grid
 *
 * By default, all values are 'xs={12}'
 * </pre>
 *
 * @typedef {Object.<String, Number | Object>} formGridSizes
 * @property {Number | Object =} fieldsContainer - grid size of Grid containing all fields
 * @property {Number | Object =} submitButton - grid size of Grid containing submit button
 * @property {Object.<String, Number | Object> =} fields - grid sizes of respective fields, keys of this object are 'fieldName' of the formField
 */
