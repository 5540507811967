import { preventNumberChange } from 'utils/preventNumberChange';


const COMMON_FIELDS = {
  username: {
    fieldType: 'email',
    label:     'usernameFieldLabel',
  },
  usernameToFill: {
    value:      '', //Has to be filled somewhere in code
    disabled:   true,
    fieldType:  'email',
    label:      'usernameFieldLabel',
  },

  otp: {
    label:        'otpFieldLabel',
    fieldType:    'number',
    autoComplete: 'one-time-code',
    onWheel:      preventNumberChange,
    inputProps: {
      min: 0,
      hidenumberadornment: 'true', //Firefox
    },
    InputProps: {
      hidenumberadornment: 'true', //Chrome, Edge, Opera, Safari
    },
  },
  deviceDescription: {
    label: 'deviceDescriptionFieldLabel',
    inputProps: {
      minlength: 1,
    },
  },

  password: {
    fieldType:    'password',
    autoComplete: 'current-password',
    label:        'passwordFieldLabel',
  },
};

export default COMMON_FIELDS;
