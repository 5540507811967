import React              from 'react';
import { useNavigate }    from 'react-router-dom';
import usePOST            from 'rest/usePOST';
import useStyles          from 'components/CommonForm/commonFormStyles';
import { useTranslation } from 'react-i18next';
import useURL             from 'utils/useURL';

import Alert         from 'components/Alert';
import CommonForm    from 'components/CommonForm';
import COMMON_FIELDS from 'components/CommonForm/fields';
import Container     from '@mui/material/Container';
import Paper         from '@mui/material/Paper';
import Typography    from '@mui/material/Typography';

import areNewPasswordsValid    from 'utils/areNewPasswordsValid';
import createRestRequestConfig from 'rest/createRestRequestConfig';

import { createOtpSubmitData }   from 'pages/RegisterOTPPage';
import { FIELD_NAMES }           from 'utils/areNewPasswordsValid';
import { FINTECH_PATHS }         from 'rest/const';
import { commonOTPErrorHandled } from 'rest/const';
import { getErrorMessage }       from 'rest/sendRequest';
import INTERNAL_PATHS            from 'routing/internalPaths';
import PasswordRequirements      from 'components/PasswordRequirements';

const IS_NOT_CREDENTIALS = ['otp'];

function createFields(includeCurrentPassword, showPwdRequirements) {
  const result = {
    username: {
      ...COMMON_FIELDS.usernameToFill,
    },
    otp: {
      ...COMMON_FIELDS.otp,
    },
  };

  if (includeCurrentPassword) {
    result.password = {
      ...COMMON_FIELDS.password,
    };
  }

  result[FIELD_NAMES.newPassword] = {
    ...COMMON_FIELDS.password,
    autoComplete: 'new-password',
    label: 'newPasswordFieldLabel',
  };
  result[FIELD_NAMES.repeatPassword] = {
    ...COMMON_FIELDS.password,
    autoComplete: 'new-password',
    label: 'repeatNewPasswordFieldLabel',
  };

  if (showPwdRequirements) {
    result.pwdRequirements = {
      component: PasswordRequirements,
    };
  }

  return result;
}


function ResetPasswordPage({ callOtpPathKey, otpType, resetPasswordPathKey, includeCurrentPassword, otpOutsideCredentials }) {

  //TODO this page has too much in common with LoginPage - refeactoring is needed

  const navigate    = useNavigate();
  const { t }       = useTranslation();
  const { classes } = useStyles();

  const { token, email, hasTokenExpired } = useURL(() => setTokenInvalid(true));
  const axiosCfg = createRestRequestConfig(token, true);

  const [showPwdRequirements, setShowPwdRequirements] = React.useState(false);
  const FIELDS = React.useMemo(() => createFields(includeCurrentPassword, showPwdRequirements),
    [includeCurrentPassword, showPwdRequirements]);
  FIELDS.username.value = email; //Default value of this field

  const [alert,               setAlert]               = React.useState(undefined);
  const [tokenInvalid,        setTokenInvalid]        = React.useState(false);
  const [callOtpSubmitData,   setCallOtpSubmitData]   = React.useState(() => createOtpSubmitData(email, hasTokenExpired, otpType, otpOutsideCredentials));
  const [resetPassSubmitData, setResetPassSubmitData] = React.useState(undefined);
  const [successPassReset,    setSuccessPassReset]    = React.useState(false);


  React.useEffect(() => {
    if (tokenInvalid) {
      setAlert({
        msgTranslKey: 'tokenInURLInvalid',
        type:         'error',
      });
    } else if (hasTokenExpired) {
      setAlert({
        msgTranslKey: 'tokenExpired',
        type:         'error',
      });
    }
  }, [tokenInvalid, hasTokenExpired]);

  const callOPTSwrOpts = {
    onSuccess: (res) => {
      setCallOtpSubmitData(null);
      setAlert({
        msgTranslKey: t('successCallOTP').replace('&{phoneNo}', res.data.phoneNo),
        type:         'success',
      });

      if (process.env.NODE_ENV === 'development') {
        console.log('ResetPasswordPage, callOTP, ' + FINTECH_PATHS[callOtpPathKey] + ' onRestSuccess: ', res.data);
      }
    },
    onError: (err) => {
      setCallOtpSubmitData(null);
      //TODO? add more specific handling when API is developed
      // Rozróżnić błędy?
      // telefon nie skonfigurowany dla użytkownika
      // generyczne błędy
      const otpErrorHandler = (errKey) => {
        setAlert({
          msgTranslKey: errKey,
          type:         'error',
        });
      };
      if (!commonOTPErrorHandled(err, otpErrorHandler)) {
        setAlert({
          msgTranslKey: 'serverError',
          type:         'warning',
        });
      }

      if (process.env.NODE_ENV === 'development') {
        console.log('ResetPasswordPage, callOTP, ' + FINTECH_PATHS[callOtpPathKey] + ' onRestError: ', getErrorMessage(err), err.response);
        console.error('ResetPasswordPage, callOTP, ' + FINTECH_PATHS[callOtpPathKey] + ' onRestError: ', err);
      }
    },
  };

  const CALL_OTP_PATH = callOtpSubmitData && !tokenInvalid ? FINTECH_PATHS[callOtpPathKey] : null; //null = don't send
  const CALL_OTP_POST = usePOST(CALL_OTP_PATH, callOtpSubmitData, axiosCfg, callOPTSwrOpts);

  const resetPasswordSwrOpts = {
    onSuccess: (res) => {
      setResetPassSubmitData(null)
      setSuccessPassReset(true);
      setAlert({
        msgTranslKey: 'passwordSetSuccessfully',
        type: 'success'
      });
      //Wait short moment and open login page
      setTimeout(() => navigate(INTERNAL_PATHS.login), 1000);

      if (process.env.NODE_ENV === 'development') {
        console.log('ResetPasswordPage, resetPassword, ' + FINTECH_PATHS[resetPasswordPathKey] + ' onRestSuccess: ', res.data);
      }
    },
    onError: (err) => {
      setResetPassSubmitData(null);
      //TODO? add more specific handling when API is developed
      console.error("ResetPasswordPage, onRestError: ", err);
      const otpErrorHandler = (errKey) => {
        setAlert({
          msgTranslKey: errKey,
          type:         'error',
        });
      };
      if (!commonOTPErrorHandled(err, otpErrorHandler)) {
        setAlert({
          msgTranslKey: 'serverError',
          type:         'warning',
        });
      }

      if (process.env.NODE_ENV === 'development') {
        console.log('ResetPasswordPage, resetPassword, ' + FINTECH_PATHS[resetPasswordPathKey] + ' onRestError: ', getErrorMessage(err), err.response);
        console.error('ResetPasswordPage, resetPassword, ' + FINTECH_PATHS[resetPasswordPathKey] + ' onRestError: ', err);
      }
    },
  }

  const RESET_PASSWORD_PATH = resetPassSubmitData ? FINTECH_PATHS[resetPasswordPathKey] : null; //null = don't send
  const RESET_PASSWORD_POST = usePOST(RESET_PASSWORD_PATH, resetPassSubmitData, axiosCfg, resetPasswordSwrOpts);

  function handleSubmit(evt) {
    evt.preventDefault();
    setAlert(null);
    const formData = new FormData(evt.target);
    const toBeSubmitted = {
      credentials: {
        type:     'EMAIL',
        username: email,
      },
    };

    for (const [key, value] of formData.entries()) {
      if (IS_NOT_CREDENTIALS.includes(key)) {
        toBeSubmitted[key] = value;
      } else {
        toBeSubmitted.credentials[key] = value;
      }
    }

    const newAlert = areNewPasswordsValid(toBeSubmitted.credentials, false);

    if (newAlert) {
      setShowPwdRequirements(true);
      setAlert(newAlert);
    } else {
      delete toBeSubmitted.credentials.repeatPassword;
      //Old structure was inside credentials and 'new-password', now it's 'newPassword' outside credentials
      toBeSubmitted.newPassword = toBeSubmitted.credentials[FIELD_NAMES.newPassword];
      delete toBeSubmitted.credentials[FIELD_NAMES.newPassword];
      setResetPassSubmitData(toBeSubmitted);
      RESET_PASSWORD_POST.mutate();
    }
  }

  function submitBtnTooltipFn() {
    if (tokenInvalid) {
      return t('tokenInvalid');
    }
    if (hasTokenExpired) {
      return t('tokenExpired');
    }
    if (CALL_OTP_POST.isValidating || RESET_PASSWORD_POST.isValidating) {
      return t('inProgress');
    }
    if (successPassReset) {
      return t('passwordSetSuccessfully');
    }
    return '';
  }

  return (
    <Container maxWidth='xs'>
      <Alert alert={alert} setAlert={setAlert}/>
      <Paper elevation={6} className={classes.mainPaper}>
        <Paper elevation={0} className={classes.titlePaper}>
          <Typography
            align='center'
            variant='subtitle1'
            className={classes.title}
          >
            {t('resetPasswordTitle')}
          </Typography>
        </Paper>
        <CommonForm
          t={t}
          fields={FIELDS}
          onSubmit={handleSubmit}
          submitBtnText='resetPasswordTitle'
          submitBtnTooltipFn={submitBtnTooltipFn}
          everythingDisabled={
            CALL_OTP_POST.isValidating || RESET_PASSWORD_POST.isValidating
              || successPassReset || tokenInvalid || hasTokenExpired
          }
        />
      </Paper>
    </Container>
  );
}

export default ResetPasswordPage;