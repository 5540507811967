import axios from 'axios';

import { addAuthorizationHeader }    from 'rest/createRestRequestConfig';
import { ALMOST_EXPIRED }            from 'utils/setAutoRefreshSession';
import { AXIOS_BASE_CONFIG }         from 'rest/const';
import createRestRequestConfig       from 'rest/createRestRequestConfig';
import { FINTECH_PATHS }             from 'rest/const';
import { getSecondsUntilExpiration } from 'utils/getSecondsUntilExpiration';
import { PATHS_WO_AUTHORIZATION }    from 'routing/internalPaths';
import { checkIfItsTheAllowedTab }   from 'utils/browserTabsSessionUtils';


/**
 * @throws SessionError
 */
export default function sendRequest(path, method, body, cfg = AXIOS_BASE_CONFIG, authContext, onSessionError) {

  try {
    if (!PATHS_WO_AUTHORIZATION.includes(window.location.pathname))
      checkIfItsTheAllowedTab();
  } catch (e) {
    onSessionError?.(e);
    if (cfg?.throwOnSessionError)  //this is not an actual axios setting
      throw e;
  }

  const mergedCfg = {
    ...AXIOS_BASE_CONFIG,
    ...cfg,
  };

  const headers = {
    'X-Requested-With': 'XMLHttpRequest',
    //TODO put adding Authorization header here
  };

  if (body) {
    const charset           = "charset=utf-8";
    const dataType          = typeof body === "object" ? "application/json" : "application/x-www-form-urlencoded";
    headers["Content-Type"] = dataType + ";" + charset;
  }

  mergedCfg.headers = {
    ...headers,
    ...mergedCfg.headers,
  };

  if (authContext?.userInfo && (authContext.userInfo.refreshPromise //Session refresh is already in progress
            || getSecondsUntilExpiration(authContext.userInfo.accessToken) <= ALMOST_EXPIRED) //OR token almost expired
  ) {
    //There is no point in calling new REST which will throw expired JWT error, so we go straight to refresh and retry
    return new Promise((resolve, reject) => refreshSessionAndRetryPromise(
      path, method, body, mergedCfg, authContext, //SendRequest arguments
      resolve, reject //Nested promise arguments
    ));
  }

  let originalPromise;
  const fixedMethod = typeof method === 'string' ? method.toLowerCase() : method;
  switch (fixedMethod) {
    case 'get':
      originalPromise = axios.get(path, mergedCfg);
      break;
    case 'post':
      originalPromise = axios.post(path, body, mergedCfg);
      break;
    case 'put':
      originalPromise = axios.put(path, body, mergedCfg);
      break;
    case 'patch':
      originalPromise = axios.patch(path, body, mergedCfg);
      break;
    case 'delete':
      mergedCfg.data = body;
      originalPromise = axios.delete(path, mergedCfg);
      break;
    default:
      return new Promise((resolve, reject) => {
        if (axios[fixedMethod] || axios[method]) {
          reject(Error('Unsupported REST method: ' + method));
        } else {
          reject(Error('Unknown REST method: ' + method));
        }
      });
  }

  return new Promise((resolve, reject) => {
    originalPromise
      .then((res) => resolveOrRejectResponse(res, resolve, reject))
      .catch((error) => {
        if (authContext?.hasJWTExpired?.(error)) {
          refreshSessionAndRetryPromise(
            path, method, body, mergedCfg, authContext, //SendRequest arguments
            resolve, reject //Nested promise arguments
          );
        } else {
          reject(error);
        }
      });
  });
}

export function isJWTExpiredError(error) {
  if (!error || error.response?.status !== 401 || !error.response.data?.message) {
    return false;
  }
  const message = error.response.data.message.toLowerCase();
  return message.includes('jwt') && message.includes('expired');
}

async function refreshSessionAndRetryPromise(path, method, body, mergedCfg, authContext,
                                             resolve, reject) {
  const userInfo = authContext.userInfo;
  let setUserInfo;

  if (!userInfo.refreshPromise) { //First one creates promise, all of them will await it
    userInfo.refreshPromise = createRefreshSessionPromise(userInfo.refreshToken);
    setUserInfo = authContext.setUserInfo; //Only first one needs setter
  }

  await userInfo.refreshPromise
    .then((res) => { //Session refresh succeeded, retry the promise
      const newUserInfo = {
        ...userInfo, //Keep username or other values
        ...res.data, //Replace expired tokens with new tokens
        refreshPromise: null, //Do not keep the original promise
        fromRefreshPromise: true, //Will trigger hook to updateCookies
      };
      if (typeof setUserInfo === 'function') {
        setUserInfo(newUserInfo); //First one calls setter
      }
      const newAuthContext = {
        ...authContext,
        userInfo: newUserInfo,
        hasJWTExpired: isJWTExpiredError, //Simplified version, it's temporary authContext
      };

      addAuthorizationHeader(mergedCfg.headers, res.data.accessToken); //Update headers with new tokens

      sendRequest(path, method, body, mergedCfg, newAuthContext) //Retry promise
        .then(resolve)  //sendRequest() handled everything already, just pass it further
        .catch(reject); //sendRequest() handled everything already, just pass it further

    })
    .catch((error) => { //Session refresh failed
      if (isJWTExpiredError(error)) { //Refresh token is expired too
        if (typeof setUserInfo === 'function') { //Only the first one logs warning and logs user out
          console.warn('Attempted to refresh session, but refresh token was expired');
          setUserInfo(null); //LOGOUT
        }
      } else {
        reject(error);
      }
    });
}

export function isResponseSuccess(status) {
  return status >= 200 && status < 300;
}

export function resolveOrRejectResponse(response, resolve, reject) {
  if (isResponseSuccess(response.status)) {
    resolve(response);
  } else {
    reject(response);
  }
}

export function createRefreshSessionPromise(refreshToken) {
  const axiosCfg = createRestRequestConfig(refreshToken, true);
  return axios.post(FINTECH_PATHS.refreshSession, null, axiosCfg);
}

/**
 * <pre>
 * Do not display it to user
 *
 * Returns message if found
 * Returns null if error didn't send any data
 * Returns undefined if error provided data, but message wasn't provided
 * </pre>
 *
 * @param error {Error} - error returned from REST
 * @returns {String | null | undefined}
 */
export function getErrorMessage(error) {
  const data = error.response?.data;
  if (!data) {
    return null; //null if no data at all
  }
  if (typeof data === 'string') {
    return data;
  }
  return data.message; //undefined or message
}
