import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  credit: {
    ...theme.components.Cell.credit,
  },
  debit: {
    ...theme.components.Cell.debit,
  },
}));

export default useStyles;