import React from 'react';

import PropTypes              from 'prop-types';
import Grid                   from '@mui/material/Grid';
import { useTranslation }     from 'react-i18next';
import { GRID_MARGIN_BOTTOM } from '../detailsAndOperationsTabConst';
import { GRID_SPACING }       from '../detailsAndOperationsTabConst';
import OperationButton        from 'pages/CardDetailsPage/tabs/DetailsAndOperationsTab/OperationButton/OperationButton';


function ButtonsRow(props) {

  const { t } = useTranslation('cardDetails');

  return (
    <Grid
      container
      alignItems='center'
      spacing={GRID_SPACING}
      marginBottom={GRID_MARGIN_BOTTOM}
    >
      <Grid item>
        <OperationButton
          disabled={props.status !== 'ACTIVE' || props.disabled}
          title={props.status !== 'ACTIVE' ? t('cardIsNotActive') : ''}
          onClick={props.temporarilyBlockCard}
        >
          {t('temporarilyBlockCardButton')}
        </OperationButton>
      </Grid>
      <Grid item>
        <OperationButton
          disabled={props.status !== 'BLOCKED' || props.disabled}
          title={props.status !== 'BLOCKED' ? t('cardIsNotBlocked') : ''}
          onClick={props.unblockCard}
        >
          {t('cardUnblockButton')}
        </OperationButton>
      </Grid>

      <Grid item>
        <OperationButton
          disabled={props.status !== 'ACTIVE' || props.disabled}
          title={props.status !== 'ACTIVE' ? t('cardIsNotActive') : ''}
          onClick={props.blockLostCard}
        >
          {t('blockLostCard')}
        </OperationButton>
      </Grid>
      <Grid item>
        <OperationButton
          disabled={props.status !== 'ACTIVE' || props.disabled}
          title={props.status !== 'ACTIVE' ? t('cardIsNotActive') : ''}
          onClick={props.blockStolenCard}
        >
          {t('blockStolenCard')}
        </OperationButton>
      </Grid>

      <Grid item>
        <OperationButton
          disabled={props.status !== 'ACTIVE' || props.disabled}
          title={props.status !== 'ACTIVE' ? t('cardIsNotActive') : ''}
          onClick={props.changePin}
        >
          {t('changePINButton')}
        </OperationButton>
      </Grid>
    </Grid>
  );
}

ButtonsRow.propTypes = {
  status:      PropTypes.string.isRequired,

  temporarilyBlockCard: PropTypes.func.isRequired,
  unblockCard:          PropTypes.func.isRequired,
  blockLostCard:        PropTypes.func.isRequired,
  blockStolenCard:      PropTypes.func.isRequired,
  changePin:            PropTypes.func.isRequired,
};

export default ButtonsRow;