import React                  from 'react';
import Accordion              from '@mui/material/Accordion';
import AccordionSummary       from '@mui/material/AccordionSummary';
import ExpandMoreIcon         from '@mui/icons-material/ExpandMore';
import Grid                   from '@mui/material/Grid';
import Typography             from '@mui/material/Typography';
import AccordionDetails       from '@mui/material/AccordionDetails';
import MerchantWhitelistTable from './MerchantWhitelistTable';
import { useTranslation }     from 'react-i18next';
import AddMerchantButton      from './AddMerchantButton';
import useStyles              from './merchantWhitelistInAccordionStyles';


function MerchantWhitelistInAccordion() {

  const { classes } = useStyles();
  const { t }       = useTranslation('cardDetails');

  const [tableExpanded, setTableExpanded] = React.useState(false);

  return (
    <Accordion
      expanded={tableExpanded}
      onChange={(_, expanded) => setTableExpanded(expanded)}
    >
      <Grid
        container
        alignItems='center'
        justifyContent='space-between'
      >
        <Grid item flexGrow={1}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            className={classes.accordionSummary}
          >
            <Typography className={classes.accordionSummaryTitle}>
              {t('merchantWhitelistTableTitle')}
            </Typography>
          </AccordionSummary>
        </Grid>

        {tableExpanded &&
          <Grid item marginRight={2}>
            <AddMerchantButton/>
          </Grid>
        }
      </Grid>

      <AccordionDetails>
        <MerchantWhitelistTable/>
      </AccordionDetails>
    </Accordion>
  );
}

export default MerchantWhitelistInAccordion;