import React from 'react';

import PropTypes          from 'prop-types';
import Grid               from '@mui/material/Grid';
import Typography         from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import isBlank            from 'utils/isBlank';

const DEFAULT_NUMBER_FORMAT = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

function AvailableBalance(props) {

  const { t } = useTranslation('cardDetails');

  let availableBalance = props.availableBalance;
  if (availableBalance == null) //keep 0 in mind
    return null;

  if (typeof availableBalance !== 'number')
    availableBalance = parseFloat(props.availableBalance);

  if (isNaN(availableBalance))
    return null;

  availableBalance = availableBalance.toLocaleString(undefined, DEFAULT_NUMBER_FORMAT);

  if (isBlank(availableBalance))
    return null;

  if (!isBlank(props.currency))
    availableBalance += ' ' + props.currency;


  return (
    <Grid container direction='column'>
      <Grid item>
        <Typography>
          {t('availableBalance')}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant='h3'>
          {availableBalance}
        </Typography>
      </Grid>
    </Grid>
  );
}

AvailableBalance.propTypes = {
  availableBalance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currency:         PropTypes.string,
};

export default AvailableBalance;