import { useParams }                    from 'react-router-dom';
import React                            from 'react';
import { AuthContext }                  from 'App';
import useREST                          from 'rest/useREST';
import useCurrentAndAvailable3DSMethods from './useCurrentAndAvailable3DSMethods';
import createRestRequestConfig          from 'rest/createRestRequestConfig';
import { FINTECH_PATHS }                from 'rest/const';
import sha256AndBase64                  from 'utils/sha256AndBase64';


export default function useChange3DSAnswer() {

  const urlParams    = useParams();
  const cardId       = urlParams.cardId;
  const { userInfo } = React.useContext(AuthContext);

  const [inProgress, setInProgress] = React.useState(false);

  const { mutate } = useCurrentAndAvailable3DSMethods();

  const rest = useREST();

  function resetStateAndMutate() {
    setInProgress(false);
    mutate();
  }

  async function change3DSAnswer(otp, answerString) {
    const axiosCfg = createRestRequestConfig(userInfo.accessToken, true);
    const path     = FINTECH_PATHS.change3DSAnswer.replace('{cardId}', cardId);

    setInProgress(true);

    let hashedAndEncoded;
    try {
      hashedAndEncoded = await sha256AndBase64(answerString);
    } catch (cryptoError) {
      console.error("Error while hashing and encrypting 3DS answer: ", cryptoError);
      resetStateAndMutate();
      throw cryptoError;
    }

    const body = {
      answer: hashedAndEncoded,
      otp:    otp,
    };

    let response;
    try {
      response = await rest([path, 'put', body, axiosCfg]);
    } catch (err) {
      console.error("Error while changing 3DS answer", err);
      resetStateAndMutate();
      throw err;
    }

    resetStateAndMutate();
    return response;
  }

  return {
    inProgress,
    change3DSAnswer,
  };
}