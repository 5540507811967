import React from 'react';

import PropTypes        from 'prop-types';
import Accordion        from '@mui/material/Accordion';
import Typography       from '@mui/material/Typography';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon   from '@mui/icons-material/ExpandMore';
import useStyles        from './tableAccordionStyles';


function TableAccordion(props) {

  const { classes } = useStyles();

  return (
    <Accordion TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon/>}
        className={classes.accordionSummary}
      >
        <Typography className={classes.accordionSummaryTitle}>
          {props.summary}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {props.children}
      </AccordionDetails>
    </Accordion>
  );
}

TableAccordion.propTypes = {
  summary: PropTypes.node,
  children: PropTypes.node,
};

export default TableAccordion;