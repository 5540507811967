import React from 'react';


export default function columnOptions(t, classes, cardStatuses) {
  return {
    card: {
      Cell: (tableData) => {
        const row = tableData.row.original;
        return (
          <>
            <div className={classes.productName}>{row.productName}</div>
            <div>{row.cardMask}</div>
          </>
        );
      },
      muiTableHeadCellProps: {
        align: 'left',
        className: classes.otherCells,
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
    },
    expDate: {
      muiTableHeadCellProps: {
        align: 'left',
        className: classes.otherCells,
      },
      muiTableBodyCellProps: {
        align: 'left',
        className: classes.otherCells,
      },
    },
    status: {
      Cell: (tableData) => {
        const row = tableData.row.original;
        return (
          <div>
            {cardStatuses.t(row.status)}
          </div>
        );
      },
      muiTableHeadCellProps: {
        align: 'left',
        className: classes.otherCells,
      },
      muiTableBodyCellProps: {
        align: 'left',
        className: classes.otherCells,
      },
    },
  };
};
