import React              from 'react';
import useStyles          from './debitCreditRadioButtonsStyles';
import { useTranslation } from 'react-i18next';

import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup       from '@mui/material/RadioGroup';
import Radio            from '@mui/material/Radio';


import PropTypes from 'prop-types';


function DebitCreditRadioButtons(props) {

  const { classes } = useStyles();
  const { t }       = useTranslation('cardDetails', { keyPrefix: 'filters' });

  function onChange(evt) {
    props.setAmount(prevAmount => {
      const prevAmountOrDefault = prevAmount || {};
      const newAmount           = { ...prevAmountOrDefault };
      newAmount.include         = evt.target.value;

      return newAmount;
    });
  }

  return (
    <RadioGroup
      row
      value={props?.amount?.include || 'all'}
      onChange={onChange}
      className={classes.radioGroup}
    >
      <FormControlLabel
        label={t('includeDebit')}
        value='debit'
        control={<Radio/>}
      />
      <FormControlLabel
        label={t('includeCredit')}
        value='credit'
        control={<Radio/>}
      />
      <FormControlLabel
        label={t('includeAll')}
        value='all'
        control={<Radio/>}
      />
    </RadioGroup>
  );
}

DebitCreditRadioButtons.propTypes = {
  amount:    PropTypes.shape({
    from:    PropTypes.string,
    to:      PropTypes.string,
    include: PropTypes.oneOf(['debit', 'credit', 'all']),
  }),
  setAmount: PropTypes.func.isRequired,
};

export default DebitCreditRadioButtons;