import { useRouteError } from "react-router-dom";

import GenericError           from 'pages/ErrorPage/GenericError';
import Grid                   from '@mui/material/Grid';
import NotFoundError          from 'pages/ErrorPage/NotFoundError';
import { SessionError }       from 'utils/browserTabsSessionUtils';
import SessionUniquenessError from 'components/SessionUniquenessError';


export default function ErrorPage() {

  const error = useRouteError();

  let errorElement = <GenericError error={error}/>

  if (error.status === 404)
    errorElement = <NotFoundError/>

  if (error instanceof SessionError)
    return (
      <SessionUniquenessError error={error}/>
    );

  return (
    <Grid container justifyContent='center'>
      <Grid item>
        {errorElement}
      </Grid>
    </Grid>
  );
}