import React                  from 'react';
import { useContext }         from 'react';
import { useLocation }        from 'react-router-dom';

import { Navigate }           from "react-router-dom";
import SessionUniquenessError from 'components/SessionUniquenessError';

import { AuthContext }             from 'App';
import internalPaths               from 'routing/internalPaths';
import isAuthorized                from 'routing/Authorization/isAuthorized';
import { PATHS_WO_AUTHORIZATION }  from 'routing/internalPaths';
import { checkIfItsTheAllowedTab } from 'utils/browserTabsSessionUtils';


function Authorization(props) {

  const [sessionUniquenessError, setSessionUniquenessError] = React.useState(null);

  const { userInfo } = useContext(AuthContext);

  const location = useLocation();

  let authorized = isAuthorized(userInfo, location);
  let allowed;

  if (PATHS_WO_AUTHORIZATION.includes(location.pathname))
    allowed = true;
  else
    allowed = authorized;

  React.useEffect(() => {
    try {
      if (PATHS_WO_AUTHORIZATION.includes(location.pathname))
        setSessionUniquenessError(undefined);
      else
        checkIfItsTheAllowedTab();
    } catch (err) {
      console.error('Error caught on location change: ', err);
      setSessionUniquenessError(err);
    }
  }, [location]);

  if (sessionUniquenessError) {
    return (
      <SessionUniquenessError error={sessionUniquenessError}/>
    );
  }

  if (!allowed) {
    const state   = {
      goBackTo: location.pathname + location.search + location.hash
    }
    //1 of the Fintech WWW pages failed auth, so we send back to login, don't send back to appSelection
    return <Navigate  to={internalPaths.login} state={state}/>
  }

  return props.children;
}

export default Authorization;