import React              from 'react';
import usePOST            from 'rest/usePOST';
import { useTranslation } from 'react-i18next';

import Alert         from 'components/Alert';
import Button        from '@mui/material/Button';
import Dialog        from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle   from '@mui/material/DialogTitle';
import TextField     from '@mui/material/TextField';

import { commonOTPErrorHandled } from 'rest/const';
import { FINTECH_PATHS }         from 'rest/const';
import { getErrorMessage }       from 'rest/sendRequest';

function getValue(value) {
  if (!value) {
    return '';
  }
  if (typeof value === 'object') {
    return {...value};
  }
  return value;
}


export default function EditDialog({ user, axiosCfg, editableField, closeDialog }) {

  const { t } = useTranslation();

  const [alert,    setAlert]    = React.useState(undefined);
  const [value,    setValue]    = React.useState(() => getValue(user[editableField.fieldName]));
  const [postPath, setPostPath] = React.useState(null);

  usePOST(postPath, value, axiosCfg, {
    onSuccess: (res) => {
      setPostPath(null);
      user[editableField.fieldName] = value; //Update the value without redownloading it whole
      closeDialog();

      if (process.env.NODE_ENV === 'development') {
        console.log('EditDialog, ' + postPath + ', onRestSuccess: ', res.data);
      }
    },
    onError: (err) => {
      setPostPath(null);

      const otpErrorHandler = (errKey) => {
        setAlert({
          msgTranslKey: errKey,
          type:         'error',
        });
      };
      if (!commonOTPErrorHandled(err, otpErrorHandler)) {
        setAlert({
          msgTranslKey: 'serverError',
          type:         'warning',
        });
      }

      if (process.env.NODE_ENV === 'development') {
        console.log('EditDialog, ' + postPath + ', onRestError: ', getErrorMessage(err), err.response, err);
        console.error('EditDialog, ' + postPath + ', onRestError: ', err);
      }
    },
  });

  const FieldComponent = editableField.EditComponent || TextField;

  return (
    <Dialog
      open={true}
      fullWidth
      maxWidth={'sm'}
      onClose={(evt, type) => {
        if (type === 'backdropClick') {
          return; //Don't close on backdrop click
        }
        closeDialog();
      }}
    >
      <DialogTitle>{t('editDialogTitle')}</DialogTitle>
      <DialogContent dividers>
        <div>
          {alert &&
            <Alert alert={alert} setAlert={setAlert}/>
          }
          <FieldComponent
            fullWidth
            required
            value={value}
            onChange={(evt) => setValue(evt.target.value)}
            label={t(editableField.labelKey)}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          id='submitButton'
          onClick={() => setPostPath(FINTECH_PATHS[editableField.pathKey])}
          color='primary'
          variant='contained'
        >
          {t('submitButton')}
        </Button>
        <Button
          id='cancelButton'
          onClick={closeDialog}
          color='primary'
          variant='outlined'
        >
          {t('cancelButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
