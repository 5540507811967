import React from 'react';

import PropTypes          from 'prop-types';
import Grid               from '@mui/material/Grid';
import Typography         from '@mui/material/Typography';
import Button             from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import useStyles          from 'components/CommonForm/commonFormStyles';


function UntrustedDeviceChoiceForm(props) {

  const { t }       = useTranslation('common');
  const { classes } = useStyles();

  return (
    <Grid
      container
      spacing={1}
      paddingTop={2}
      paddingBottom={3}
      paddingLeft={1}
      paddingRight={1}
      alignItems='center'
      justifyContent='center'
      className={classes.centerText}
    >
      <Grid item xs={12}>
        <Typography variant='h5' fontWeight='bold'>
          {t('untrustedDevice')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          {t('addToTrustedOrUseOneTimeLogin')}
        </Typography>
      </Grid>
      <Grid item>
        <Button
          onClick={props.chooseAddToTrusted}
        >
          {t('addToTrustedButton')}
        </Button>
      </Grid>
      <Grid item>
        <Button
          onClick={props.chooseUseOneTimeLogin}
        >
          {t('oneTimeLoginButton')}
        </Button>
      </Grid>
    </Grid>
  );
}

UntrustedDeviceChoiceForm.propTypes = {
  chooseAddToTrusted:    PropTypes.func.isRequired,
  chooseUseOneTimeLogin: PropTypes.func.isRequired,
};

export default UntrustedDeviceChoiceForm;