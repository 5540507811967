import createRestRequestConfig from 'rest/createRestRequestConfig';
import React                   from 'react';
import { AuthContext }         from 'App';
import { FINTECH_PATHS }       from 'rest/const';
import usePOST                 from 'rest/usePOST';


export default function useOTP(onSuccess, onError) {


  const { userInfo } = React.useContext(AuthContext);

  const [otpRequestDetails, setOtpRequestDetails] = React.useState(undefined);

  const axiosCfg = createRestRequestConfig(userInfo.accessToken, true);


  const otpSwrOpts = {
    revalidateIfStale:     true,
    revalidateOnFocus:     false,
    revalidateOnReconnect: false,
    //since we control sending of the requests by setting and unsetting the path
    // deduplication could break the state of the component - onSuccess is not
    // called when deduplication stops the request
    dedupingInterval:      0,
    onSuccess:             (res) => {
      setOtpRequestDetails(undefined);
      onSuccess?.(res);
    },
    onError:               (err) => {
      setOtpRequestDetails(undefined);
      onError?.(err);
    },
  };


  const { isValidating, mutate }
          = usePOST(otpRequestDetails?.path, otpRequestDetails?.body, axiosCfg, otpSwrOpts);

  const sendOtp = React.useCallback((type) => {
    setOtpRequestDetails({
      path: FINTECH_PATHS.cardOpsCallOtp,
      body: {
        type: type,
      },
    });
  }, []);


  return { sendOtp, mutate, isValidating };
}