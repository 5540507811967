import React                            from 'react';
import useREST                          from 'rest/useREST';
import createRestRequestConfig          from 'rest/createRestRequestConfig';
import { AuthContext }                  from 'App';
import { useParams }                    from 'react-router-dom';
import { FINTECH_PATHS }                from 'rest/const';
import useCurrentAndAvailable3DSMethods from './useCurrentAndAvailable3DSMethods';


export default function useChange3DSMethod() {

  const urlParams    = useParams();
  const cardId       = urlParams.cardId;
  const { userInfo } = React.useContext(AuthContext);
  const { mutate }   = useCurrentAndAvailable3DSMethods();

  const [inProgress, setInProgress] = React.useState(false);

  const rest = useREST();

  function resetStateAndMutate() {
    setInProgress(false);
    mutate();
  }

  function change3DSMethod(otp, method, onSuccess, onError) {
    setInProgress(true);

    const body = {
      method,
      otp,
    };

    const axiosCfg = createRestRequestConfig(userInfo.accessToken, true);
    const path     = FINTECH_PATHS.change3DSMethod.replace('{cardId}', cardId);

    rest([path, 'put', body, axiosCfg])
      .then(onSuccess)
      .catch(err => {
        console.error('Error while changing 3DS authentication method: ', err);
        onError(err);
      })
      .then(resetStateAndMutate);
  }

  return {
    inProgress,
    change3DSMethod,
  };
}