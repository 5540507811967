import { useParams }           from 'react-router-dom';
import React                   from 'react';
import { AuthContext }         from 'App';
import createRestRequestConfig from 'rest/createRestRequestConfig';
import { FINTECH_PATHS }       from 'rest/const';
import useGET                  from 'rest/useGET';


export default function useCurrentAndAvailable3DSMethods() {
  const urlParams    = useParams();
  const cardId       = urlParams.cardId;
  const { userInfo } = React.useContext(AuthContext);
  const axiosCfg     = createRestRequestConfig(userInfo.accessToken, true);

  const path = FINTECH_PATHS.getCurrentAndAvailable3DSMethods.replace("{cardId}", cardId);

  const current3DSMethodGET  = useGET(path, axiosCfg, undefined);
  const response             = current3DSMethodGET.data;
  const data                 = response?.data;
  const currentAuthMethod    = data?.current;
  const availableAuthMethods = data?.available;


  return {
    currentAuthMethod,
    availableAuthMethods,
    ...current3DSMethodGET,
  };
}