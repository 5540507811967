/**
 * IMPORTANT
 * secp256r1, prime256v1 and NIST P-256 are the same thing!
 */


export async function generateKeyPairWithPromise() {
  return await window.crypto.subtle.generateKey(
    {
      name:       "ECDH",
      namedCurve: "P-256"
    },
    true,
    ["deriveKey"]
  )
}

/**
 * Exports public key to a format accepted by middleware, which is
 * PEM without prefix end postfix.
 */
export async function getSendablePublicKeyWithPromise(keyPair) {
  const exported = await window.crypto.subtle.exportKey(
    "spki",
    keyPair.publicKey
  );
  const exportedAsString = ab2str(exported);
  return window.btoa(exportedAsString);
}

/**
 *
 * @param data {string} base64-encoded data to be decrypted
 * @param privateKey {object} key pair generated by WebCrypto; in
 * elliptic curve cryptography a key pair and a private key are the
 * same thing - a private key and a public key are not separate: the
 * private key contains public key
 * @param securityKey {string} base64-encoded
 * @param initializationVector {string} initialization vector,
 * sometimes called iv or nonce, used for AES-GCM decryption
 * @returns {string} decrypted data
 */
export async function decrypt(data, privateKey, securityKey, initializationVector) {
  const importedSecurityKey = await importSecurityKey(securityKey);
  const derivedKey          = await deriveSecretKey(privateKey.privateKey, importedSecurityKey);

  const binaryDataDer = pem2der(data);
  const binaryIvDer   = pem2der(initializationVector);

  const decryptedArrayBuffer = await window.crypto.subtle.decrypt(
    {
      name:      "AES-GCM",
      iv:        binaryIvDer,
      tagLength: 128
    },
    derivedKey,
    binaryDataDer
  );

  return ab2str(decryptedArrayBuffer);
}

/**
 Derive an AES key, given:
 - our ECDH private key
 - their ECDH public key
 */
function deriveSecretKey(privateKey, publicKey) {
  return window.crypto.subtle.deriveKey(
    {
      name: "ECDH",
      public: publicKey
    },
    privateKey,
    {
      name:   "AES-GCM",
      length: 256 //FIXME we have no idea why 256 instead of 128...
    },
    false,
    ["decrypt"]
  );
}

/**
 * PEM to DER converter; PEM is DER with prefix, postfix and base63
 * encoding.
 * @param pem {String}
 * @returns {ArrayBuffer}
 */
function pem2der(pem) {
  const binaryDerDataString = window.atob(pem);
  return str2ab(binaryDerDataString);
}

/**
 * ArrayBuffer to String converter.
 */
function ab2str(buf) {
  return String.fromCharCode.apply(null, new Uint8Array(buf));
}

/**
 * String to ArrayBuffer converter.
 * @param str
 * @returns {ArrayBuffer}
 */
function str2ab(str) {
  const buf     = new ArrayBuffer(str.length);
  const bufView = new Uint8Array(buf);
  for (let i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
}

function importSecurityKey(securityKey) {
  const binaryDerString = window.atob(securityKey);
  const binaryDer       = str2ab(binaryDerString);

  return window.crypto.subtle.importKey(
    'spki',
    binaryDer,
    {
      name:       "ECDH",
      namedCurve: "P-256"
    },
    true,
    //normally ['deriveKey'] should be here, but Chrome requires
    // empty array which also works on FF, so empty array it is
    []
  )
}
