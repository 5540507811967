import isBlank from 'utils/isBlank';


const MIN_CHAR_VARIETY = 3;
const MIN_LENGTH = 6;

/**
 *
 * @param answer the answer to be checked
 * @param t translation function with `cardDetails` namespace loaded
 */
export default function checkAnswerComplexity(answer, t) {
  if (isBlank(answer) || answer.length < MIN_LENGTH) {
    return t('cardDetails:answerTooShort');
  }
  const set = new Set(answer);
  if (set.size < MIN_CHAR_VARIETY) {
    return t('cardDetails:useAtLeastThreeDifferentCharacters');
  }
  return null;
}