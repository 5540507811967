import React from 'react';

import PropTypes             from 'prop-types';
import Grid                  from '@mui/material/Grid';
import Typography            from '@mui/material/Typography';
import { useTranslation }    from 'react-i18next';
import LabelledPieceOfInfo   from 'pages/CardDetailsPage/tabs/AccountInfoTab/LabelledPieceOfInfo';
import { END_PLACEHOLDER }   from 'pages/CardDetailsPage/tabs/AccountInfoTab/accountInfoTabConst';
import { BEGIN_PLACEHOLDER } from 'pages/CardDetailsPage/tabs/AccountInfoTab/accountInfoTabConst';


function CurrentPeriod(props) {

  const { t } = useTranslation('cardDetails');

  if (!props.period)
    return null;

  const debitAmount = props.period.debitAmount + props.currencyName;

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <Typography fontWeight='semibold'>
          {t('currentBillingPeriod')
            .replace(BEGIN_PLACEHOLDER, props.period.periodBegin)
            .replace(END_PLACEHOLDER, props.period.periodEnd)
          }
        </Typography>
      </Grid>
      <Grid item>
        <LabelledPieceOfInfo label={t('expensesInThePeriod')} info={debitAmount}/>
      </Grid>
    </Grid>
  );
}

CurrentPeriod.propTypes = {
  period:       PropTypes.shape({
    periodBegin: PropTypes.string.isRequired,
    periodEnd:   PropTypes.string.isRequired,
    debitAmount: PropTypes.number.isRequired
  }),
  currencyName: PropTypes.string,
};

export default CurrentPeriod;