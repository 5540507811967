import React from 'react';

import { CARD_STATUSES_NS }  from 'i18n';
import PropTypes             from 'prop-types';
import Grid                  from '@mui/material/Grid';
import Typography            from '@mui/material/Typography';
import OperationButton       from 'pages/CardDetailsPage/tabs/DetailsAndOperationsTab/OperationButton';
import { useTranslation }    from 'react-i18next';
import { VIRTUAL_TECH_MODE } from '../detailsAndOperationsTabConst';


function CardDetail(props) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Typography>
          {props.label}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        {props.children}
      </Grid>
    </Grid>
  );
}

function CardDetails(props) {

  const { t }        = useTranslation('cardDetails');
  const cardStatuses = useTranslation(CARD_STATUSES_NS);

  function extractContactless() {
    return props.card.contactless === true ? t('contactlessTrue') : t('contactlessFalse');
  }

  const toggleContactlessButtonText
          = props.card.contactless === true ? t('turnContactlessOffButton') : t('turnContactlessOnButton');


  const disableFetchEmboss = props.disableButtons
    || (props.card.status !== 'ACTIVE' && props.card.status !== 'ORDERED');

  const detailWithButtonGridProps = {
    container:  true,
    alignItems: 'center',
    spacing:    2,
  };
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <CardDetail label={t('productName')}>
          <Typography fontWeight='semibold'>
            {props.card.productName}
          </Typography>
        </CardDetail>
      </Grid>

      <Grid item xs={12}>
        <CardDetail label={t('status')}>
          <Grid {...detailWithButtonGridProps}>
            <Grid item>
              <Typography fontWeight='semibold'>
                {cardStatuses.t(props.card.status)}
              </Typography>
            </Grid>
            <Grid item>
              {props.card.status === 'INACTIVE' &&
                <OperationButton
                  onClick={props.activateCard}
                  disabled={props.disableButtons}
                >
                  {t('activateCardButton')}
                </OperationButton>
              }
            </Grid>
          </Grid>
        </CardDetail>
      </Grid>

      <Grid item xs={12}>
        <CardDetail label={t('expDate')}>
          <Typography fontWeight='semibold'>
            {props.card.expDate}
          </Typography>
        </CardDetail>
      </Grid>

      {props.card.cardTechnologyMode !== VIRTUAL_TECH_MODE &&
        <Grid item xs={12}>
          <CardDetail label={t('contactless')}>
            <Grid {...detailWithButtonGridProps}>
              <Grid item>
                <Typography fontWeight='semibold'>
                  {extractContactless()}
                </Typography>
              </Grid>
              <Grid item>
                <OperationButton
                  disabled={props.disableButtons || props.card.status !== 'ACTIVE'}
                  onClick={props.toggleContactless}
                >
                  {toggleContactlessButtonText}
                </OperationButton>
              </Grid>
            </Grid>
          </CardDetail>
        </Grid>
      }

      <Grid item xs={12}>
        <CardDetail label={t('embossedInfo')}>
          <OperationButton
            disabled={disableFetchEmboss}
            onClick={props.fetchEmbossInfo}
          >
            {t('showEmbossedInfo')}
          </OperationButton>
        </CardDetail>
      </Grid>
    </Grid>
  );
}

CardDetails.propTypes = {
  activateCard:   PropTypes.func,
  disableButtons: PropTypes.bool,
  card:           PropTypes.shape({
    productName:        PropTypes.string.isRequired,
    primaryAccountNo:   PropTypes.string.isRequired,
    status:             PropTypes.string.isRequired,
    cardTechnologyMode: PropTypes.string.isRequired,
  }).isRequired,
};

export default CardDetails;