export const WHITESPACE_REGEX = /\s/g;

export default function isBlank(value) {
  if (value == null)
    return true;
  if (typeof value !== "string")
    return false;

  const whiteSpaceRemoved = value.replace(WHITESPACE_REGEX, '');
  return whiteSpaceRemoved === '';
}