import React              from 'react';
import TokensTable        from './TokensTable';
import TokenActionsDialog from './TokenActionsDialog';
import Typography         from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Grid               from '@mui/material/Grid';
import { DELETE_MODE }    from 'pages/CardDetailsPage/tabs/MobilePaymentsTab/TokenActionsDialog/TokenActionsDialog';


export const DeleteTokenDialogContext = React.createContext(undefined);
export const MutateContext            = React.createContext(undefined);


/**
 * This component's purpose is to separate 'DeleteTokeDialog` from
 * the table. If the dialog was a successor of a row in the table, it
 * would disappear when table is refreshed, making it impossible to
 * refresh the table and to present result inside the dialog at the
 * same time. On the other hand, if tha dialog was managed by
 * `TokensTable` it would be really slow, because the table would
 * refresh when dialog opens or closes.
 *
 * Current solution involves memoization of the table with `React.memo` which
 * fixes performance.
 * @returns {JSX.Element}
 * @constructor
 */
function MobilePaymentsTab() {

  const { t } = useTranslation('cardDetails');

  const [tokenToDelete, setTokenToDelete] = React.useState(undefined);

  const mutateRef = React.useRef();

  function closeDialog() {
    setTokenToDelete(undefined);
  }

  return (
    <DeleteTokenDialogContext.Provider value={setTokenToDelete}>
      <MutateContext.Provider value={mutateRef}>
        {tokenToDelete &&
          <TokenActionsDialog
            mode={DELETE_MODE}
            token={tokenToDelete}
            closeDialog={closeDialog}
          />
        }
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              {t('tokensTableInstruction')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TokensTable mutateRef={mutateRef}/>
          </Grid>
        </Grid>
      </MutateContext.Provider>
    </DeleteTokenDialogContext.Provider>
  );
}

export default MobilePaymentsTab;