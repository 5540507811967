import React              from 'react';
import { useTranslation } from 'react-i18next';

import Button      from '@mui/material/Button';
import Grid        from '@mui/material/Grid';
import { NavLink } from 'react-router-dom';
import Typography  from '@mui/material/Typography';

import internalPaths from 'routing/internalPaths';


export default function LogoutPage() {

  const { t } = useTranslation();

  //TODO może dodać ten spinner ładowania w trakcje jak ten /clearCookies trwa i userInfo jeszcze nie zostało znullowane?

  return (
    <Grid
      container
      direction='column'
      alignItems='center'
      spacing={3}
    >
      <Grid item>
        <Typography variant='h5'>
          {t('thankYouAndComeAgain')}
        </Typography>
      </Grid>
      <Grid item>
        <NavLink to={internalPaths.login}>
          <Button color='primary'>
            {t('loginAgain')}
          </Button>
        </NavLink>
      </Grid>
    </Grid>
  );
}
