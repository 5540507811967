import * as React         from 'react';
import TextField          from '@mui/material/TextField';
import Autocomplete       from '@mui/material/Autocomplete';
import parse              from 'autosuggest-highlight/parse';
import match              from 'autosuggest-highlight/match';
import PropTypes          from 'prop-types';
import { useTranslation } from 'react-i18next';
import useStyles          from './autocompleteWithHighlightsStyles';
import classnames         from 'classnames';


export default function AutocompleteWithHighlights(props) {

  const { options, label, textFieldParams, selectedOption, setSelectedOption, ...otherProps } = props;

  const { classes } = useStyles();
  const { t }       = useTranslation('common');

  return (
    <Autocomplete
      sx={{ width: 300 }}
      options={options || []}
      getOptionLabel={(option) => option.label}
      value={selectedOption}
      onChange={(_, selectedOption) => setSelectedOption?.(selectedOption)}

      clearText={t('autocompleteClearText')}
      openText={t('autocompleteOpenText')}
      closeText={t('autocompleteCloseText')}
      loadingText={t('autocompleteLoadingText')}
      noOptionsText={t('autocompleteNoOptionsText')}

      renderInput={(params) => (
        <TextField
          {...params}
          {...textFieldParams}
          className={classnames(classes.textField, textFieldParams.className)}
          label={label}
        />
      )}
      renderOption={(props, option, { inputValue }) => {
        const matches = match(option.label, inputValue, { insideWords: true });
        const parts   = parse(option.label, matches);

        return (
          <li {...props}>
            <div>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{
                    fontWeight: part.highlight ? 700 : 400,
                  }}
                >
                  {part.text}
                </span>
              ))}
            </div>
          </li>
        );
      }}

      {...otherProps}
    />
  );
}

AutocompleteWithHighlights.propTypes = {
  selectedOption:    PropTypes.shape({
    value: PropTypes.any,
    label: PropTypes.string,
  }),
  setSelectedOption: PropTypes.func,
  options:           PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.any,
    label: PropTypes.string,
  })),
  label:             PropTypes.node,
  textFieldParams:   PropTypes.object,
};