import React from 'react';

import PropTypes                 from 'prop-types';
import { useTranslation }        from 'react-i18next';
import { useSnackbar }           from 'notistack';
import useOTP                    from 'pages/CardDetailsPage/tabs/CardLimitsTab/useOTP';
import { OTP_TYPES }             from 'pages/CardDetailsPage/tabs/DetailsAndOperationsTab/detailsAndOperationsTabConst';
import useEmbossInfoAccess       from './useEmbossInfoAccess';
import Dialog                    from '@mui/material/Dialog';
import DialogTitle               from '@mui/material/DialogTitle';
import DialogContent             from '@mui/material/DialogContent';
import Grid                      from '@mui/material/Grid';
import OTPField                  from 'pages/CardDetailsPage/tabs/DetailsAndOperationsTab/OTPField';
import DialogActions             from '@mui/material/DialogActions';
import Button                    from '@mui/material/Button';
import { OTP_FIELD_NAME }        from 'pages/CardDetailsPage/tabs/DetailsAndOperationsTab/OTPField/OTPField';
import { commonOTPErrorHandled } from 'rest/const';
import EmbossInfo                from './EmbossInfo';
import Alert                     from 'components/Alert';


const RESEND_DISABLE_TIMEOUT = 5000;
const AUTO_CLOSE_TIMEOUT     = 120000;

function EmbossInfoAccessDialog(props) {

  const { t }               = useTranslation(['common', 'cardDetails']);
  const { enqueueSnackbar } = useSnackbar();

  const [disableResend, setDisableResend]               = React.useState(false);
  const [alert, setAlert]                               = React.useState(undefined);
  const [showValidityMessages, setShowValidityMessages] = React.useState(false);

  const formRef = React.useRef();

  function onOtpSuccess(res) {
    enqueueSnackbar(t('common:successCallOTP').replace('&{phoneNo}', res.data.phoneNo));
  }

  function handleTooManyOtpAttempts() {
    setAlert({  //setting error message on the field isn't enough, retrying won't help
      msgTranslKey: 'tooManyOtpAttempts',
      type:         'error',
    });
  }

  function onSendOtpError(err) {
    const sendOtpErrorHandler = (errKey) => {
      //only this common error is possible here
      if (errKey === 'tooManyOtpAttempts') {
        handleTooManyOtpAttempts();
      }
    };
    if (commonOTPErrorHandled(err, sendOtpErrorHandler)) {
      return;
    }
    setAlert({
      msgTranslKey: 'failure',
      type:         'error',
    });
  }

  const { sendOtp } = useOTP(onOtpSuccess, onSendOtpError);

  React.useEffect(() => {
    if (!props.open)
      return;
    sendOtp(OTP_TYPES.embossInfoAccess);
  }, [props.open, sendOtp]); //sendOtp is stable

  function resendOtp() {
    setDisableResend(true);
    sendOtp(props.otpType);
    setTimeout(() => setDisableResend(false), RESEND_DISABLE_TIMEOUT);
  }

  function onEmbossInfoAccessError(err) {
    const sendOtpErrorHandler = (errKey) => {
      formRef.current.elements[OTP_FIELD_NAME].setCustomValidity(t(`common:${errKey}`));
      setShowValidityMessages(true);
      if (errKey === 'tooManyOtpAttempts') {
        handleTooManyOtpAttempts();
      }
    };
    if (commonOTPErrorHandled(err, sendOtpErrorHandler)) {
      return;
    }
    setAlert({
      msgTranslKey: 'failure',
      type:         'error',
    });
  }

  const { trigger, embossInfo, isInProgress } =
          useEmbossInfoAccess(props.cardId, props.expDate, onEmbossInfoAccessError, formRef);

  const onCloseRef   = React.useRef();
  onCloseRef.current = props.onClose;

  /**
   * Closes the dialog window automatically after a specified.
   * Starts counting when emboss info shows up.
   */
  React.useEffect(() => {
    if (!embossInfo)
      return;
    const handler = onCloseRef.current;
    const timeout = setTimeout(handler, AUTO_CLOSE_TIMEOUT);
    return () => clearTimeout(timeout);
  }, [embossInfo]);

  function onSubmit(evt) {
    evt.preventDefault()
    const formData = new FormData(evt.target);
    const otp      = formData.get(OTP_FIELD_NAME);
    trigger(otp);
  }

  function onFormInvalid(evt) {
    evt.preventDefault();  //stops tooltips displayed by browser
    setShowValidityMessages(true);
  }

  return (
    <Dialog
      onClose={props.onClose}
      open={!!props.open} //conversion to boolean
    >
      <DialogTitle>
        {t('cardDetails:embossInfoAccessDialogTitle')}
      </DialogTitle>
      <DialogContent>
        <form
          onSubmit={onSubmit}
          onChange={() => setShowValidityMessages(false)}
          onInvalid={onFormInvalid}
          ref={formRef}
        >
          <Grid container direction='column'>
            {!embossInfo && !alert &&
              <Grid item>
                <OTPField
                  onSubmit={props.onSubmit}
                  resend={resendOtp}
                  disableSubmit={isInProgress}
                  disableResend={disableResend || isInProgress}
                  showValidityMessages={showValidityMessages}
                />
              </Grid>
            }
            {embossInfo &&
              <EmbossInfo embossInfo={embossInfo}/>
            }
            {alert &&
              <Alert alert={alert} setAlert={setAlert}/>
            }
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          id="cancelButton"
          onClick={props.onClose}
          color="primary"
          variant="outlined"
        >
          {embossInfo ? t('common:defaultCloseButton') : t('cardDetails:cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

EmbossInfoAccessDialog.propTypes = {
  open:    PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  cardId:  PropTypes.string,
  expDate: PropTypes.string,
};

export default EmbossInfoAccessDialog;