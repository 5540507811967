import useSWRImmutable from 'swr';

export default function useDELETE(path, body, axiosCfg, swrOpts) {
  const method = 'DELETE';

  let swrKey   = [path, method, body, axiosCfg];
  if (path == null)
    swrKey = null; //sending request conditionally

  return useSWRImmutable(swrKey, swrOpts);
}