import React                          from 'react';
import useMerchantWhitelist           from './useMerchantWhitelist';
import MaterialReactTable             from 'material-react-table';
import { useTranslation }             from 'react-i18next';
import { MRT_Localization_PL }        from 'material-react-table/locales/pl';
import useStyles                      from './merchantWhitelistTabStyles';
import useMerchantWhitelistColumns    from './useMerchantWhitelistColumns';
import MerchantWhitelistBottomToolbar from './MerchantWhitelistBottomToolbar';
import DeleteMerchantButton           from './DeleteMerchantButton';


function MerchantWhitelistTable() {

  const { classes } = useStyles();
  const { t }       = useTranslation(['common', 'cardDetails']);

  const {
          merchantWhitelist,
          isLoading,
          isValidating,
        } = useMerchantWhitelist();

  const localization = React.useMemo(
    () => {
      const localization   = t('common:localization', { returnObjects: true });
      localization.actions = '';  //no header for actions column
      return { ...MRT_Localization_PL, ...localization };
    }, [t]
  );

  const columns = useMerchantWhitelistColumns();

  return (
    <MaterialReactTable
      columns={columns}
      data={merchantWhitelist || []}
      localization={localization}
      enableStickyHeader={true}
      enableStickyFooter={true}
      enablePagination={false}
      enableSorting={false}
      enableColumnActions={false}
      //MRT uses row number as row id by default which is okay until you delete a row
      // which is not the last row - in that case state leaks to following component
      getRowId={originalRow => originalRow.merchantId}
      renderTopToolbar={false}
      renderBottomToolbarCustomActions={() => <MerchantWhitelistBottomToolbar/>}

      enableRowActions={true}
      positionActionsColumn='last'
      renderRowActions={({ row }) => <DeleteMerchantButton merchant={row.original}/>}

      muiBottomToolbarProps={{
        className: classes.bottomToolbar,
      }}
      muiTableContainerProps={{
        //maxHeight set to prevent scroll
        sx: { maxHeight: '45vh' },
      }}
      state={{
        isLoading:        isLoading,
        showProgressBars: isLoading || isValidating,
      }}
    />
  );
}

export default React.memo(MerchantWhitelistTable);