import React                     from 'react';
import Grid                      from '@mui/material/Grid';
import { useTranslation }        from 'react-i18next';
import CardDetails               from './CardDetails';
import { AuthContext }           from 'App';
import createRestRequestConfig   from 'rest/createRestRequestConfig';
import usePOST                   from 'rest/usePOST';
import isBlank                   from 'utils/isBlank';
import { FINTECH_PATHS }         from 'rest/const';
import { commonOTPErrorHandled } from 'rest/const';
import ButtonsRow                from './ButtonsRow';
import { OTP_FIELD_NAME }        from 'pages/CardDetailsPage/tabs/DetailsAndOperationsTab/OTPField/OTPField';
import OperationsDialogs         from './OperationsDialogs';
import { OPERATIONS }            from './detailsAndOperationsTabConst';


export default function DetailsAndOperationsTab({ card, refreshData }) {

  const { t } = useTranslation(['common', 'cardDetails']);

  const { userInfo } = React.useContext(AuthContext);

  const [operation, setOperation]                           = React.useState(undefined);
  const [pinChangeDialogOpen, setPinChangeDialogOpen]       = React.useState(false);
  const [embossInfoDialogOpen, setEmbossInfoDialogOpen]     = React.useState(false);
  const [userConfirmed, setUserConfirmed]                   = React.useState(false);

  const [otpCode, setOtpCode]       = React.useState(undefined);
  const [actionPath, setActionPath] = React.useState(undefined);
  const [actionBody, setActionBody] = React.useState(undefined);

  const [alert, setAlert]                               = React.useState(undefined);
  const [showValidityMessages, setShowValidityMessages] = React.useState(false);

  const confirmAndOtpFormRef = React.useRef();

  function resetState() {
    setOperation(undefined);

    setPinChangeDialogOpen(false);
    setEmbossInfoDialogOpen(false);
    setUserConfirmed(false);

    setActionPath(undefined);

    setOtpCode(undefined);
    setActionBody(undefined);

    setAlert(undefined);
    setShowValidityMessages(false);
  }

  const axiosCfg = React.useMemo(() =>
      createRestRequestConfig(userInfo.accessToken, true),
    [userInfo.accessToken]
  );

  const actionSwrOpts = {
    shouldRetryOnError:    false,
    revalidateOnFocus:     false,
    revalidateOnReconnect: false,
    onSuccess:             () => {
      setAlert({
        msgTranslKey: 'success',
        type:         'success',
      });
      setActionPath(undefined);
      refreshData();
    },
    onError:               (err) => {
      const otpErrorHandler = (errKey) => {
        confirmAndOtpFormRef.current?.elements[OTP_FIELD_NAME].setCustomValidity(t(`common:${errKey}`));
        setShowValidityMessages(true);
        if (errKey === 'tooManyOtpAttempts') {
          setAlert({  //setting error message on the field isn't enough, retrying won't help
            msgTranslKey: errKey,
            type:         'error',
          });
        }
        setOtpCode(undefined);
      };
      if (commonOTPErrorHandled(err, otpErrorHandler)) {
        return;
      }
      setAlert({
        msgTranslKey: 'failure',
        type:         'error',
      });
      console.error("Error while performing an action on a card", err)
    },
  };

  let _body = actionBody;
  if (!isBlank(otpCode))
    _body = { ...actionBody, otp: otpCode };

  //don't send until all are ready; some actions require otp
  // while others have a confirm button to prevent missclicks
  const otpReadyOrUserConfirmed = !isBlank(otpCode) || userConfirmed;
  const _path                   = otpReadyOrUserConfirmed ? actionPath : null;

  const { isValidating: isValidatingAction } = usePOST(_path, _body, axiosCfg, actionSwrOpts);

  function replacePlaceholder(path) {
    return path.replace('{cardId}', card.cardId);
  }

  function activateCard() {
    setOperation(OPERATIONS.activateCard);
    setActionPath(replacePlaceholder(FINTECH_PATHS.activateCard));
  }

  function temporarilyBlockCard() {
    setOperation(OPERATIONS.temporarilyBlockCard);
    setActionPath(replacePlaceholder(FINTECH_PATHS.temporarilyBlockCard));
  }

  function unblockCard() {
    setOperation(OPERATIONS.unblockCard);
    setActionPath(replacePlaceholder(FINTECH_PATHS.unblockCard));
  }

  function blockLostCard() {
    setOperation(OPERATIONS.blockLostCard);
    setActionPath(replacePlaceholder(FINTECH_PATHS.blockLostCard));
  }

  function blockStolenCard() {
    setOperation(OPERATIONS.blockStolenCard);
    setActionPath(replacePlaceholder(FINTECH_PATHS.blockStolenCard));
  }

  function toggleContactless() {
    let _operation = OPERATIONS.enableContactless;
    const body     = {
      enable: true,
    };
    if (card.contactless === true) {
      _operation  = OPERATIONS.disableContactless;
      body.enable = false;
    }

    setOperation(_operation);

    setActionBody(body);
    setActionPath(replacePlaceholder(FINTECH_PATHS.changeContactless));
  }

  function closeAlert() {
    if (alert?.type === 'success')
      resetState();
    setAlert(undefined);
  }


  return (
    <Grid container spacing={1}>
      <Grid item>
        <ButtonsRow
          status={card.status}

          temporarilyBlockCard={temporarilyBlockCard}
          unblockCard={unblockCard}
          blockLostCard={blockLostCard}
          blockStolenCard={blockStolenCard}
          changePin={() => {
            setPinChangeDialogOpen(true);
          }}
        />
      </Grid>

      <Grid item>
        <CardDetails
          card={card}
          activateCard={activateCard}
          toggleContactless={toggleContactless}
          disableButtons={isValidatingAction}
          fetchEmbossInfo={() => {
            setEmbossInfoDialogOpen(true)
          }}
        />
      </Grid>

      <OperationsDialogs
        operation={operation}
        onConfirmation={() => setUserConfirmed(true)}
        setOtpCode={setOtpCode}
        onClose={resetState}
        alert={alert}
        closeAlert={closeAlert}
        disableSubmit={isValidatingAction}
        formRef={confirmAndOtpFormRef}
        showValidityMessages={showValidityMessages}

        pinChangeDialogOpen={pinChangeDialogOpen}
        embossInfoDialogOpen={embossInfoDialogOpen}
        cardId={card.cardId}
        expDate={card.expDate}
      />
    </Grid>
  );
}
