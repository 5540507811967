import React                           from 'react';
import Button                          from '@mui/material/Button';
import { useTranslation }              from 'react-i18next';
import { DeleteMerchantDialogContext } from 'pages/CardDetailsPage/tabs/ThreeDSTab/ThreeDSTab';
import PropTypes                       from 'prop-types';


function DeleteMerchantButton(props) {

  const { t } = useTranslation('common');

  const setMerchantToDelete = React.useContext(DeleteMerchantDialogContext);

  return (
    <Button onClick={() => setMerchantToDelete(props.merchant)}>
      {t('deleteButton')}
    </Button>
  );
}

DeleteMerchantButton.propTypes = {
  merchant: PropTypes.shape({
    merchantId:     PropTypes.string.isRequired,
    merchantAcqBin: PropTypes.string.isRequired,
    merchantName:   PropTypes.string.isRequired,
  }).isRequired,
};

export default DeleteMerchantButton;