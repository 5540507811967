import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  radioGroup: {
    '& .MuiFormControlLabel-label': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
}));

export default useStyles;