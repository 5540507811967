import React     from 'react';
import useStyles from './footerStyles'

import Grid from '@mui/material/Grid';

import ITCARDLogo from '../resources/img/logoitcard.svg';


export default function Footer() {
  const { classes } = useStyles();

  return (
    <Grid
      container
      justifyContent='center'
      className={classes.container}
    >
      <Grid item>
        <img
          src={ITCARDLogo}
          alt='ITCARD'
          className={classes.logo}
        />
      </Grid>
    </Grid>
  );
}
