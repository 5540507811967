/**
 * Determines if information about logged-in user is present and
 * sufficient.
 *
 * @param userInfo object provided by AuthContext
 * @param location location object provided by useLocation hook from
 * React Router.
 *
 * @see AuthContext
 */
export default function isAuthorized(userInfo, location) {
  if (userInfo)
    return true;
  const fromRouterState = location?.state?.userInfo;
  return fromRouterState != null;
}