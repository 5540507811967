/**
 * Using scrolling wheel when a number field is focused and the
 * cursor is no that allows to change the value of that field. This
 * is a feature provided by browsers which can't be programmatically
 * disabled. This function is a workaround.
 * It may cause inelegant, stutter-like behaviour if the container in
 * which the field is placed can be scrolled. This probably is the
 * only drawback of this workaround and the only solution to that is
 * to put the fields in small containers which are unlikely to ever
 * be scrollable...
 *
 * @param evt event object
 */
export function preventNumberChange(evt) {
  if (evt.target !== document.activeElement)
    return; // Fix is needed only when the field is focused

  evt.target.blur(); // Prevent the input value change
  // Refocus immediately, on the next tick (after the current function is done)
  setTimeout(() => evt.target.focus(), 0);
}