import { makeStyles } from 'tss-react/mui';


const useStyles = makeStyles()((theme) => ({
  maxAmountField: {
    input:                          {
      '&:user-invalid': { //Special action when <form> returns invalid field
        color: theme.palette.error.main,
      },
    },
    '& [hidenumberadornment=true]': { //Custom attribute, hide adornment for <TextField type=number/>
      MozAppearance: 'textfield', //Firefox
      '& ::-webkit-outer-spin-button,& ::-webkit-inner-spin-button': {
        WebkitAppearance: 'none', //Chrome, Edge, Opera, Safari
      },
    },
  },
}));

export default useStyles;
