import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  field: {
    input: {
      '&:user-invalid': { //Special action when <form> returns invalid field
        color: theme.palette.error.main,
      },
    },
  },
}));

export default useStyles;
