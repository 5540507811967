import useREST                from 'rest/useREST';
import { BACKEND_PATHS }      from 'rest/const';
import internalPaths          from 'routing/internalPaths';
import React                  from 'react';
import { AuthContext }        from 'App';
import { useNavigate }        from 'react-router-dom';
import { useSnackbar }        from 'notistack';
import { useTranslation }     from 'react-i18next';
import { setCookie }          from 'utils/cookieUtils';
import { TAB_ID_COOKIE_NAME } from 'utils/browserTabsSessionUtils';
import useDELETE              from 'rest/useDELETE';


export default function useLogout() {

  const { t } = useTranslation('common');

  const [path, setPath] = React.useState(undefined);

  const { setUserInfo, refreshTimeoutId } = React.useContext(AuthContext);

  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const fetcher = useREST(false);

  const axiosCfg = {
    throwOnSessionError: false,
  };

  const swrOpts = {
    onSuccess: () => {
      setUserInfo(null);
      sessionStorage?.clear();
      setCookie(TAB_ID_COOKIE_NAME, '');
      navigate(internalPaths.logout);
      setPath(undefined);
    },
    onError:   () => {
      enqueueSnackbar(t('logoutFailed'), { variant: 'error' });
      setPath(undefined);
    },
    fetcher:   fetcher,
  };

  const { isValidating } = useDELETE(path, null, axiosCfg, swrOpts);

  function logout() {
    clearTimeout(refreshTimeoutId);
    setPath(BACKEND_PATHS.clearCookies);
  }

  return {
    logout:             logout,
    isLogoutInProgress: isValidating,
  };
}