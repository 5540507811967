import React from 'react';

import PropTypes                  from 'prop-types';
import Button                     from '@mui/material/Button';
import { useTranslation }         from 'react-i18next';
import { SUSPENDED_TOKEN_STATUS } from 'pages/CardDetailsPage/tabs/MobilePaymentsTab/mobilePaymetsTabConst';
import { ACTIVE_TOKEN_STATUS }    from 'pages/CardDetailsPage/tabs/MobilePaymentsTab/mobilePaymetsTabConst';
import { SUSPEND_TOKEN_STATUS }   from 'pages/CardDetailsPage/tabs/MobilePaymentsTab/mobilePaymetsTabConst';
import TokenActionsDialog         from 'pages/CardDetailsPage/tabs/MobilePaymentsTab/TokenActionsDialog';
import { UNSUSPEND_MODE }         from 'pages/CardDetailsPage/tabs/MobilePaymentsTab/TokenActionsDialog/TokenActionsDialog';
import { SUSPEND_MODE }           from 'pages/CardDetailsPage/tabs/MobilePaymentsTab/TokenActionsDialog/TokenActionsDialog';


function SuspendOrUnsuspendButton(props) {

  const { t } = useTranslation('cardDetails');

  const [mode, setMode] = React.useState(undefined);

  const tokenStatus = props.token.tokenStatus;
  let buttonText;
  let buttonColor;
  let modeOnClick;

  switch (tokenStatus) {
    case SUSPEND_TOKEN_STATUS:
    case SUSPENDED_TOKEN_STATUS:
      buttonText  = t('unsuspendTokenButton');
      buttonColor = 'primary';
      modeOnClick = UNSUSPEND_MODE;
      break;
    case ACTIVE_TOKEN_STATUS:
      buttonText  = t('suspendTokenButton');
      buttonColor = 'warning';
      modeOnClick = SUSPEND_MODE;
      break;
    default:
      break;
  }

  if (!buttonText) {
    return null;
  }


  return (
    <>
      <TokenActionsDialog
        mode={mode}
        token={props.token}
        closeDialog={() => setMode(undefined)}
      />
      <Button
        onClick={() => setMode(modeOnClick)}
        color={buttonColor}
      >
        {buttonText}
      </Button>
    </>
  );
}

SuspendOrUnsuspendButton.propTypes = {
  token: PropTypes.shape({
    tokenReferenceId: PropTypes.string.isRequired,
    tokenStatus:      PropTypes.string.isRequired,
  }).isRequired,
};

export default SuspendOrUnsuspendButton;