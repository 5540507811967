import React              from 'react';
import { useTranslation } from 'react-i18next';

import Grid      from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import PropTypes from 'prop-types';

const ADDRESS_FIELDS_LABEL_KEYS = {
  street:  'addressStreetLabel',
  zipCode: 'addressZipCodeLabel',
  city:    'addressCityLabel',
  country: 'addressCountryLabel',
};


function AddressField({ value, onChange }) {

  const { t } = useTranslation();

  function setter(fieldName, newValue) {
    onChange({ //Fake onChange event structure
      target: {
        value: {
          ...value,
          [fieldName]: newValue,
        },
      },
    });
  }

  return (
    <Grid container direction='column'>
      {Object.keys(ADDRESS_FIELDS_LABEL_KEYS).map((fieldName) => {
        const label = t(ADDRESS_FIELDS_LABEL_KEYS[fieldName]);
        return (
          <Grid item>
            <TextField
              required
              fullWidth
              value={value[fieldName] || ''}
              onChange={(evt) => setter(fieldName, evt.target.value)}
              key={label}
              label={label}
              helperText=' ' //A gap between fields
            />
          </Grid>
        );
      })}
    </Grid>
  );
}

AddressField.propTypes = {
  value: PropTypes.shape({
    street:  PropTypes.string,
    zipCode: PropTypes.string,
    city:    PropTypes.string,
    country: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default React.memo(AddressField);
