import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  accordionSummary: {
    flexFlow: 'row-reverse',
  },
  accordionSummaryTitle: {
    fontWeight: theme.typography.fontWeightSemibold,
  },
  tableToolbar: {
    backgroundColor: '#f6f6f6',
  },
}));

export default useStyles;
