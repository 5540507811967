import React              from 'react';
import Dialog             from '@mui/material/Dialog';
import DialogTitle        from '@mui/material/DialogTitle';
import DialogContent      from '@mui/material/DialogContent';
import DialogActions      from '@mui/material/DialogActions';
import Button             from '@mui/material/Button';
import * as PropTypes     from 'prop-types';
import { useTranslation } from 'react-i18next';
import FormWithOtpField   from 'pages/CardDetailsPage/tabs/DetailsAndOperationsTab/FormWithOtpField';
import Alert              from 'components/Alert';
import { OTP_FIELD_NAME } from 'pages/CardDetailsPage/tabs/DetailsAndOperationsTab/OTPField/OTPField';
import useStyles          from '../confirmationDialogsStyles';
import Typography         from '@mui/material/Typography';


export default function ConfirmationAndOtpDialog(props) {

  const { classes } = useStyles();
  const { t }       = useTranslation(['common', 'cardDetails']);

  const [otpRequested, setOtpRequested] = React.useState(false);

  function onSubmit(evt) {
    evt.preventDefault()
    const formData = new FormData(evt.target);
    const otp      = formData.get(OTP_FIELD_NAME);
    props.setOtpCode(otp);
  }

  //conversion to boolean
  const open                 = !!(props.otpType || props.alert);
  const showConfirmationText = !!(
    props.confirmationText
    && !props.skipUserOtpRequest
    && !otpRequested
    && !props.alert
  );

  /**
   * Resets 'otpRequested' flag when the dialog gets closed. Using
   * Dialog's onClose handler is insufficient since the dialog can be
   * closed by parent component.
   */
  React.useEffect(() => {
    if (!open)
      setOtpRequested(false);
  }, [open]);


  let actionButtonText = t('cardDetails:cancel');
  if (props.alert)
    actionButtonText = t('common:closeButton');

  return (
    <Dialog
      onClose={props.onClose}
      open={open}
    >
      <DialogTitle>
        {props.dialogTitle}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {showConfirmationText &&
          <Typography marginBottom={2}>
            {props.confirmationText}
          </Typography>
        }

        <span hidden={!props.otpType}>
          {/*hiding instead of dismounting to preserve state*/}
          <FormWithOtpField
            sendOtpButtonText={props.sendOtpButtonText}
            skipUserOtpRequest={props.skipUserOtpRequest}
            otpType={props.otpType}
            onSubmit={onSubmit}
            disableSubmit={props.disableSubmit}
            formRef={props.formRef}
            showValidityMessages={props.showValidityMessages}
            onOtpRequested={() => setOtpRequested(true)}
          />
        </span>
        {props.alert &&
          <Alert
            alert={props.alert}
            setAlert={props.closeAlert}
          />
        }
      </DialogContent>
      <DialogActions>
        <Button
          id="cancelButton"
          onClick={props.onClose}
          color="primary"
          variant="outlined"
        >
          {actionButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationAndOtpDialog.propTypes = {
  confirmationText:  PropTypes.string,
  sendOtpButtonText: PropTypes.string,
  /**
   * The form displays a button which sends OTP when clicked. This
   * property allows to skip it and send OTP right away. Note that
   * this property is saved in state and can't change while the form
   * is mounted.
   */
  skipUserOtpRequest: PropTypes.bool,
  dialogTitle:        PropTypes.node,
  otpType:            PropTypes.string,
  disableSubmit:      PropTypes.bool,

  setOtpCode: PropTypes.func.isRequired,
  onClose:    PropTypes.func,

  alert:      PropTypes.shape({
    msgTranslKey:          PropTypes.string.isRequired,
    msgTranslSecondaryKey: PropTypes.string,
    type:                  PropTypes.string,
  }),
  closeAlert: PropTypes.func,

  formRef:              PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]),
  showValidityMessages: PropTypes.bool,
};