import React from 'react';

import PropTypes             from 'prop-types';
import { useTranslation }    from 'react-i18next';
import Grid                  from '@mui/material/Grid';
import Typography            from '@mui/material/Typography';
import { BEGIN_PLACEHOLDER } from 'pages/CardDetailsPage/tabs/AccountInfoTab/accountInfoTabConst';
import { END_PLACEHOLDER }   from 'pages/CardDetailsPage/tabs/AccountInfoTab/accountInfoTabConst';
import LabelledPieceOfInfo   from 'pages/CardDetailsPage/tabs/AccountInfoTab/LabelledPieceOfInfo';


function PreviousPeriod(props) {

  const { t } = useTranslation('cardDetails');

  if (!props.period)
    return null;

  const paymentMinAmount    = props.period.paymentMinAmount + props.currencyName;
  const closingBalanceValue = props.period.closingBalance;
  let closingBalance        = closingBalanceValue + props.currencyName;

  if (closingBalanceValue < 0)
    closingBalance = 0;

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <Typography fontWeight='semibold'>
          {t('previousBillingPeriod')
            .replace(BEGIN_PLACEHOLDER, props.period.periodBegin)
            .replace(END_PLACEHOLDER, props.period.periodEnd)
          }
        </Typography>
      </Grid>
      <Grid item>
        <Grid container spacing={5}>
          <Grid item>
            <LabelledPieceOfInfo label={t('paymentMinAmount')} info={paymentMinAmount}/>
          </Grid>
          <Grid item>
            <LabelledPieceOfInfo label={t('closingBalance')} info={closingBalance}/>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <LabelledPieceOfInfo label={t('paymentDate')} info={props.period.paymentDate}/>
      </Grid>
    </Grid>
  );
}

PreviousPeriod.propTypes = {
  period:       PropTypes.shape({
    periodBegin:      PropTypes.string.isRequired,
    periodEnd:        PropTypes.string.isRequired,
    paymentMinAmount: PropTypes.number.isRequired,
    closingBalance:   PropTypes.number.isRequired,
    paymentDate:      PropTypes.string.isRequired,
  }),
  currencyName: PropTypes.string,
};

export default PreviousPeriod;