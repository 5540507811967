import React                from 'react';
import PropTypes            from 'prop-types';
import { useTranslation }   from 'react-i18next';
import Grid                 from '@mui/material/Grid';
import TextField            from '@mui/material/TextField';
import validateAnswerFields from './validateAnswerFields';
import isBlank              from 'utils/isBlank';
import useStyles            from './threeDSAnswerFormStyles';
import Typography           from '@mui/material/Typography';


export const ANSWER_FIELD_NAME        = 'answer';
export const REPEAT_ANSWER_FIELD_NAME = 'answer-repeat';

function ThreeDSAnswerForm(props) {

  const { classes } = useStyles();
  const { t }       = useTranslation('cardDetails');

  const inputProps = {
    autoComplete: 'off',
    type:         'password',
    minLength:    6,
  };

  //spaces allow to preserve field height
  let answerHelperText       = props.answerInputValidationMessage || ' ';
  let answerRepeatHelperText = props.answerRepeatInputValidationMessage || ' ';
  if (!props.showValidityMessages) {
    answerHelperText       = ' ';
    answerRepeatHelperText = ' ';
  }


  return (
    <>
      <Grid item>
        <Typography>
          {t('threeDSAnswerInstruction')}
        </Typography>
      </Grid>
      <Grid item>
        <TextField
          required
          label={t('answerFieldLabel')}
          name={ANSWER_FIELD_NAME}
          onBlur={() => validateAnswerFields(props.refs, props.setters, t)}
          error={props.showValidityMessages && !isBlank(props.answerRepeatInputValidationMessage)}
          helperText={answerHelperText}
          className={classes.field}
          inputRef={props.refs?.answerInputRef}
          inputProps={inputProps}
        />
      </Grid>
      <Grid item>
        <TextField
          required
          label={t('repeatAnswerFieldLabel')}
          name={REPEAT_ANSWER_FIELD_NAME}
          onChange={() => validateAnswerFields(props.refs, props.setters, t)}
          error={props.showValidityMessages && !isBlank(props.answerRepeatInputValidationMessage)}
          helperText={answerRepeatHelperText}
          className={classes.field}
          inputRef={props.refs?.answerRepeatInputRef}
          inputProps={inputProps}
        />
      </Grid>
    </>
  );
}

ThreeDSAnswerForm.propTypes = {
  showValidityMessages: PropTypes.bool,

  refs: PropTypes.shape({
    answerInputRef:       PropTypes.object,
    answerRepeatInputRef: PropTypes.object,
  }),

  setters: PropTypes.shape({
    setAnswerInputValidationMessage:       PropTypes.func.isRequired,
    setAnswerRepeatInputValidationMessage: PropTypes.func.isRequired,
  }).isRequired,

  answerInputValidationMessage:       PropTypes.string,
  answerRepeatInputValidationMessage: PropTypes.string,
};

export default ThreeDSAnswerForm;