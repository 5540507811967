const PATTERN     = /\./g;
const NUM_OPTIONS = { minimumIntegerDigits: 2 }; //To make sure it's '02' itd instead of '2'

/**
 * <pre>
 * Function that reformats date to string format "YYYY-MM-DD"
 * If string is given then converts it to Date and then reformats
 * </pre>
 *
 * @param date {Object | String} - value to reformat
 * @param isUTC {Boolean =} - answer question: "Do you want to display this date in timezone +0?"
 * @param defaultValue {String | JSX.Element =} - value to return instead of null
 * @returns {String | JSX.Element | null}
 */
export default function reformatDate(date, isUTC, defaultValue) {
  defaultValue = defaultValue != null ? defaultValue : null;
  if (!date) {
    return defaultValue;
  }

  if (!(date instanceof Date)) {
    date = new Date(date);
  }
  if (isNaN(date)) {
    return defaultValue;
  }

  let dateLocale;
  if (isUTC) {
    dateLocale = date.toISOString();
    dateLocale = dateLocale.substring(0, 10);
    return dateLocale;
  } else {
    dateLocale = date.toLocaleDateString("pl-PL");
  }

  const splitted = dateLocale.split(PATTERN);
  if (splitted.length < 3) {
    return dateLocale;
  }
  const year  = splitted[2];
  const month = parseInt(splitted[1]).toLocaleString(undefined, NUM_OPTIONS);
  const day   = parseInt(splitted[0]).toLocaleString(undefined, NUM_OPTIONS);

  return `${year}-${month}-${day}`;
}