import jwt_decode from 'jwt-decode';
import isBlank    from 'utils/isBlank';


export function getUsernameFromToken(token) {
  if (!token) {
    return undefined;
  }
  if (typeof token === 'string') { //Token is not decoded yet
    token = jwt_decode(token);
  }

  const username = token.admin || token.user; //Currently, it's 1 of those
  if (!isBlank(username)) {
    return username;
  }

  //Just to be safe
  for (const field in token) {
    const val = token[field];
    if (val && val.includes('@') && val.includes('.')) {
      return val;
    }
  }
  return null;
}