import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  selectedButton: {
    color: theme.palette.primary.main + '!important',
    backgroundColor: '#FFF !important',
    boxShadow: 'inset 0 0.05em 0.1em #000 !important',
    borderStyle: 'inset',
    borderColor: '#000',
    borderWidth: 1,
  },
  defaultButton: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
}));

export default useStyles;