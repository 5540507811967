import jwt_decode from 'jwt-decode';


export function getSecondsUntilExpiration(token) {
  if (!token) {
    return undefined; //Will return false for operations '>', '<', etc.
  }
  if (typeof token === 'string') { //Token is not decoded yet
    token = jwt_decode(token);
  }
  const currentTimeInMilliseconds = new Date().getTime();
  const currentTimeInSeconds      = currentTimeInMilliseconds / 1000;
  //Token's expire time is in seconds
  return Math.floor(token.exp - currentTimeInSeconds);
}