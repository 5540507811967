import React from 'react';

import PropTypes               from 'prop-types';
import MaterialReactTable      from 'material-react-table';
import Alert                   from 'components/Alert';
import Typography              from '@mui/material/Typography';
import { MRT_Localization_PL } from 'material-react-table/locales/pl';
import { useTranslation }      from 'react-i18next';
import useSWRInfinite          from 'swr/infinite';
import createRestRequestConfig from 'rest/createRestRequestConfig';
import { AuthContext }         from 'App';
import useStyles               from './infiniteScrollTableStyles';
import classnames              from 'classnames';


const PAGE_SIZE = 50;

function InfiniteScrollTable({ getDataFromResponse, pathFormat, queryParams, ...otherProps }) {

  const { classes } = useStyles();
  const { t }       = useTranslation();
  const { userInfo } = React.useContext(AuthContext);

  const [alert, setAlert]           = React.useState(undefined);
  const [fetchError, setFetchError] = React.useState(null);


  function getLengthFromResponse(response) {
    return getDataFromResponse(response)?.length;
  }

  function getKey(pageIndex, previousResponse) {
    if (previousResponse && getLengthFromResponse(previousResponse) < PAGE_SIZE)
      return null; // reached the end

    const axiosCfg = createRestRequestConfig(userInfo.accessToken, true);

    axiosCfg.params = queryParams;

    const pageNumber = pageIndex + 1; // Indexes start from 0 while gateway API starts from 1
    const path = pathFormat
      .replace('{page}', pageNumber)
      .replace('{per_page}', '' + PAGE_SIZE);

    return [path, 'GET', null, axiosCfg];
  }

  const swrOptions = {
    revalidateFirstPage: false,
    ...otherProps.swrOptions,
    onError: (error) => {
      otherProps.swrOptions?.onError(error);
      setFetchError(error);
    },
    onSuccess: () => {
      setFetchError(null);
    },
  };

  const { data: responseArray, isLoading, isValidating, setSize, mutate } = useSWRInfinite(getKey, swrOptions);

  if (otherProps.mutateRef)
    otherProps.mutateRef.current = mutate;

  //useSWRInfinite wraps whatever fetcher returns in an array, therefore it's an array of responses
  const data    = responseArray ? responseArray.flatMap(getDataFromResponse) : [];
  const isEmpty = getLengthFromResponse(responseArray?.[0]) === 0;

  function isEndReached() {
    if (isEmpty)
      return true;
    if (!responseArray)
      return false;

    const lastResponse   = responseArray[responseArray.length - 1];
    const lastDataLength = getLengthFromResponse(lastResponse);
    return lastDataLength < PAGE_SIZE;
  }

  const endReached = isEndReached();

  //Called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
  const fetchMoreOnBottomReached = React.useCallback(
    (containerRefElement) => {
      if (isLoading || isValidating || endReached) {
        return;
      }
      const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
      //Once the user has scrolled within 100px of the bottom of the table, fetch more data if we can
      const fetchAtDistanceFromBottom = 100;
      if (scrollHeight - scrollTop - clientHeight < fetchAtDistanceFromBottom) {
        setAlert(null);
        setFetchError(null);
        //Scroll up, this way it won't do multiple fetches at once
        containerRefElement.scrollTop = scrollTop - fetchAtDistanceFromBottom + 5;
        setSize(prevSize => prevSize + 1);
      }
    }, [isLoading, isValidating, endReached, setSize]
  );

  /**
   * General props
   * translation, columns and data
   */

  function fetchStatusText() {
    if (fetchError) {
      return t('fetchError');
    }
    if (isLoading || isValidating) {
      return t('isFetching');
    }
    if (endReached) {
      return t('finishedFetching').replace('{totalFetched}', '' + data.length);
    }
    return t('currentlyFetched').replace('{totalFetched}', '' + data.length);
  }

  const localization = React.useMemo(
    () => {
      const localization   = t('localization', { returnObjects: true });
      localization.actions = '';  //no header for actions column
      return { ...MRT_Localization_PL, ...localization };
    }, [t]
  );

  const justMountedRef = React.useRef(true);

  /**
   * This ref is necessary because setSize function is not a React
   * state setter and is not stable (is newly created in each render).
   */
  const setSizeRef   = React.useRef(undefined);
  setSizeRef.current = setSize;

  /**
   * Schedules resetting table data when query params change.
   */
  React.useEffect(() => {
    if (justMountedRef.current) {
      justMountedRef.current = false;
      //skip running onMount; this is an optimization attempt
      //setSize is not a React setter, setting size to 1 while it's
      //already 1 WILL cause rerender (but will not fetch the data
      //again due to SWR cache). This is a good thing since otherwise
      //it wouldn't refresh the table if queryParams change but there
      //was just one page fetched.
      return;
    }

    setSizeRef.current(1);
  }, [queryParams]);

  //false positive warning
  // noinspection RequiredAttributes
  return (
    <MaterialReactTable
      localization={localization}
      enableStickyHeader={true}
      enableStickyFooter={true}
      enableTopToolbar={typeof otherProps.renderTopToolbar === 'function'
        || React.isValidElement(otherProps.renderTopToolbar)
      }
      enablePagination={false}
      enableSorting={false}
      enableColumnActions={false}
      renderBottomToolbarCustomActions={() => alert
        ? <Alert
            alert={alert}
            setAlert={setAlert}
            classes={{
              alertContainer: classes.alertContainer,
              paper:          classes.alertPaper,
            }}
          />
        : <Typography className={classes.fetchingToolbar}>
            {fetchStatusText()}
          </Typography>
      }

      {...otherProps}

      data={data}
      muiBottomToolbarProps={{
        ...otherProps.muiBottomToolbarProps,
        className: classnames(classes.paginationStyles, otherProps.muiBottomToolbarProps?.className),
      }}
      muiTableContainerProps={{
        ...otherProps.muiTableContainerProps,
        //Half of the screen, so it doesn't generate scroll
        sx: {
          maxHeight: '50vh',
          ...otherProps.muiTableContainerProps?.sx,
        },
        //Add an event listener to the table container element
        onScroll: (event) => {
          if (otherProps.muiTableContainerProps?.onScroll)
            otherProps.muiTableContainerProps.onScroll(event);
          fetchMoreOnBottomReached(event.target);
        },
      }}
      state={{
        ...otherProps.state,
        isLoading:        isLoading,
        showProgressBars: isLoading || isValidating,
      }}
    />
  );
}

InfiniteScrollTable.propTypes = {
  /**
   * A function which is provided with axios response object and is
   * supposed to extract an array with data from it. Useful when the
   * data to be displayed is just a part of the response.
   */
  getDataFromResponse: PropTypes.func.isRequired,
  /**
   * An address to fetch data from with placeholders for page index
   * and page size. The placeholders are following: '{page}',
   * '{per_page}'.
   */
  pathFormat: PropTypes.string.isRequired,
  /**
   * Query params of each request. The table will clear each time
   * they change. Provide filters here instead putting them in
   * pathFormat.
   */
  queryParams: PropTypes.object,
  swrOptions:  PropTypes.object,
  /**
   * The ref will be supplied with mutate function, allowing parent
   * component to trigger refetching of table's data.
   */
  mutateRef: PropTypes.shape({
    current: PropTypes.any
  }),
};

export default InfiniteScrollTable;