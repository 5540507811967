import isBlank from 'utils/isBlank';


const MAX_SEQUENCE_LEN = 2;

/**
 * Disallowed:
 * * sequences of three or more consecutive digits,
 * * using less than three different digits
 *
 * @param {string} pin four digits as string, shorter input is ignored
 * @param {function} t i18n translate function with cardDetails namespace loaded
 */
export default function checkPinComplexity(pin, t) {
  if (isBlank(pin) || pin.length < 4)
    return t('cardDetails:pinTooShort')
  const set = new Set(pin);
  if (set.size < 3)
    return t('cardDetails:tooManyRepetitions')
  const digitsArray         = [...pin].map(d => parseInt(d));
  let consecutiveValidation = checkForConsecutive(digitsArray, MAX_SEQUENCE_LEN, t);
  if (consecutiveValidation != null)
    return consecutiveValidation;
  return checkForConsecutive(digitsArray.reverse(), MAX_SEQUENCE_LEN, t);
}

function checkForConsecutive(pinAsIntArray, maxSequenceLen, t) {
  let increasingSequenceLength = 1;
  let decreasingSequenceLength = 1;
  let previousDigit            = pinAsIntArray[0];
  for (let i = 1; i < pinAsIntArray.length; i++) {
    const currentDigit = pinAsIntArray[i];
    if (currentDigit === previousDigit + 1)
      increasingSequenceLength++;
    else if (currentDigit === previousDigit - 1)
      decreasingSequenceLength++;
    else {
      decreasingSequenceLength = 1;
      increasingSequenceLength = 1;
    }

    if (decreasingSequenceLength > maxSequenceLen || increasingSequenceLength > maxSequenceLen)
      return t('cardDetails:sequenceDetected');
    previousDigit = currentDigit;
  }
}
