import React              from 'react';
import useAutologin       from 'pages/LoginPage/useAutologin';
import { useContext }     from 'react';
import { useLocation }    from 'react-router-dom';
import usePOST            from 'rest/usePOST';
import useStyles          from 'components/CommonForm/commonFormStyles';
import { useTranslation } from 'react-i18next';

import Alert                 from 'components/Alert';
import Button                from '@mui/material/Button';
import CommonForm            from 'components/CommonForm';
import COMMON_FIELDS         from 'components/CommonForm/fields';
import Container             from '@mui/material/Container';
import Grid                  from '@mui/material/Grid';
import OTPAdornment          from 'components/OTPAdornment';
import Paper                 from '@mui/material/Paper';
import TooManyTrustedDevices from 'pages/LoginPage/TooManyTrustedDevices';
import Typography            from '@mui/material/Typography';
import UntrustedDevice       from 'pages/LoginPage/UntrustedDevice';

import { AuthContext }         from 'App';
import { BACKEND_PATHS }       from 'rest/const';
import { getErrorMessage }     from 'rest/sendRequest';
import INTERNAL_PATHS          from 'routing/internalPaths';
import { Navigate }            from 'react-router-dom';
import { NavLink }             from 'react-router-dom';
import { replaceTabSessionId } from 'utils/browserTabsSessionUtils';

const IS_NOT_CREDENTIALS = ['otp'];

function createFields(includeOTP, otpAdornment, credentials) {
  const result = {
    username: {
      ...COMMON_FIELDS.username,
      defaultValue: credentials?.username,
    },
    password: {
      ...COMMON_FIELDS.password,
      defaultValue: credentials?.password,
    },
  };
  if (includeOTP) {
    result.otp = {
      ...COMMON_FIELDS.otp,
    };
    result.otp.InputProps.endAdornment = otpAdornment;
  }

  return result;
}

const DEVICE_NOT_TRUSTED_MESSAGE       = "device is not trusted";
const TOO_MANY_TRUSTED_DEVICES_MESSAGE = "too many trusted devices";

const UNTRUSTED_DEVICE = {
  choice: "choice",
  addToTrusted: "addToTrusted",
  oneTime: "oneTime",
  tooManyTrustedDevices: "tooMany",
};

/**
 * Flow is as follows: during the first request, the device is
 * assumed to be trusted. If the response states it's not trusted,
 * then the user is given a choice: either the device will be added
 * to trusted list or it will be a one time login. Either way, for
 * untrusted device OTP will be required.
 */
function LoginPage() {

  const { t }                     = useTranslation();
  const { classes }               = useStyles();
  const { userInfo, setUserInfo } = useContext(AuthContext);

  const location = useLocation();

  const [alert, setAlert]                     = React.useState(undefined);
  const [FIELDS, setFIELDS]                   = React.useState(() => createFields(false));
  const [submitData, setSubmitData]           = React.useState(undefined);
  const [untrustedDevice, setUntrustedDevice] = React.useState(undefined);

  const [otpPath, setOtpPath] = React.useState(null);

  useAutologin();

  const otpAdornment = React.useMemo(() => {
    function resend() {
      setAlert(null);
      if (untrustedDevice === UNTRUSTED_DEVICE.addToTrusted)
        setOtpPath(BACKEND_PATHS.loginAndTrustDevice);
      else
        setOtpPath(BACKEND_PATHS.loginOneTime);
    }

    return (
      <OTPAdornment resend={resend}/>
    );
  }, [untrustedDevice]);

  const { isValidating: isValidatingOtp } = usePOST(otpPath, submitData, undefined, {
    onError: (err) => {
      setOtpPath(null);
      if (err.response && err.response.data.isAlert) {
        setAlert(err.response.data);
      } else if (err.response?.data === TOO_MANY_TRUSTED_DEVICES_MESSAGE) {
        setUntrustedDevice(UNTRUSTED_DEVICE.tooManyTrustedDevices);
        return; // Don't log it as onRestError
      } else {
        setAlert({
          msgTranslKey: 'serverError',
          type:         'warning',
        });
      }

      if (process.env.NODE_ENV === 'development') {
        console.log('LoginPage, OTP, onRestError: ', getErrorMessage(err), err.response, err);
        console.error('LoginPage, OTP, onRestError: ', err);
      }
    },
    onSuccess: (res) => {
      setOtpPath(null);
      setFIELDS(createFields(true, otpAdornment, submitData?.credentials));
      setAlert({
        msgTranslKey: t('successCallOTP').replace('&{phoneNo}', res.data.phoneNo),
        type:         'success',
      });
      if (otpPath === BACKEND_PATHS.loginAndTrustDevice) {
        setUntrustedDevice(UNTRUSTED_DEVICE.addToTrusted); // Switch screen after success
      }

      if (process.env.NODE_ENV === 'development') {
        console.log('LoginPage, OTP, onRestSuccess: ', res.data);
      }
    },
  });


  function handleLogin(res) {
    setLoginPath(null);
    const data = res.data;
    const sendOTP = !data.accessToken;

    if (sendOTP) {
      setFIELDS(createFields(true, otpAdornment, submitData?.credentials));
      setAlert({
        msgTranslKey: t('successCallOTP').replace('&{phoneNo}', data.phoneNo),
        type:         'success',
      });
    } else {
      replaceTabSessionId();  //new session replaces the old one on login
      setUserInfo({
        username: submitData?.credentials?.username,
        ...data,
      });
    }

    if (process.env.NODE_ENV === 'development') {
      console.log('LoginPage, onRestSuccess: ', res.data);
    }
  }

  function onRestError(err) {
    setLoginPath(null);
    if (err.response && err.response.data.isAlert) {
      setAlert(err.response.data);
    } else if (err.response?.data === DEVICE_NOT_TRUSTED_MESSAGE) {
      setUntrustedDevice(UNTRUSTED_DEVICE.choice);
      return; // Don't log it as onRestError
    } else {
      setAlert({
        msgTranslKey: 'serverError',
        type:         'warning',
      });
    }

    if (process.env.NODE_ENV === 'development') {
      console.log('LoginPage, onRestError: ', getErrorMessage(err), err.response, err);
      console.error('LoginPage, onRestError: ', err);
    }
  }

  const swrOptions = {
    shouldRetryOnError: false,
    revalidateOnFocus:  false,
    onSuccess:          handleLogin,
    onError:            onRestError,
  };

  const [loginPath, setLoginPath] = React.useState(BACKEND_PATHS.login);
  const path = submitData && loginPath ? loginPath : null;
  const { isValidating: isValidatingLogin, mutate } = usePOST(path, submitData, undefined, swrOptions);

  function handleSubmit(evt) {
    evt.preventDefault();
    const formData      = new FormData(evt.target);
    const toBeSubmitted = {
      credentials: {
        type: 'EMAIL',
      },
    };

    for (const [key, value] of formData.entries()) {
      if (IS_NOT_CREDENTIALS.includes(key)) {
        toBeSubmitted[key] = value;
      } else {
        toBeSubmitted.credentials[key] = value;
      }
    }
    //TODO add encryption
    setSubmitData(prevSubmitData => ({
      ...prevSubmitData,  //to preserve device object, if present
      ...toBeSubmitted,
    }));

    if (untrustedDevice === UNTRUSTED_DEVICE.addToTrusted)
      setLoginPath(BACKEND_PATHS.loginAndTrustDevice)
    else if (untrustedDevice === UNTRUSTED_DEVICE.oneTime)
      setLoginPath(BACKEND_PATHS.loginOneTime)
    else
      setLoginPath(BACKEND_PATHS.loginWithTrustedDevice);
    mutate();
  }

  function handleOnAddToTrusted(deviceDescription) {
    setSubmitData(prevSubmitData => ({
      ...prevSubmitData,
      device: {
        deviceDescription: deviceDescription,
        //fingerprint is added by backend
      },
    }));
    setFIELDS(createFields(true, otpAdornment, submitData?.credentials));
    setOtpPath(BACKEND_PATHS.loginAndTrustDevice);
  }

  function handleUseOneTimeLogin() {
    setUntrustedDevice(UNTRUSTED_DEVICE.oneTime);
    setFIELDS(createFields(true, otpAdornment, submitData?.credentials));
    setOtpPath(BACKEND_PATHS.loginOneTime);
  }

  if (userInfo) {
    const navigateTo = location?.state?.goBackTo || INTERNAL_PATHS.myCards;
    return <Navigate to={navigateTo}/>
  }

  function submitBtnTooltipFn() {
    if (isValidatingLogin || isValidatingOtp) {
      return t('inProgress');
    }
    return '';
  }

  const everythingDisabled = isValidatingLogin || isValidatingOtp;

  const registerButton = (
    <>
      <Grid item xs={6}>
        <NavLink to={INTERNAL_PATHS.register}>
          <Button
            fullWidth
            disabled={everythingDisabled}
            className={classes.submitButton}
            color='secondary'
          >
            {t('registerButton')}
          </Button>
        </NavLink>
      </Grid>
      <Grid item xs={12} align='center'>
        <NavLink to={INTERNAL_PATHS.forgotPassword} className={classes.submitButton}>
          <div>
            {t('forgotPasswordButton')}
          </div>
        </NavLink>
      </Grid>
    </>
  );

  const showForm = untrustedDevice == null
    || untrustedDevice === UNTRUSTED_DEVICE.oneTime
    || untrustedDevice === UNTRUSTED_DEVICE.addToTrusted;

  return (
    <Container maxWidth='xs'>
      <Alert alert={alert} setAlert={setAlert}/>
      <Paper elevation={6} className={classes.mainPaper}>
        <Paper elevation={0} className={classes.titlePaper}>
          <Typography
            align='center'
            variant='subtitle1'
            className={classes.title}
          >
            {t('loginTitle')}
          </Typography>
        </Paper>
        {showForm &&
          <CommonForm
            t={t}
            fields={FIELDS}
            onSubmit={handleSubmit}
            submitBtnText='loginButton'
            submitBtnTooltipFn={submitBtnTooltipFn}
            extraButtons={registerButton}
            everythingDisabled={everythingDisabled}
            gridSizes={{
              submitButton: 6, //This way register button fits next to login button
            }}
          />
        }
        {untrustedDevice === UNTRUSTED_DEVICE.choice &&
          <UntrustedDevice
            onAddToTrusted={handleOnAddToTrusted}
            onUseOneTimeLogin={handleUseOneTimeLogin}
          />
        }
        {untrustedDevice === UNTRUSTED_DEVICE.tooManyTrustedDevices &&
          <TooManyTrustedDevices onUseOneTimeLogin={handleUseOneTimeLogin}/>
        }
      </Paper>
    </Container>
  );
}

export default LoginPage;