import React   from 'react';
import Tooltip from '@mui/material/Tooltip';
import Button  from '@mui/material/Button';


export default function OperationButton({ title, ...buttonProps }) {
  return (
    <Tooltip title={title == null ? '' : title} arrow placement='top'>
      <span>{/* Disabled elements can't hold a ref required by Tooltip */}
        <Button
          {...buttonProps}
        />
      </span>
    </Tooltip>
  );
}