import React from 'react';

import PropTypes          from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid               from '@mui/material/Grid';
import Typography         from '@mui/material/Typography';
import Button             from '@mui/material/Button';
import useStyles          from 'components/CommonForm/commonFormStyles';


function TooManyTrustedDevices(props) {

  const { t }       = useTranslation('common');
  const { classes } = useStyles();

  return (
    <Grid
      container
      spacing={2}
      paddingTop={2}
      paddingBottom={3}
      paddingLeft={4}
      paddingRight={4}
      justifyContent='center'
      className={classes.centerText}
    >
      <Grid item xs={12}>
        <Typography variant='h5' fontWeight='bold'>
          {t('tooManyTrustedDevicesTitle')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          {t('tooManyTrustedDevicesInfo')}
        </Typography>
      </Grid>
      <Grid item>
        <Button
          onClick={props.onUseOneTimeLogin}
        >
          {t('oneTimeLoginButton')}
        </Button>
      </Grid>
    </Grid>
  );
}

TooManyTrustedDevices.propTypes = {
  onUseOneTimeLogin: PropTypes.func.isRequired,
};

export default TooManyTrustedDevices;