

export default function prepareColumns(columnNames, columnOptions) {
  columnOptions = columnOptions || {};

  const columns = [];
  for (const columnName in columnNames) {
    columns.push({
      id: columnName,
      accessorKey: columnName,
      header: columnNames[columnName],
      ...columnOptions[columnName],

      //Flat copy would override whole object if done after this
      muiTableHeadCellProps: {
        align: 'center',
        ...columnOptions[columnName]?.muiTableHeadCellProps,
        style: {
          minWidth: 0,
          justifyContent: 'center',
          ...columnOptions[columnName]?.muiTableHeadCellProps?.style,
        },
      },
      muiTableBodyCellProps: {
        align: 'center',
        ...columnOptions[columnName]?.muiTableBodyCellProps,
        style: {
          minWidth: 0,
          justifyContent: 'center',
          ...columnOptions[columnName]?.muiTableBodyCellProps?.style,
        },
      },
    });
  }

  return columns;
}
