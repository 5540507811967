import React from 'react';

import PropTypes                    from 'prop-types';
import useStyles                    from './historyTableStyles';
import { useTranslation }           from 'react-i18next';
import prepareColumns               from 'pages/MyCardsPage/CardsTable/prepareColumns';
import { columnOptions }            from './historyTableTools';
import { generateFilterData }       from './historyTableTools';
import HistoryTableToolbar          from './HistoryTableToolbar';
import { FINTECH_PATHS }            from 'rest/const';
import InfiniteScrollTable          from 'components/InfiniteScrollTable';
import { getDefaultDateFilterData } from './historyTableTools';


export default function HistoryTab(props) {

  const { classes } = useStyles();
  const { t }       = useTranslation('cardDetails');

  const [filtersExpanded, setFiltersExpanded] = React.useState(true);

  const [dateFilterData, setDateFilterData]               = React.useState(getDefaultDateFilterData);
  const [descriptionFilterData, setDescriptionFilterData] = React.useState(null);
  const [amountFilterData, setAmountFilterData]           = React.useState({});

  const [filterData, setFilterData] = React.useState(() => generateFilterData(dateFilterData, descriptionFilterData, amountFilterData));

  React.useEffect(() => {
    if (dateFilterData.errorKey || amountFilterData.errorKey) {
      return;
    }
    const newFilterData = generateFilterData(dateFilterData, descriptionFilterData, amountFilterData);
    const debounce = setTimeout(() => setFilterData(newFilterData), 1000);
    return () => clearTimeout(debounce);
  }, [dateFilterData, descriptionFilterData, amountFilterData]);

  function getTransactionsFromResponse(response) {
    return response?.data?.transactions;
  }

  const columns = React.useMemo(
    () => prepareColumns(
      t('transactionsTableColumnNames', { returnObjects: true }),
      columnOptions(classes, t)
    ), [t, classes]
  );

  const pathFormat = FINTECH_PATHS.transactions.replace('{cardId}', props.cardId);

  return (
    <div className={classes.negativeMargin}>
      <InfiniteScrollTable
        getDataFromResponse={getTransactionsFromResponse}
        queryParams={filterData}
        columns={columns}
        pathFormat={pathFormat}
        layoutMode="grid"
        state={{
          density: 'compact',
        }}
        renderTopToolbar={() =>
          <HistoryTableToolbar
            filtersExpanded={filtersExpanded}
            setFiltersExpanded={setFiltersExpanded}
            dateFilterData={dateFilterData}
            descriptionFilterData={descriptionFilterData}
            amountFilterData={amountFilterData}
            setDateFilterData={setDateFilterData}
            setDescriptionFilterData={setDescriptionFilterData}
            setAmountFilterData={setAmountFilterData}
          />
        }
      />
    </div>
  );
}

HistoryTab.propTypes = {
  cardId: PropTypes.string.isRequired,
};
