import { makeStyles } from 'tss-react/mui';

const height = '10rem';
const widthValue = 16.5;
const width = widthValue + 'rem';
const gapBetweenCardWidthValue = 5; // Too small gap causes cards to scramble
const rootContainerWidth = 2 * widthValue + gapBetweenCardWidthValue + 'rem';

const useStyles = makeStyles()((theme) => ({
  rootContainer: {
    width: rootContainerWidth,
  },
  appSelectionCard: {
    backgroundColor: theme.palette.primary.main,
    marginTop: '10rem',
    marginBottom: '5rem',
    borderRadius: 30,
    textAlign: 'center',
    color:  '#FFF',
    width:  width,
    height: height,
    textTransform: 'uppercase',
    '&:hover': {
      opacity: 0.75,
      cursor: 'pointer',
    },
  },
  left: {
    float: 'left',
  },
  right: {
    float: 'right',
  },
  titlesContainer: {
    display: 'table-cell', //Required by vertical-align
    verticalAlign: 'middle',
    width:  width,  // 100% doesn't work on table-cell
    height: height, // 100% doesn't work on table-cell
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
  subTitle: {
    //Currently nothing
  },
}));

export default useStyles;
