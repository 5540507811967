import isBlank               from 'utils/isBlank';
import checkAnswerComplexity from 'pages/CardDetailsPage/tabs/ThreeDSTab/ThreeDSActions/Change3DSAnswer/Change3DSAnswerDialog/ThreeDSAnswerForm/checkAnswerComplexity';


/**
 * If both values, answer and repeated answer, are present checks
 * if they are the same. Uses browsers' built-in mechanism to report
 * error.
 * @param {{answerInputRef, answerRepeatInputRef}} refs references to input fields
 * @param {{setAnswerInputValidationMessage, setAnswerRepeatInputValidationMessage}} setters validation messages' setters
 * @param {function} t translation function with `cardDetails` namespace loaded
 */
export default function validateAnswerFields(refs, setters, t) {
  const { answerInputRef, answerRepeatInputRef }                                   = refs;
  const { setAnswerInputValidationMessage, setAnswerRepeatInputValidationMessage } = setters;

  const answerField          = answerInputRef.current;
  const answerValue          = answerField.value;
  const answerRepeatField    = answerRepeatInputRef.current;
  const answerRepeatValue    = answerRepeatField.value;
  const complexityValidation = checkAnswerComplexity(answerValue, t);
  const skipFieldsEquality   = isBlank(answerRepeatValue);

  function setValidityMessage(message) {
    //these are for browser
    answerField.setCustomValidity(message);
    answerRepeatField.setCustomValidity(message);
    //these change helper text under the fields
    setAnswerInputValidationMessage(message);
    setAnswerRepeatInputValidationMessage(message);
  }

  function resetControlBackToTheBrowser() {
    setValidityMessage('');
  }

  if (complexityValidation)
    setValidityMessage(complexityValidation);
  else if (skipFieldsEquality)
    resetControlBackToTheBrowser();
  else if (answerValue !== answerRepeatValue)
    setValidityMessage(t('cardDetails:answersDontMatch'));
  else
    resetControlBackToTheBrowser();
}