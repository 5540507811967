import React              from 'react';
import useGET             from 'rest/useGET';
import useStyles          from './userDataPageStyles'
import { useTranslation } from 'react-i18next';

import createRestRequestConfig from 'rest/createRestRequestConfig';
import { getErrorMessage }     from 'rest/sendRequest';

import AddressField         from './AddressField';
import Button               from '@mui/material/Button';
import DisplayUserValue     from './DisplayUserValue';
import EditDialog           from './EditDialog';
import Grid                 from '@mui/material/Grid';
import NavBar               from 'NavBar';
import Paper                from '@mui/material/Paper';
import TrustedDevicesDialog from './TrustedDevicesDialog';

import { AuthContext }   from 'App';
import { FINTECH_PATHS } from 'rest/const';
import { useSnackbar }   from 'notistack';

const USER_FIELDS = {
  fullName: {
    displayedValueFn: getFullName,
    labelKey:         'fullNameLabel',
  },
  address: {
    displayedValueFn: getAddress,
    fieldName:        'address',
    labelKey:         'addressLabel',
    pathKey:          'editAddressWithOtp',
    otpType:          'OTP_EDIT_ADDRESS',
    EditComponent:    AddressField,
  },
  phone: {
    fieldName: 'phoneNumber',
    labelKey:  'phoneLabel',
    pathKey:   'editPhoneWithOtp',
    otpType:   'OTP_EDIT_PHONE_NUMBER',
  },
  email: {
    fieldName: 'email',
    labelKey:  'emailLabel',
  },
  consent: { // TODO jeszcze nie dodane
    fieldName: 'consentsAndRepresentations',
    labelKey:  'consentsAndRepresentationsLabel',
    pathKey:   'editConsentWithOtp',
    otpType:   'OTP_EDIT_CONSENT_AND_REPRESENTATIONS',
  },
  trustedDevices: {
    fieldName: 'trustedDevices',
    labelKey:  'trustedDevicesLabel',
  },
};

function buttonFields(t, setTrustedDevicesDialog) {
  return {
    trustedDevices: (
      <Button onClick={() => setTrustedDevicesDialog(true)}>
        {t('showTrustedDevices')}
      </Button>
    ),
    /* TODO dodać gdy gateway doda endpointy
    consent: (
      <Button onClick={() => setConsentAndRepresentationsDialog(true)}>
        {t('showConsentsAndRepresentationsLabel')}
      </Button>
    ),
    */
  };
}


export default function UserDataPage() {

  const { t }        = useTranslation();
  const { classes }  = useStyles();
  const { userInfo } = React.useContext(AuthContext);
  const axiosCfg = createRestRequestConfig(userInfo.accessToken, true);
  const { enqueueSnackbar } = useSnackbar();

  const [getPath,              setGetPath]              = React.useState(FINTECH_PATHS.userInfo);
  const [trustedDevicesDialog, setTrustedDevicesDialog] = React.useState(false);

  const [user,      setUser]      = React.useState({});
  const [editField, setEditField] = React.useState(null); // Field mapped by 'USER_FIELDS'

  useGET(getPath, axiosCfg, {
    onSuccess: (res) => {
      setGetPath(null);
      setUser({
        ...buttonFields(t, setTrustedDevicesDialog),
        ...res.data,
      });

      if (process.env.NODE_ENV === 'development') {
        console.log('UserDataPage, info, onRestSuccess: ', res.data);
      }
    },
    onError: (err) => {
      setGetPath(null);
      enqueueSnackbar(t('common:failure'), { variant: 'error' });

      if (process.env.NODE_ENV === 'development') {
        console.log('UserDataPage, info, onRestError: ', getErrorMessage(err), err.response, err);
        console.error('UserDataPage, info, onRestError: ', err);
      }
    },
  });

  return (
    <>
      <NavBar/>
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          {Object.keys(USER_FIELDS).map((fieldKey) => {
            const field = USER_FIELDS[fieldKey];
            const value = field.displayedValueFn?.(user) || user[field.fieldName];
            const editButton = FINTECH_PATHS[field.pathKey] == null ? undefined : (
              <Button onClick={() => setEditField(field)}>
                {t('editButton')}
              </Button>
            );
            return (
              <DisplayUserValue
                key={fieldKey}
                value={value}
                label={t(field.labelKey)}
                button={editButton}
              />
            );
          })}
        </Grid>
      </Paper>
      {editField &&
        <EditDialog
          user={user}
          axiosCfg={axiosCfg}
          editableField={editField}
          closeDialog={() => setEditField(null)}
        />
      }
      {trustedDevicesDialog &&
        <TrustedDevicesDialog
          userInfo={userInfo}
          closeDialog={() => setTrustedDevicesDialog(false)}
        />
      }
    </>
  );
}

function getFullName(user) {
  let result = '';

  if (user.firstName) {
    result += user.firstName;
  }
  if (user.lastName) {
    if (result) {
      result += ' ';
    }
    result += user.lastName;
  }

  return result;
}

function getAddress(user) {
  const addressObj = user[USER_FIELDS.address.fieldName];
  if (!addressObj) {
    return undefined;
  }
  let result = '';

  if (addressObj.street) {
    result += addressObj.street;
  }
  if (addressObj.zipCode) {
    if (result) {
      result += ', ';
    }
    result += addressObj.zipCode;
  }
  if (addressObj.city) {
    if (result) {
      result += ' ';
    }
    result += addressObj.city;
  }
  if (addressObj.country) {
    if (result) {
      result += ', ';
    }
    result += addressObj.country;
  }

  return result;
}
