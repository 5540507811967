const MIN_LENGTH          = 8;
const MAX_LENGTH          = 128;
const SPECIAL_CHARS_REGEX = /[~`!@#$%&*()\-_+={}[\]|\\/:;'"<>,.?]/g

const REASONS = {
  noPasswords:      'passwordsMissing',
  doNotMatch:       'passwordsDoNotMatch',
  tooShort:         'passwordTooShort',
  tooLong:          'passwordTooLong',
  noDigit:          'passwordMustHaveADigit',
  noSpecial:        'passwordMustHaveASpecialChar',
  includesUsername: 'passwordIncludesUsername',
};

const FIELD_NAMES = {
  newPassword:    'new-password',
  newPasswordAlt: 'password', //Sometimes gateway requires this one
  repeatPassword: 'repeat-password',
};

export { REASONS, FIELD_NAMES, returnAlert };


/**
 * Password validation
 *
 * @param credentials {Object.<String, String>} - object containing passwords and username
 * @param useAltFieldName {Boolean} - should use FIELD_NAMES.newPasswordAlt? required to make it conscious decision
 * @see FIELD_NAMES
 * @return { null | alert }
 */
export default function areNewPasswordsValid(credentials, useAltFieldName) {
  if (!credentials) {
    return returnAlert(REASONS.noPasswords);
  }
  const newPassword    = useAltFieldName ? credentials[FIELD_NAMES.newPasswordAlt] : credentials[FIELD_NAMES.newPassword];
  const repeatPassword = credentials[FIELD_NAMES.repeatPassword];
  if (!newPassword || !repeatPassword) {
    return returnAlert(REASONS.noPasswords);
  }

  if (newPassword !== repeatPassword) {
    return returnAlert(REASONS.doNotMatch);
  }

  if (newPassword.length < MIN_LENGTH) {
    return returnAlert(REASONS.tooShort)
  }
  if (newPassword.length > MAX_LENGTH) {
    return returnAlert(REASONS.tooLong);
  }

  if (newPassword.search(/\d/g) === -1) {
    return returnAlert(REASONS.noDigit);
  }

  if (newPassword.search(SPECIAL_CHARS_REGEX) === -1) {
    return returnAlert(REASONS.noSpecial);
  }

  const username = credentials.username;
  if (newPassword.includes(username))
    return returnAlert(REASONS.includesUsername);

  return null;
}

function returnAlert(msgTranslKey, msgTranslSecondaryKey, type) {
  return {
    msgTranslKey,
    msgTranslSecondaryKey,
    type,
  };
}

/**
 * @typedef {Object} alert
 * @property {String} msgTranslKey - primary alert key
 * @property {String =} msgTranslSecondaryKey - secondary alert key
 * @property {String =} type - type of alert, default 'warning'
 */
