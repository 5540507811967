import React from 'react';

import PropTypes                from 'prop-types';
import { useTranslation }       from 'react-i18next';
import { SessionStorageError }  from 'utils/browserTabsSessionUtils';
import { CookiesDisabledError } from 'utils/browserTabsSessionUtils';
import { MultipleTabsError }    from 'utils/browserTabsSessionUtils';
import Typography               from '@mui/material/Typography';
import WarningAmberIcon         from '@mui/icons-material/WarningAmber';
import Grid                     from '@mui/material/Grid';
import Button                   from '@mui/material/Button';
import useLogout                from 'utils/useLogout';


function SessionUniquenessError(props) {

  const { t } = useTranslation('common');

  const { logout } = useLogout();

  let text = t('onlyOneTabAllowedError');
  if (props.error instanceof CookiesDisabledError)
    text = t('cookiesDisabledError');
  if (props.error instanceof SessionStorageError)
    text = t('sessionStorageDisabledError');

  let showLogoutButton = false;
  if (props.error instanceof MultipleTabsError)
    showLogoutButton = true;

  return (
    <Grid
      container
      direction='column'
      alignItems='center'
      justifyContent='center'
      paddingTop={6}
    >
      <Grid item>
        <WarningAmberIcon fontSize='large' color='error'/>
      </Grid>
      <Grid item>
        <Typography align='center' variant='h5'>
          {text}
        </Typography>
      </Grid>
      {showLogoutButton &&
        <Grid item>
          <Button onClick={logout}>
            {t('logoutButton')}
          </Button>
        </Grid>
      }
    </Grid>
  );
}

SessionUniquenessError.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.instanceOf(SessionStorageError),
    PropTypes.instanceOf(CookiesDisabledError),
    PropTypes.instanceOf(MultipleTabsError),
  ]),
};

export default SessionUniquenessError;