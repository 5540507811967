import React                             from 'react';
import useCurrentAndAvailable3DSMethods  from 'pages/CardDetailsPage/tabs/ThreeDSTab/ThreeDSActions/useCurrentAndAvailable3DSMethods';
import { FormControl }                   from '@mui/material';
import { FormLabel }                     from '@mui/material';
import RadioGroup                        from '@mui/material/RadioGroup';
import FormControlLabel                  from '@mui/material/FormControlLabel';
import Radio                             from '@mui/material/Radio';
import Grid                              from '@mui/material/Grid';
import { useTranslation }                from 'react-i18next';
import { AUTH_METHOD_MOBILE_APP }        from 'pages/CardDetailsPage/tabs/ThreeDSTab/ThreeDSActions/threeDSActionsConst';
import { AUTH_METHOD_SMS_PLUS_QUESTION } from 'pages/CardDetailsPage/tabs/ThreeDSTab/ThreeDSActions/threeDSActionsConst';


export const THREE_DS_METHOD_FIELD_NAME = "three-ds-method";

function ThreeDsMethodsRadioGroup() {

  const { t }                                       = useTranslation('cardDetails');
  const { currentAuthMethod, availableAuthMethods } = useCurrentAndAvailable3DSMethods();

  const otherThanCurrent = availableAuthMethods.filter(method => method !== currentAuthMethod);

  function getMethodLabel(method) {
    switch (method) {
      case AUTH_METHOD_MOBILE_APP:
        return t('mobileApp3DSAuthMethod');
      case AUTH_METHOD_SMS_PLUS_QUESTION:
        return t('smsPlusQuestion3DSAuthMethod');
      default:
        return method;
    }
  }

  return (
    <Grid item>
      <FormControl>
        <FormLabel>
          {t('numberOfAlternativeMethods') + otherThanCurrent.length}
        </FormLabel>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue={otherThanCurrent[0]}
          name={THREE_DS_METHOD_FIELD_NAME}
        >
          {otherThanCurrent.map(method =>
            <FormControlLabel
              key={method}
              label={getMethodLabel(method)}
              value={method}
              control={<Radio/>}
            />
          )}
        </RadioGroup>
      </FormControl>
    </Grid>
  );
}

export default ThreeDsMethodsRadioGroup;