import React from 'react';

import PropTypes           from 'prop-types';
import Dialog              from '@mui/material/Dialog';
import DialogTitle         from '@mui/material/DialogTitle';
import DialogContent       from '@mui/material/DialogContent';
import DialogActions       from '@mui/material/DialogActions';
import Button              from '@mui/material/Button';
import { useTranslation }  from 'react-i18next';
import { OTP_FIELD_NAME }  from 'pages/CardDetailsPage/tabs/DetailsAndOperationsTab/OTPField/OTPField';
import usePinChange        from './usePinChange';
import { PIN_FIELD_NAME }  from './PinFields';
import PinFields           from './PinFields';
import FormWithOtpField    from '../../FormWithOtpField';
import Alert               from 'components/Alert';
import useStyles           from './pinChangeDialogStyles';
import { OTP_TYPES }       from 'pages/CardDetailsPage/tabs/DetailsAndOperationsTab/detailsAndOperationsTabConst';
import { getErrorMessage } from 'rest/sendRequest';
import { GATEWAY_ERRORS }  from 'rest/const';
import validatePinFields   from './PinFields/validatePinFields';


function PinChangeDialog(props) {

  const { classes } = useStyles();
  const { t }       = useTranslation(['common', 'cardDetails']);
  const formRef     = React.useRef();

  /**
   * contract: undefined -> no change was made
   */
  const [changeWasSuccessful, setChangeWasSuccessful]                         = React.useState(undefined);
  const [alert, setAlert]                                                     = React.useState(undefined);
  const [showValidityMessages, setShowValidityMessages]                       = React.useState(false);
  const [pinInputValidationMessage, setPinInputValidationMessage]             = React.useState(undefined);
  const [pinRepeatInputValidationMessage, setPinRepeatInputValidationMessage] = React.useState(undefined);

  const pinInputRef       = React.useRef();
  const pinRepeatInputRef = React.useRef();

  const refs    = {
    pinInputRef:       pinInputRef,
    pinRepeatInputRef: pinRepeatInputRef,
  };
  const setters = {
    setPinInputValidationMessage:       setPinInputValidationMessage,
    setPinRepeatInputValidationMessage: setPinRepeatInputValidationMessage,
  };

  function onCloseWrapper() {
    props.onClose();
    setAlert(undefined);
    setChangeWasSuccessful(undefined);
  }

  function setAlertWrapper() {
    if (changeWasSuccessful === true)
      onCloseWrapper();
    else
      setAlert(undefined);
  }

  function onPinSuccess() {
    setChangeWasSuccessful(true);
    setAlert({
      msgTranslKey: 'success',
      type:         'success',
    })
  }

  function onPinError(err) {
    setChangeWasSuccessful(false);

    const errorMessage = getErrorMessage(err);
    if (errorMessage === GATEWAY_ERRORS.tooManyOtpAttempts) {
      setAlert({
        msgTranslKey: 'tooManyOtpAttempts',
        type:         'error',
      });
      return;
    }

    setAlert({
      msgTranslKey: 'failure',
      type:         'error',
    });
  }

  const { trigger, isInProgress } =
          usePinChange(props.cardId, props.expDate, onPinSuccess, onPinError, formRef);

  function onSubmit(evt) {
    evt.preventDefault();
    setShowValidityMessages(true);  //to display mismatch message or wrong otp message

    const form     = evt.target;
    const formData = new FormData(form);
    const otp      = formData.get(OTP_FIELD_NAME);
    const pin      = formData.get(PIN_FIELD_NAME);

    validatePinFields(refs, setters, t);
    if (form.checkValidity()) {
      trigger(otp, pin);
    }
  }

  function onFormInvalid(evt) {
    evt.preventDefault();  //stops tooltips displayed by browser
    setShowValidityMessages(true);
  }

  return (
    <Dialog
      onClose={onCloseWrapper}
      open={!!props.open} //conversion to boolean
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle>
        {t('cardDetails:changePinDialogTitle')}
      </DialogTitle>
      <DialogContent>
        {!alert &&
          <FormWithOtpField
            formRef={formRef}
            onSubmit={onSubmit}
            onInvalid={onFormInvalid}
            onChange={() => setShowValidityMessages(true)}
            showValidityMessages={showValidityMessages}
            disableSubmit={isInProgress}
            otpType={OTP_TYPES.changePin}
          >
            <PinFields
              refs={refs}
              setters={setters}
              showValidityMessages={showValidityMessages}
              pinInputValidationMessage={pinInputValidationMessage}
              pinRepeatInputValidationMessage={pinRepeatInputValidationMessage}
            />
          </FormWithOtpField>
        }
        {alert &&
          <Alert
            alert={alert}
            setAlert={setAlertWrapper}
          />
        }
      </DialogContent>
      <DialogActions>
        <Button
          id="cancelButton"
          onClick={onCloseWrapper}
          color="primary"
          variant="outlined"
        >
          {changeWasSuccessful === undefined ? t('cardDetails:cancel') : t('common:closeButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

PinChangeDialog.propTypes = {
  open:    PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  cardId:  PropTypes.string,
  expDate: PropTypes.string,
};

export default PinChangeDialog;