import React              from 'react';
import { useTranslation } from 'react-i18next';

import Typography         from '@mui/material/Typography';


function GenericError(props) {

  const { t } = useTranslation();

  console.error("ErrorPage, GenericError, ", props.error);

  return (
    <>
      <Typography variant='h5'>{t('oops')}</Typography>
      <Typography variant='h6'>{t('unexpectedError')}</Typography>
    </>
  );
}

export default GenericError;