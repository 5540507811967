import React                           from 'react';
import PropTypes                       from 'prop-types';
import { useTranslation }              from 'react-i18next';
import useTokensManagement             from 'pages/CardDetailsPage/tabs/MobilePaymentsTab/useTokensManagement';
import useDialogCommonStateAndHandlers from 'pages/CardDetailsPage/tabs/useDialogCommonStateAndHandlers';
import Dialog                          from '@mui/material/Dialog';
import DialogTitle                     from '@mui/material/DialogTitle';
import DialogContent                   from '@mui/material/DialogContent';
import FormWithOtpField                from 'pages/CardDetailsPage/tabs/DetailsAndOperationsTab/FormWithOtpField';
import { OTP_TYPES }                   from 'pages/CardDetailsPage/tabs/DetailsAndOperationsTab/detailsAndOperationsTabConst';
import DialogActions                   from '@mui/material/DialogActions';
import Button                          from '@mui/material/Button';
import Alert                           from 'components/Alert';
import useStyles                       from 'pages/CardDetailsPage/tabs/MobilePaymentsTab/TokenActionsDialog/tokenActionsDialogStyles';
import Typography                      from '@mui/material/Typography';
import Grid                            from '@mui/material/Grid';
import { OTP_FIELD_NAME }              from 'pages/CardDetailsPage/tabs/DetailsAndOperationsTab/OTPField/OTPField';


export const SUSPEND_MODE   = 'suspend';
export const UNSUSPEND_MODE = 'unsuspend';
export const DELETE_MODE    = 'delete';

function TokenActionsDialog(props) {

  const { classes } = useStyles();
  const { t }       = useTranslation('cardDetails');

  const {
          suspendToken,
          unsuspendToken,
          deleteToken,
          inProgress,
        } = useTokensManagement();

  const tokenReferenceId = props.token.tokenReferenceId

  let title;
  let content;
  let translKeys = {};
  let actionFunction;
  let otpType;

  switch (props.mode) {
    case null:
      break;
    case undefined:
      break;
    case SUSPEND_MODE:
      title          = t('cardDetails:tokenActionsDialog.suspendTitle');
      content        = t('cardDetails:tokenActionsDialog.suspendContent')
        .replace('{tokenReferenceId}', tokenReferenceId);
      translKeys     = {
        onSuccess: 'tokenSuspensionSuccessful',
        onError:   'tokenSuspensionFailed',
      };
      actionFunction = suspendToken;
      otpType        = OTP_TYPES.suspendToken;
      break;
    case UNSUSPEND_MODE:
      title          = t('cardDetails:tokenActionsDialog.unsuspendTitle');
      content        = t('cardDetails:tokenActionsDialog.unsuspendContent')
        .replace('{tokenReferenceId}', tokenReferenceId);
      translKeys     = {
        onSuccess: 'tokenUnsuspensionSuccessful',
        onError:   'tokenUnsuspensionFailed',
      };
      actionFunction = unsuspendToken;
      otpType        = OTP_TYPES.unsuspendToken;
      break;
    case DELETE_MODE:
      title          = t('cardDetails:tokenActionsDialog.deleteTitle');
      content        = t('cardDetails:tokenActionsDialog.deleteContent')
        .replace('{tokenReferenceId}', tokenReferenceId);
      translKeys     = {
        onSuccess: 'tokenDeletionSuccessful',
        onError:   'tokenDeletionFailed',
      };
      actionFunction = deleteToken;
      otpType        = OTP_TYPES.deleteToken;
      break;
    default:
      console.warn('TokenActionsDialog, text switch, unknown mode', props.mode);
      break;
  }


  const {
          changeWasSuccessful,
          alert,

          showValidityMessages,
          setShowValidityMessages,

          formRef,

          setAlertWrapper,
          onSuccess,
          onError,
          onFormInvalid,
        } = useDialogCommonStateAndHandlers(props.closeDialog, translKeys);

  function onSubmit(evt) {
    evt.preventDefault();

    const form     = evt.target;
    const formData = new FormData(form);
    const otp      = formData.get(OTP_FIELD_NAME);

    if (!actionFunction) {
      onError();
      console.error('TokenActionsDialog, onSubmit switch, unset action function for mode: ', props.mode);
      return;
    }

    actionFunction(otp, props.token, onSuccess, onError);
  }

  if (!props.mode) {
    return null;
  }

  return (
    <Dialog
      open={true}
      fullWidth={true}
      maxWidth={'md'}
      onClose={props.closeDialog}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle>
        {title}
      </DialogTitle>
      {!alert &&
        <>
          <DialogContent dividers>
            <Grid container direction='column' spacing={2}>
              <Grid item>
                <Typography>
                  {content}
                </Typography>
              </Grid>
              <Grid item>
                <FormWithOtpField
                  formRef={formRef}
                  onSubmit={onSubmit}
                  onInvalid={onFormInvalid}
                  onChange={() => setShowValidityMessages(true)}
                  showValidityMessages={showValidityMessages}
                  disableSubmit={inProgress}
                  otpType={otpType}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              id='cancelButton'
              onClick={props.closeDialog}
              color='primary'
              variant='outlined'
            >
              {changeWasSuccessful === undefined ? t('common:cancelButton') : t('common:closeButton')}
            </Button>
          </DialogActions>
        </>
      }

      {alert &&
        <DialogContent>
          <Alert alert={alert} setAlert={setAlertWrapper}/>
        </DialogContent>
      }
    </Dialog>
  );
}

TokenActionsDialog.propTypes = {
  mode: PropTypes.oneOf([SUSPEND_MODE, UNSUSPEND_MODE, DELETE_MODE]),

  token: PropTypes.shape({
    tokenReferenceId: PropTypes.string.isRequired,
  }).isRequired,

  closeDialog: PropTypes.func.isRequired,
};

export default TokenActionsDialog;