import React from 'react';

import PropTypes           from 'prop-types';
import Grid                from '@mui/material/Grid';
import { useTranslation }  from 'react-i18next';
import LabelledPieceOfInfo from 'pages/CardDetailsPage/tabs/AccountInfoTab/LabelledPieceOfInfo';


function CreditLimits(props) {

  const { t } = useTranslation('cardDetails');

  const holdsAmount    = props.holdsAmount    + props.currencyName;
  const currentBalance = props.currentBalance + props.currencyName;
  const creditLimit    = props.creditLimit    + props.currencyName;

  return (
    <Grid container spacing={8}>
      <Grid item>
        <LabelledPieceOfInfo label={t('currentBalance')} info={currentBalance}/>
      </Grid>
      <Grid item>
        <LabelledPieceOfInfo label={t('holdsAmount')} info={holdsAmount}/>
      </Grid>
      <Grid item>
        <LabelledPieceOfInfo label={t('creditLimit')} info={creditLimit}/>
      </Grid>
    </Grid>
  );
}

CreditLimits.propTypes = {
  holdsAmount:    PropTypes.number.isRequired,
  currentBalance: PropTypes.number.isRequired,
  creditLimit:    PropTypes.number.isRequired,
  currencyName:   PropTypes.string.isRequired,
};

export default CreditLimits;