import React                           from 'react';
import PropTypes                       from 'prop-types';
import Dialog                          from '@mui/material/Dialog';
import DialogTitle                     from '@mui/material/DialogTitle';
import DialogContent                   from '@mui/material/DialogContent';
import Alert                           from 'components/Alert';
import DialogActions                   from '@mui/material/DialogActions';
import Button                          from '@mui/material/Button';
import { useTranslation }              from 'react-i18next';
import useChange3DSMethod              from '../../useChange3DSMethod';
import FormWithOtpField                from 'pages/CardDetailsPage/tabs/DetailsAndOperationsTab/FormWithOtpField';
import { OTP_TYPES }                   from 'pages/CardDetailsPage/tabs/DetailsAndOperationsTab/detailsAndOperationsTabConst';
import ThreeDsMethodsRadioGroup        from './ThreeDSMethodsRadioGroup';
import useStyles                       from './change3DSMethodDialogStyles';
import { OTP_FIELD_NAME }              from 'pages/CardDetailsPage/tabs/DetailsAndOperationsTab/OTPField/OTPField';
import { THREE_DS_METHOD_FIELD_NAME }  from './ThreeDSMethodsRadioGroup/ThreeDSMethodsRadioGroup';
import useDialogCommonStateAndHandlers from 'pages/CardDetailsPage/tabs/useDialogCommonStateAndHandlers';


/**
 * This component and its successors assume available methods list is
 * ready and there is a method other than currently used.
 */
function Change3DsMethodDialog(props) {

  const { classes }                     = useStyles();
  const { t }                           = useTranslation(['common', 'cardDetails']);
  const { inProgress, change3DSMethod } = useChange3DSMethod();

  const {
          changeWasSuccessful,
          alert,

          showValidityMessages,
          setShowValidityMessages,

          formRef,

          setAlertWrapper,
          onSuccess,
          onError,
          onFormInvalid,
        } = useDialogCommonStateAndHandlers(props.closeDialog);

  function onSubmit(evt) {
    evt.preventDefault();
    setShowValidityMessages(true);

    const form     = evt.target;
    const formData = new FormData(form);
    const otp      = formData.get(OTP_FIELD_NAME);
    const method   = formData.get(THREE_DS_METHOD_FIELD_NAME);

    change3DSMethod(otp, method, onSuccess, onError);
  }


  return (
    <Dialog
      open={true}
      onClose={props.closeDialog}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle>
        {t('cardDetails:change3DSMethodDialogTitle')}
      </DialogTitle>
      <DialogContent>
        {!alert &&
          <FormWithOtpField
            formRef={formRef}
            onSubmit={onSubmit}
            onInvalid={onFormInvalid}
            onChange={() => setShowValidityMessages(true)}
            showValidityMessages={showValidityMessages}
            disableSubmit={inProgress}
            otpType={OTP_TYPES.change3DSMethod}
          >
            <ThreeDsMethodsRadioGroup/>
          </FormWithOtpField>
        }

        {alert &&
          <Alert
            alert={alert}
            setAlert={setAlertWrapper}
          />
        }
      </DialogContent>
      <DialogActions>
        <Button
          id='cancelButton'
          onClick={props.closeDialog}
          color='primary'
          variant='outlined'
        >
          {changeWasSuccessful === undefined ? t('common:cancelButton') : t('common:closeButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

Change3DsMethodDialog.propTypes = {
  closeDialog: PropTypes.func.isRequired,
};

export default Change3DsMethodDialog;