import { makeStyles } from 'tss-react/mui';


const useStyles = makeStyles()((theme, color) => ({
  typography: {
    color:     theme.palette[color].main,
    textAlign: 'center',
    width:     '100%',
  },
}));

export default useStyles;
