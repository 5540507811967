import AmountWithCurrency from 'pages/CardDetailsPage/tabs/HistoryTab/AmountWithCurrency';
import Tooltip            from '@mui/material/Tooltip';

import { BILLING_AMOUNT_COLUMN_NAME } from 'pages/MyCardsPage/const';
import { BILLING_DATE_COLUMN_NAME }   from 'pages/MyCardsPage/const';
import { DEBIT_CREDIT_COLUMN_NAME }   from 'pages/MyCardsPage/const';
import { DEBIT }                      from 'pages/MyCardsPage/const';
import { HOLD }                       from 'pages/MyCardsPage/const';
import { OPERATION_TYPE_COLUMN_NAME } from 'pages/MyCardsPage/const';
import { DAYJS_FORMATS }              from 'pages/CardDetailsPage/tabs/components/DateFields/MaskedDateField';
import dayjs                          from 'dayjs';


const HOLD_TRANSACTION_BILLING_DATE = '-';

window.dayjs = dayjs;

export function getDefaultDateFilterData() {
  const defaultFrom = dayjs().subtract(1, 'year').format(DAYJS_FORMATS.DATE);
  const defaultTo   = dayjs().format(DAYJS_FORMATS.DATE);

  return {
    from:     defaultFrom,
    to:       defaultTo,
    errorKey: '',
  };
}

export function generateFilterData(dateFilterData, descriptionFilterData, amountFilterData) {
  const amountInclude = extractDebitCredit(amountFilterData);

  return {
    start_date: rejectBlank(dateFilterData.from),
    end_date:   rejectBlank(dateFilterData.to),

    transaction_desc: rejectBlank(descriptionFilterData),

    start_amount: rejectBlank(amountFilterData.from),
    end_amount:   rejectBlank(amountFilterData.to),

    debit_credit: rejectBlank(amountInclude),
  };
}

export function rejectBlank(data) {
  if (data == null || data.length === 0) { //null or undefined or empty string
    return undefined; //undefined values are not inserted to URL as query params
  }
  return data;
}

export function extractDebitCredit(amountFilterData) {
  let amountInclude = amountFilterData?.include;
  if (amountInclude === 'debit')
    return 'D'
  else if (amountInclude === 'credit')
    return 'C';
  else return undefined;
}

export function columnOptions(classes, t) {

  const dateColumnSize   = 67;  //smallest both headers can fit in
  const amountColumnSize = 60;

  const dateCellsCommonProps = {
    size:                  dateColumnSize,
    muiTableHeadCellProps: {
      className: classes.dateHeadCell,
    },
  };

  return {
    transDate: {
      ...dateCellsCommonProps,
    },

    billingDate: {
      ...dateCellsCommonProps,
      Cell:       (tableData) => {
        const val = tableData.cell.getValue();
        if (HOLD_TRANSACTION_BILLING_DATE !== val) {
          return val;
        }
        return (
          <Tooltip title={t('holdTransactionBillingDateTooltip')} arrow placement='top'>
            <div> {/* String can't hold a ref required by Tooltip */}
              {val}
            </div>
          </Tooltip>
        );
      },
      accessorFn: handleBillingDate,
    },

    transDesc: {
      Cell:                  ({ cell }) => cell.getValue()?.toLowerCase(),
      muiTableBodyCellProps: {
        className: classes.wrapping,
      },
    },

    transTypeDesc: {
      Cell:                  ({ cell }) => cell.getValue()?.toLowerCase(),
      muiTableBodyCellProps: {
        className: classes.wrapping,
      },
    },

    originalAmountWithCurrency: {
      size: amountColumnSize,
      muiTableBodyCellProps: {
        className: classes.wrapping,
      },
      Cell: (tableData) => {
        const amount   = tableData.row.original.originalAmount;
        const currency = tableData.row.original.originalCurrName;
        const isDebit  = tableData.row.original[DEBIT_CREDIT_COLUMN_NAME] === DEBIT;
        return (
          <AmountWithCurrency
            amount={amount}
            currency={currency}
            isDebit={isDebit}
          />
        );
      },
    },

    billingAmountWithCurrency: {
      size: amountColumnSize,
      muiTableBodyCellProps: {
        className: classes.wrapping,
      },
      Cell: (tableData) => {
        const amount   = tableData.row.original[BILLING_AMOUNT_COLUMN_NAME];
        const currency = tableData.row.original.billingCurrName;
        const isDebit  = tableData.row.original[DEBIT_CREDIT_COLUMN_NAME] === DEBIT;
        return (
          <AmountWithCurrency
            amount={amount}
            currency={currency}
            isDebit={isDebit}
          />
        );
      },
    },
  };
}

function handleBillingDate(rowData) {
  if (rowData[OPERATION_TYPE_COLUMN_NAME] === HOLD) {
    return HOLD_TRANSACTION_BILLING_DATE;
  }
  return rowData[BILLING_DATE_COLUMN_NAME];
}
