import React from 'react';

import Grid       from '@mui/material/Grid';
import Typography from '@mui/material/Typography';


export default function DisplayUserValue({ value, label, button }) {
  if (!value) {
    return undefined;
  }

  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <Typography>
            {label}
          </Typography>
        </Grid>
        <Grid item xs={5}>
          {value}
        </Grid>
        <Grid item xs={2}>
          {button}
        </Grid>
      </Grid>
    </Grid>
  );
}
