import React              from 'react';
import { useTranslation } from 'react-i18next';

import DebitCreditRadioButtons from './DebitCreditRadioButtons';
import Grid                    from '@mui/material/Grid';
import TextField               from '@mui/material/TextField';
import Typography              from '@mui/material/Typography';

import PropTypes from 'prop-types';

const TO_FIELD   = 'to';
const FROM_FILED = 'from';


function AmountFields(props) {

  const { t } = useTranslation('cardDetails', { keyPrefix: 'filters' });

  const [displayError, setDisplayError] = React.useState(false);
  const errorKey = props.amount.errorKey;
  const isError = displayError && !!errorKey;

  function amountFilter(value) {
    return value.replaceAll(/[^\d.,]/g, '');
  }

  function onChange(newValue, fieldName) {
    newValue = newValue.replace(',', '.');
    setDisplayError(false);
    props.setAmount(prevAmount => {
      const newAmount = {
        ...prevAmount,
        errorKey: validateAmounts(newValue, fieldName),
      };
      newAmount[fieldName] = amountFilter(newValue);

      if (prevAmount[fieldName] === newAmount[fieldName]) {
        return prevAmount; //Nothing changed
      }
      return newAmount; // It flat-copied prevAmount already
    });
  }

  function onChangeAmountFrom(evt) {
    const value = evt.target.value || '';
    onChange(value, FROM_FILED);
  }

  function onChangeAmountTo(evt) {
    const value = evt.target.value || '';
    onChange(value, TO_FIELD);
  }

  function validateAmounts(newValue, fieldName) {
    const amountFrom = (fieldName === FROM_FILED ? newValue : props.amount.from) || '-1';
    const amountTo   = (fieldName === TO_FIELD   ? newValue : props.amount.to  ) || '-1';

    if (amountFrom.split('.').length > 2) {
      return 'singleDecimalPointFrom';
    } else if (amountTo.split('.').length > 2) {
      return 'singleDecimalPointTo';
    }

    if (amountFrom < 0 || amountTo < 0) {
      return '';
    }
    if (parseFloat(amountTo) < parseFloat(amountFrom)) {
      return 'amountToLowerThanAmountFrom';
    }
    return '';
  }

  return (
    <Grid container spacing={2} alignItems='flex-start'>
      <Grid item sm={4} xs={12} minWidth={'min(100%, 320px)'}>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <TextField
              fullWidth
              value={props.amount.from || ''}
              onChange={onChangeAmountFrom}
              onBlur={() => setDisplayError(true)}
              label={t('amountFrom')}
              error={isError}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              fullWidth
              value={props.amount.to || ''}
              onChange={onChangeAmountTo}
              onBlur={() => setDisplayError(true)}
              label={t('amountTo')}
              error={isError}
            />
          </Grid>
          <Grid item md={8} sm={12}>
            {isError
              ? <Typography>
                  {t(errorKey)}
                </Typography>
              : '\u00A0' // '&nbsp;' aka non breaking space
            }
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <DebitCreditRadioButtons
          setAmount={props.setAmount}
          amount={props.amount}
        />
      </Grid>
    </Grid>
  );
}

AmountFields.propTypes = {
  amount: PropTypes.shape({
    from:     PropTypes.string,
    to:       PropTypes.string,
    errorKey: PropTypes.string,
    include:  PropTypes.oneOf(['debit', 'credit', 'all']),
  }).isRequired,
  setAmount: PropTypes.func.isRequired,
};

export default React.memo(AmountFields);