import React              from 'react';
import usePOST            from 'rest/usePOST';
import useStyles          from 'components/CommonForm/commonFormStyles';
import { useTranslation } from 'react-i18next';

import Alert         from 'components/Alert';
import CommonForm    from 'components/CommonForm';
import COMMON_FIELDS from 'components/CommonForm/fields';
import Container     from '@mui/material/Container';
import Paper         from '@mui/material/Paper';
import Typography    from '@mui/material/Typography';

import { BACKEND_PATHS }   from 'rest/const';
import { getErrorMessage } from 'rest/sendRequest';

const FIELDS = {
  username: {
    ...COMMON_FIELDS.username,
  },
};


function ForgotPasswordPage() {

  const { t }       = useTranslation();
  const { classes } = useStyles();

  const [alert,      setAlert]      = React.useState(undefined);
  const [submitData, setSubmitData] = React.useState(undefined);
  const [success,    setSuccess]    = React.useState(false);

  const swrOpts = {
    onSuccess: (res) => {
      setSubmitData(null);
      setSuccess(true);
      setAlert({
        msgTranslKey: 'emailWasSent',
        type:         'success',
      });

      if (process.env.NODE_ENV === 'development') {
        console.log('ForgotPasswordPage, onRestSuccess: ', res.data);
      }
    },
    onError: (err) => {
      setSubmitData(null);
      //TODO? add more specific handling when API is developed
      setAlert({
        msgTranslKey: 'serverError',
        type:         'warning',
      });

      if (process.env.NODE_ENV === 'development') {
        console.log('ForgotPasswordPage, onRestError: ', getErrorMessage(err), err.response);
        console.error('ForgotPasswordPage, onRestError: ', err);
      }
    },
  };

  const FORGOT_PASSWORD_PATH = submitData ? BACKEND_PATHS.forgotPassword : null; //null = don't send
  const FORGOT_PASSWORD_POST = usePOST(FORGOT_PASSWORD_PATH, submitData, undefined, swrOpts);

  function handleSubmit(evt) {
    evt.preventDefault();
    setAlert(null);
    const formData      = new FormData(evt.target);
    const toBeSubmitted = {
      type: 'EMAIL',
    };

    for (const [key, value] of formData.entries()) {
      toBeSubmitted[key] = value;
    }

    setSubmitData(toBeSubmitted); //TODO? add encryption?
  }

  function submitBtnTooltipFn() {
    if (FORGOT_PASSWORD_POST.isValidating) {
      return t('inProgress');
    } else if (success) {
      return t('emailWasSent');
    }
    return '';
  }

  return (
    <Container maxWidth='xs'>
      <Alert alert={alert} setAlert={setAlert}/>
      <Paper elevation={6} className={classes.mainPaper}>
        <Paper elevation={0} className={classes.titlePaper}>
          <Typography
            align='center'
            variant='subtitle1'
            className={classes.title}
          >
            {t('forgotPasswordTitle')}
          </Typography>
        </Paper>
        <CommonForm
          t={t}
          fields={FIELDS}
          onSubmit={handleSubmit}
          submitBtnText='forgotPasswordTitle'
          submitBtnTooltipFn={submitBtnTooltipFn}
          everythingDisabled={FORGOT_PASSWORD_POST.isValidating || success}
        />
      </Paper>
    </Container>
  );
}

export default ForgotPasswordPage;