import Grid                      from '@mui/material/Grid';
import OTPField                  from 'pages/CardDetailsPage/tabs/DetailsAndOperationsTab/OTPField';
import React                     from 'react';
import PropTypes                 from 'prop-types';
import Button                    from '@mui/material/Button';
import { useTranslation }        from 'react-i18next';
import { GRID_SPACING }          from 'pages/CardDetailsPage/tabs/DetailsAndOperationsTab/detailsAndOperationsTabConst';
import useOTP                    from 'pages/CardDetailsPage/tabs/CardLimitsTab/useOTP';
import { commonOTPErrorHandled } from 'rest/const';
import { useSnackbar }           from 'notistack';
import Alert                     from 'components/Alert';


const RESEND_DISABLE_TIMEOUT = 5000;

export default function FormWithOtpField(props) {

  const { t }               = useTranslation('common');
  const { enqueueSnackbar } = useSnackbar();

  const [userRequestedOtp, setUserRequestedOtp]               = React.useState(false);
  const [disableResend, setDisableResend]                     = React.useState(false);
  const [disableRequestOtpButton, setDisableRequestOtpButton] = React.useState(false);

  const [alert, setAlert] = React.useState(undefined);

  function onOtpSuccess(res) {
    enqueueSnackbar(t('common:successCallOTP').replace('&{phoneNo}', res.data.phoneNo));
    setUserRequestedOtp(true);
  }

  function onOtpError(err) {
    setDisableRequestOtpButton(false);
    console.error("Error while sending OTP code: ", err);
    const otpErrorHandler = (errKey) => {
      setAlert({
        msgTranslKey: errKey,
        type:         'error',
      });
    }
    if (commonOTPErrorHandled(err, otpErrorHandler)) {
      return;
    }
    setAlert({
      msgTranslKey: 'failure',
      type:         'error',
    });
  }

  const { sendOtp } = useOTP(onOtpSuccess, onOtpError);

  function resendOtp() {
    setDisableResend(true);
    sendOtp(props.otpType);
    setTimeout(() => setDisableResend(false), RESEND_DISABLE_TIMEOUT);
  }

  function otpRequestHandler() {
    sendOtp(props.otpType);
    setDisableRequestOtpButton(true);
    props.onOtpRequested?.();
  }

  const otpRequestHandlerRef = React.useRef()
  otpRequestHandlerRef.current = otpRequestHandler;

  React.useEffect(() => {
    if (!props.skipUserOtpRequest)
      return;
    otpRequestHandlerRef.current();
  }, [props.skipUserOtpRequest]);

  if (alert) {
    return (
      <Alert alert={alert} setAlert={setAlert}/>
    );
  }

  if (!props.otpType)
    return null;

  const showOtpField = userRequestedOtp || props.skipUserOtpRequest;
  const buttonText = props.sendOtpButtonText || t('sendOtp');

  let alignItems = 'flex-start';
  if (!showOtpField && !props.children)
    alignItems = 'center';  //center the button when it's alone

  return (
    <form
      onSubmit={props.onSubmit}
      onInvalid={props.onInvalid}
      onChange={props.onChange}
      ref={props.formRef}
    >
      <Grid
        container
        direction="column"
        alignItems={alignItems}
        spacing={GRID_SPACING}
      >
        {props.children}

        <Grid item>
          {showOtpField &&
            <OTPField
              resend={resendOtp}
              showValidityMessages={props.showValidityMessages}
              disableSubmit={props.disableSubmit}
              disableResend={disableResend || props.disableSubmit}
            />
          }
          {!showOtpField &&
            <Button
              onClick={otpRequestHandler}
              disabled={disableRequestOtpButton}
            >
              {buttonText}
            </Button>
          }
        </Grid>
      </Grid>
    </form>
  );
}

FormWithOtpField.propTypes = {
  sendOtpButtonText: PropTypes.string,
  /**
   * The form displays a button which sends OTP when clicked. This
   * property allows to skip it and send OTP right away. Note that
   * this property is saved in state and can't change while the form
   * is mounted.
   */
  skipUserOtpRequest:      PropTypes.bool,
  formRef:                 PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]),
  otpType:                 PropTypes.string,
  onSubmit:                PropTypes.func,
  /**
   * Called when otp is requested by user for the first time.
   */
  onOtpRequested:          PropTypes.func,
  disableSubmit:           PropTypes.any,
  setShowValidityMessages: PropTypes.bool,
};