import { makeStyles } from 'tss-react/mui';


const useStyles = makeStyles()(theme => ({
  accordionSummary:      {
    flexFlow: 'row-reverse',
  },
  accordionSummaryTitle: {
    fontWeight:    theme.typography.fontWeightSemibold,
    fontSize:      '1.5rem',
    textTransform: 'uppercase',
  },
}));

export default useStyles;
