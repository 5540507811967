import React              from 'react';
import useStyles          from './descriptionFieldStyles';
import { useTranslation } from 'react-i18next';

import TextField from '@mui/material/TextField';

import PropTypes from 'prop-types';


function DescriptionField(props) {

  const { t }       = useTranslation('cardDetails', { keyPrefix: 'filters' });
  const { classes } = useStyles();

  return (
    <TextField
      fullWidth
      value={props.description || ''}
      onChange={evt => props.setDescription(evt.target.value)}
      label={t('description')}
      helperText=' '
      className={classes.fullHeight}
      InputProps={{
        className: classes.fullHeight,
      }}
    />
  );
}

DescriptionField.propTypes = {
  description:    PropTypes.string,
  setDescription: PropTypes.func.isRequired,
};

export default React.memo(DescriptionField);