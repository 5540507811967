import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  appBar: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),

    marginBottom: theme.spacing(2),

    color: theme.palette.secondaryBackgroundColor.contrastText,
    backgroundColor: theme.palette.secondaryBackgroundColor.main
  },
}));

export default useStyles;