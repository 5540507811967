import React from 'react';

import PropTypes                      from 'prop-types';
import { useTranslation }             from 'react-i18next';
import Dialog                         from '@mui/material/Dialog';
import DialogTitle                    from '@mui/material/DialogTitle';
import DialogContent                  from '@mui/material/DialogContent';
import Button                         from '@mui/material/Button';
import useMerchantWhitelistManagement from 'pages/CardDetailsPage/tabs/ThreeDSTab/useMerchantWhitelistManagement';
import Alert                          from 'components/Alert';
import DialogActions                  from '@mui/material/DialogActions';


function DeleteMerchantFromWhitelistDialog(props) {

  const { t } = useTranslation('cardDetails');

  const [alert, setAlert] = React.useState(undefined);

  const { deleteMerchantFromWhitelist, inProgress } = useMerchantWhitelistManagement();

  const { merchantId, merchantName } = props.merchantToDelete;

  /**
   *  Resets state when merchantId changes. Makes sure a result of one
   *  action will not leak into a next one.
   */
  React.useEffect(() => {
    setAlert(undefined);
  }, [merchantId]);

  function onDeleteSuccess() {
    //the alert will be shown only until the data is mutated because
    // then the row will be removed and the entire dialog window with it
    setAlert({
      msgTranslKey: 'deletionFromWhitelistSuccessful',
      type:         'success',
    });
  }

  function onDeleteFailure() {
    setAlert({
      msgTranslKey: 'deletionFromWhitelistFailed',
      type:         'error',
    });
  }

  function setAlertWrapper() {
    setAlert(undefined);
    props.closeDialog();
  }


  return (
    <Dialog
      open={true}
      fullWidth={true}
      maxWidth={'sm'}
      onClose={props.closeDialog}
    >
      <DialogTitle>
        {t('deleteMerchantFromWhitelist.title')}
      </DialogTitle>
      {!alert &&
        <>
          <DialogContent dividers>
            {t('deleteMerchantFromWhitelist.content').replace('{merchantName}', merchantName)}
          </DialogContent>
          <DialogActions>
            <Button
              id="submitButton"
              onClick={() => deleteMerchantFromWhitelist(props.merchantToDelete, onDeleteSuccess, onDeleteFailure)}
              color="primary"
              variant="contained"
              disabled={inProgress}
            >
              {inProgress ? t('deleteMerchantFromWhitelist.deletionInProgress') : t('deleteMerchantFromWhitelist.yes')}
            </Button>
            <Button
              id="cancelButton"
              onClick={props.closeDialog}
              color="primary"
              variant="outlined"
            >
              {t('deleteMerchantFromWhitelist.no')}
            </Button>
          </DialogActions>
        </>
      }

      {alert &&
        <DialogContent>
          <Alert alert={alert} setAlert={setAlertWrapper}/>
        </DialogContent>
      }
    </Dialog>
  );
}

DeleteMerchantFromWhitelistDialog.propTypes = {
  merchantToDelete: PropTypes.shape({
    merchantId:     PropTypes.string.isRequired,
    merchantAcqBin: PropTypes.string.isRequired,
    merchantName:   PropTypes.string.isRequired,
  }).isRequired,
  closeDialog:      PropTypes.func.isRequired,
};

export default DeleteMerchantFromWhitelistDialog;