import React                             from 'react';
import DeleteMerchantFromWhitelistDialog from './DeleteMerchantFromWhitelistDialog';
import ThreeDSActions                    from './ThreeDSActions';
import MerchantWhitelistInAccordion      from './MerchantWhitelistInAccordion';


export const DeleteMerchantDialogContext = React.createContext(undefined);

/**
 * This component's purpose is to separate `DeleteMerchantFromWhitelistDialog`
 * from the table. If the dialog was a successor of a row in the table, it
 * would disappear when table is refreshed, making it impossible to refresh the
 * table and to present result inside the dialog at the same time. On the other
 * hand, if tha dialog was managed by `MerchantWhitelistTable` it would be
 * really slow, because the table would refresh when dialog opens or closes.
 *
 * Current solution involves memoization of the table with `React.memo` which
 * fixes performance.
 * @returns {JSX.Element}
 * @constructor
 */
function ThreeDSTab() {

  const [merchantToDelete, setMerchantToDelete] = React.useState(undefined);

  function closeDialog() {
    setMerchantToDelete(undefined);
  }

  return (
    <DeleteMerchantDialogContext.Provider value={setMerchantToDelete}>
      {merchantToDelete &&
        <DeleteMerchantFromWhitelistDialog
          merchantToDelete={merchantToDelete}
          closeDialog={closeDialog}
        />
      }
      <ThreeDSActions/>
      <MerchantWhitelistInAccordion/>
    </DeleteMerchantDialogContext.Provider>
  );
}

export default ThreeDSTab;