import React              from 'react';
import prepareColumns     from 'pages/MyCardsPage/CardsTable/prepareColumns';
import { useTranslation } from 'react-i18next';


export default function useMerchantWhitelistColumns() {

  const { t } = useTranslation(['common', 'cardDetails']);

  return React.useMemo(() => {
    const options = {
      maxAmount: {
        Cell: ({ cell }) => {
          const noMaxAmountText = t('cardDetails:addMerchantToWhitelist.noMaxAmount');
          // Currently, currency is static and always PLN
          return cell.getValue() ? cell.getValue() + ' PLN' : noMaxAmountText;
        },
      },
    };

    return prepareColumns(
      t('cardDetails:merchantWhitelistTableColumnNames', { returnObjects: true }),
      options
    );
  }, [t]);
}