import React              from 'react';
import useDELETE          from 'rest/useDELETE';
import useGET             from 'rest/useGET';
import { useTranslation } from 'react-i18next';

import createRestRequestConfig from 'rest/createRestRequestConfig';
import { getErrorMessage }     from 'rest/sendRequest';

import Button                      from '@mui/material/Button';
import ConfirmDeviceDeletionDialog from './ConfirmDeviceDeletionDialog';
import Dialog                      from '@mui/material/Dialog';
import DialogActions               from '@mui/material/DialogActions';
import DialogContent               from '@mui/material/DialogContent';
import DialogTitle                 from '@mui/material/DialogTitle';
import DisplayUserValue            from '../DisplayUserValue';
import Grid                        from '@mui/material/Grid';

import { AuthContext }   from 'App';
import { FINTECH_PATHS } from 'rest/const';
import { useSnackbar }   from 'notistack';


export default function TrustedDevicesDialog({ closeDialog }) {

  const { t }        = useTranslation();
  const { userInfo } = React.useContext(AuthContext);
  const axiosCfg = createRestRequestConfig(userInfo.accessToken, true);
  const { enqueueSnackbar } = useSnackbar();

  const [trustedDevices, setTrustedDevices] = React.useState([]);
  const [getPath,        setGetPath]        = React.useState(FINTECH_PATHS.getTrustedDevices);
  useGET(getPath, axiosCfg, {
    onSuccess: (res) => {
      setGetPath(null);
      setTrustedDevices(res.data || []);

      if (process.env.NODE_ENV === 'development') {
        console.log('UserDataPage, trustedDevices, onRestSuccess: ', res.data);
      }
    },
    onError: (err) => {
      setGetPath(null);
      enqueueSnackbar(t('common:failure'), { variant: 'error' });

      if (process.env.NODE_ENV === 'development') {
        console.log('UserDataPage, trustedDevices, onRestError: ', getErrorMessage(err), err.response, err);
        console.error('UserDataPage, trustedDevices, onRestError: ', err);
      }
    },
  });

  const [deleteDevice, setDeleteDevice] = React.useState(null);
  const [deletePath,   setDeletePath]   = React.useState(null);
  useDELETE(deletePath, null, axiosCfg, {
    onSuccess: (res) => {
      setDeletePath(null);
      setGetPath(FINTECH_PATHS.getTrustedDevices); // Download them again to refresh

      if (process.env.NODE_ENV === 'development') {
        console.log('UserDataPage, deleteTrustedDevices, onRestSuccess: ', res.data);
      }
    },
    onError: (err) => {
      setDeletePath(null);
      enqueueSnackbar(t('common:failure'), { variant: 'error' });

      if (process.env.NODE_ENV === 'development') {
        console.log('UserDataPage, deleteTrustedDevices, onRestError: ', getErrorMessage(err), err.response, err);
        console.error('UserDataPage, deleteTrustedDevices, onRestError: ', err);
      }
    },
  });

  return (
    <Dialog
      open={true}
      fullWidth
      maxWidth={'sm'}
      onClose={(evt, type) => {
        if (type === 'backdropClick') {
          return; //Don't close on backdrop click
        }
        closeDialog();
      }}
    >
      <DialogTitle>{t('trustedDevicesLabel')}</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          {trustedDevices.length === 0 &&
            <Grid item xs={12}>
              {t('noTrustedDevices')}
            </Grid>
          }
          {trustedDevices.map((device) => {
            const deleteButton = (
              <Button onClick={() => setDeleteDevice(device)}>
                {t('deleteButton')}
              </Button>
            );
            return (
              <DisplayUserValue
                key={device.deviceId}
                value={device.deviceDescription}
                label={t('deviceNameLabel')}
                button={deleteButton}
              />
            );
          })}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          id='closeButton'
          onClick={closeDialog}
          color='primary'
          variant='outlined'
        >
          {t('closeButton')}
        </Button>
      </DialogActions>
      {deleteDevice &&
        <ConfirmDeviceDeletionDialog
          deviceName={deleteDevice.deviceDescription}
          onSubmit={() => {
            setDeletePath(FINTECH_PATHS.deleteTrustedDevice.replace('{deviceId}', deleteDevice.deviceId));
            setDeleteDevice(null);
          }}
          closeDialog={() => setDeleteDevice(null)}
        />
      }
    </Dialog>
  );
}
