import { makeStyles } from 'tss-react/mui';

export const PAPER_PADDING_FACTOR = 2;

const useStyles = makeStyles()((theme) => ({
  paper: {
    position: 'relative',
    padding:  theme.spacing(PAPER_PADDING_FACTOR),
  },
  dialogContainer: {
    alignItems: 'flex-start',
  },
  tabSelected: {
    fontWeight:       theme.typography.fontWeightBold,
    color:            theme.palette.primary.main + '!important',
    backgroundColor: '#FFF !important',
    boxShadow:       'inset 0 0.05em 0.1em #000 !important',
    borderStyle:     'inset',
    borderColor:     '#000',
    borderWidth:     1,
  },
  tabContent: {
    marginTop: 50,
  },
  tab: {
    color: '#FFF !important',
  },
}));

export default useStyles;
