import checkPinComplexity from './checkPinComplexity';
import isBlank            from 'utils/isBlank';


/**
 * Checks if the PIN has required complexity and if both values, pin
 * and pin-repeat, are present checks if they are the same. Uses
 * browsers' built-in mechanism to report error.
 * @param {{pinInputRef, pinRepeatInputRef}} refs references to input fields
 * @param {{setPinInputValidationMessage, setPinRepeatInputValidationMessage}} setters validation messages' setters
 * @param {function} t translation function with `cardDetails` namespace loaded
 */
export default function validatePinFields(refs, setters, t) {
  const { pinInputRef, pinRepeatInputRef }                                   = refs;
  const { setPinInputValidationMessage, setPinRepeatInputValidationMessage } = setters;

  const pinField             = pinInputRef.current;
  const pinValue             = pinField.value;
  const pinRepeatField       = pinRepeatInputRef.current;
  const pinRepeatValue       = pinRepeatField.value;
  const complexityValidation = checkPinComplexity(pinValue, t);
  const skipFieldsEquality   = isBlank(pinRepeatValue);

  function setValidityMessage(message) {
    //these are for browser
    pinField.setCustomValidity(message);
    pinRepeatField.setCustomValidity(message);
    //these change helper text under the fields
    setPinInputValidationMessage(message);
    setPinRepeatInputValidationMessage(message);
  }

  function resetControlBackToTheBrowser() {
    setValidityMessage('');
  }

  if (complexityValidation)
    setValidityMessage(complexityValidation);
  else if (skipFieldsEquality)
    resetControlBackToTheBrowser();
  else if (pinValue !== pinRepeatValue)
    setValidityMessage(t('cardDetails:pinsDontMatch'));
  else
    resetControlBackToTheBrowser();
}