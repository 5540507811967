import React from 'react';

import InputAdornment          from '@mui/material/InputAdornment';
import PropTypes               from 'prop-types';
import Typography              from '@mui/material/Typography';
import { useTranslation }      from 'react-i18next';
import TextField               from '@mui/material/TextField';
import useStyles               from './cardLimitStyles';
import { preventNumberChange } from 'utils/preventNumberChange';
import isBlank                 from 'utils/isBlank';


const CNP_TRANS_LIMIT_NAME = 'CNP_AMT_LMT';
const POS_TRANS_LIMIT_NAME = 'POS_PUR_LMT';

function CardLimit(props) {

  const { t }       = useTranslation('cardDetails');
  const { classes } = useStyles();

  const available            = props.limit.available;
  let descWithAvailablePart1;
  let descWithAvailablePart2 = props.limit.desc;

  // it has been requested to change the descriptions of those two
  // types of limits to make them more user-friendly and also to add
  // the line break in the specified place for the POS limit desc
  if (props.limit.name === CNP_TRANS_LIMIT_NAME) {
    descWithAvailablePart2 = t('cardNotPresentTransactionLimitDescription');
  } else if (props.limit.name === POS_TRANS_LIMIT_NAME) {
    descWithAvailablePart1 = t('pointOfServiceTransactionLimitDescriptionPart1');
    descWithAvailablePart2 = t('pointOfServiceTransactionLimitDescriptionPart2');
  }

  if (available != null)
    descWithAvailablePart2 += t('limitAmountLeft').replace('{amount}', available).replace('{currency}', props.currency);
  else
    descWithAvailablePart2 += t('availableBalanceInaccessible');

  if (props.displayOnly) {
    return (
      <tr>
        <td>
          <Typography>
            {descWithAvailablePart1}
          </Typography>
          <Typography>
            {descWithAvailablePart2}
          </Typography>
        </td>
        <td>
          <Typography>
            {props.limit.value + ' ' + props.currency}
          </Typography>
        </td>
      </tr>
    );
  }

  let max = props.limit.max;
  if (max != null)
    max = max.toLocaleString() + ' PLN';

  return (
    <tr>
      <td>
        <Typography>
          {descWithAvailablePart1}
        </Typography>
        <Typography>
          {descWithAvailablePart2}
        </Typography>
      </td>
      <td>
        <TextField
          name={props.limit.name}
          defaultValue={props.limit.value}
          onChange={props.onChange}
          type='number'
          className={classes.limitValueField}
          onWheel={preventNumberChange}
          inputProps={{
            max: max,
            hidenumberadornment: 'true', //Firefox
          }}
          InputProps={{
            hidenumberadornment: 'true', //Chrome, Edge, Opera, Safari
            classes: {
              input: classes.inputField,
            },
            endAdornment: (
              <InputAdornment position='end'>
                {props.currency}
              </InputAdornment>
            ),
          }}
        />
      </td>
      <td align='center'>
        {!isBlank(max) &&
          <Typography>
            {max}
          </Typography>
        }
      </td>
    </tr>
  );
}

CardLimit.propTypes = {
  displayOnly: PropTypes.bool,
  limit: PropTypes.shape({
    name:      PropTypes.string.isRequired,
    desc:      PropTypes.string.isRequired,
    value:     PropTypes.number.isRequired,
    available: PropTypes.number,
    min:       PropTypes.number,
    max:       PropTypes.number,
  }),
};

export default CardLimit;
