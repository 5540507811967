import React from 'react';

import Alert              from 'components/Alert';
import MaterialReactTable from 'material-react-table';
import Typography         from '@mui/material/Typography';

import columnOptions           from './cardsTableColumnOptions';
import createRestRequestConfig from 'rest/createRestRequestConfig';
import prepareColumns          from './prepareColumns';
import useREST                 from 'rest/useREST';
import useStyles               from './cardsTableStyles';

import { AuthContext }         from 'App';
import { CARD_STATUSES_NS }    from 'i18n';
import { DATA_TABLE_NS }       from '../const';
import { FINTECH_PATHS }       from 'rest/const';
import { MRT_Localization_PL } from 'material-react-table/locales/pl';
import { QueryClient }         from '@tanstack/react-query';
import { QueryClientProvider } from '@tanstack/react-query';
import { useInfiniteQuery }    from '@tanstack/react-query';
import { useTranslation }      from 'react-i18next';
import { useNavigate }         from 'react-router-dom';
import internalPaths           from 'routing/internalPaths';

const fetchSize = 50;

function CardsTableFn(props) {

  const { classes }  = useStyles();
  const { t }        = useTranslation(DATA_TABLE_NS);
  const cardStatuses = useTranslation(CARD_STATUSES_NS);

  const navigate    = useNavigate();
  const fetcher = useREST();

  const { userInfo, hasJWTExpired } = React.useContext(AuthContext);
  const axiosCfgRef = React.useRef(createRestRequestConfig(userInfo.accessToken, true));
  React.useEffect(() => {
    axiosCfgRef.current = createRestRequestConfig(userInfo.accessToken, true);
  }, [userInfo.accessToken]);

  const [alert, setAlert] = React.useState(undefined);

  //Those states are used to decrease desyncs between displayed data and actual data
  //useInfiniteQuery returns those values, but hooks need a moment to update everything and it desyncs a bit
  const [tableData,  setTableData]  = React.useState([]);
  const [isFetching, setIsFetching] = React.useState(true);
  const [fetchError, setFetchError] = React.useState(null);

  /**
   * Infinite scrolling
   */

  const { data, fetchNextPage } = useInfiniteQuery(
    ['infinite-scrolling-myCards-key-' + props.organization],
    async ({ pageParam = 1 }) => {
      setIsFetching(true);
      setFetchError(null);

      const path = FINTECH_PATHS.myCards
        .replace('{org}', props.organization)
        .replace('{page}', pageParam)
        .replace('{per_page}', '' + fetchSize);

      const cfgWithQueryParams = {
        ...axiosCfgRef.current,
      };

      const response = await fetcher([path, 'GET', null, cfgWithQueryParams]);
      return response.data;
    },
    {
      getNextPageParam: (currentlyDownloadedPage, allPages) => allPages.length + 1,
      keepPreviousData:     true,
      refetchOnMount:       true,
      refetchOnWindowFocus: false,
      retryDelay: (failureCount, error) => {
        if (hasJWTExpired(error)) {
          return 5000; //Wait longer, it tries to refresh session
        }
        return 2500;
      },
      retry: (failureCount, error) => { //Retry after failure
        //TODO wymyślić jakieś warunki wg których należy przerwać pobieranie danych
        return failureCount < 100 || error.response?.status !== 4444;
      },
      onSettled: (data, error) => {
        setIsFetching(false);
        if (data) { //Data is null when error occurs
          setTableData(data.pages.flatMap((page) => page));
        }
        if (error) {
          setFetchError(error);
        }
      },
    },
  );

  //Last page has fewer records than fetchSize = all fetched = don't fetch more
  const finishedFetching = data?.pages?.length > 0 ? data.pages[data.pages.length - 1].length < fetchSize : false;

  //Called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
  const fetchMoreOnBottomReached = React.useCallback(
    (containerRefElement) => {
      if (isFetching || finishedFetching) {
        return;
      }
      const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
      //Once the user has scrolled within 100px of the bottom of the table, fetch more data if we can
      const fetchAtDistanceFromBottom = 100;
      if (scrollHeight - scrollTop - clientHeight < fetchAtDistanceFromBottom) {
        setAlert(null);
        setIsFetching(true);
        setFetchError(null);
        //Scroll up, this way it won't do multiple fetches at once
        containerRefElement.scrollTop = scrollTop - fetchAtDistanceFromBottom + 5;
        fetchNextPage();
      }
    }, [isFetching, finishedFetching, fetchNextPage]
  );

  /**
   * General props
   * translation, columns and data
   */

  const localization = React.useMemo(
    () => {
      const localization = t('localization', { returnObjects: true });
      return { ...MRT_Localization_PL, ...localization };
    }, [t]
  );

  const columns = React.useMemo(
    () => prepareColumns(
      t('columnNames', { returnObjects: true }),
      columnOptions(t, classes, cardStatuses)
    ), [t, classes, cardStatuses]
  );

  function fetchStatusText() {
    if (fetchError) {
      return t('fetchError');
    }
    if (isFetching) {
      return t('isFetching');
    }
    if (finishedFetching) {
      return t('finishedFetching').replace('{totalFetched}', '' + tableData.length);
    }
    return t('currentlyFetched').replace('{totalFetched}', '' + tableData.length);
  }

  function navigateToCardDetails(bodyRowProps) {
    const cardId = bodyRowProps.row.original.cardId;
    const path = internalPaths.cardDetails.replace(':cardId', cardId);
    navigate(path);
  }

  const isLoading = tableData.length === 0 && isFetching;

  //TODO Po wprowadzeniu kodu twardego karta  widoczna jest w portalu przez 30 dni,
  // bez możliwości dokonywania jakichkolwiek zmian za pośrednictwem portalu.

  //MaterialReactTable screams that optional props are required
  function getMuiTableBodyRowProps(bodyRowProps) {
    if (isLoading)
      return bodyRowProps;
    return {
      ...bodyRowProps,
      hover:   true,
      classes: {
        root: classes.row,
      },
      onClick: () => navigateToCardDetails(bodyRowProps),
    };
  }

  // noinspection RequiredAttributes
  return (
    <MaterialReactTable
      columns={columns}
      data={tableData}
      localization={localization}
      enableStickyHeader={true}
      enableStickyFooter={true}
      enableTopToolbar={false}
      enablePagination={false}
      enableSorting={false}
      enableColumnActions={false}
      muiBottomToolbarProps={{
        className: classes.paginationStyles,
      }}
      muiTableBodyRowProps={getMuiTableBodyRowProps}
      muiTableContainerProps={{
        sx: { maxHeight: '50vh' }, //Half of the screen, so it doesn't generate scroll
        //Add an event listener to the table container element
        onScroll: (event) => fetchMoreOnBottomReached(event.target),
      }}
      renderBottomToolbarCustomActions={() => alert
        ? <Alert
            alert={alert}
            setAlert={setAlert}
            classes={{
              alertContainer: classes.alertContainer,
              paper: classes.alertPaper,
            }}
          />
        : <Typography className={classes.fetchingToolbar}>
            {fetchStatusText()}
          </Typography>
      }
      state={{
        isLoading:        isLoading,
        showProgressBars: isFetching,
      }}
    />
  );
}

const queryClient = new QueryClient();

export default function CardsTable(props) {
  return (
    <QueryClientProvider client={queryClient}>
      <CardsTableFn {...props}/>
    </QueryClientProvider>
  );
};
