import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme, alertType) => {
    let alertColor = alertType || 'warning';
    return {
      alertContainer:            {
        minHeight: 80,
        maxWidth:  700,
        margin:    '20px auto',
        paddingRight: 20,
        paddingLeft: 20,
        textAlign: 'center',
      },
      paper: {
        backgroundColor: theme.palette[alertColor].light,
        padding: theme.spacing(1)
      },
      typography: {
        color:     theme.palette[alertColor].contrastText,
        //whiteSpace: 'nowrap', //Text will pop outside of container
      },
      typographyFont: {
        fontWeight: theme.typography.fontWeightSemibold,
      },
      typographyFontSecondary: {
        fontWeight: theme.typography.fontWeightMedium,
      },
      alertContainerCloseButton: {
        color:    theme.palette[alertColor].contrastText
      },
      mainPaper:                 {
        minWidth: 'fit-content'
      },
      titlePaper:                {
        backgroundColor: theme.palette.secondaryBackgroundColor.main,
        paddingLeft:     theme.spacing(3),
        paddingRight:    theme.spacing(3),
        paddingTop:      theme.spacing(2),
        paddingBottom:   theme.spacing(2),
      }
    }
  }
);

export default useStyles;