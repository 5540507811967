import React                    from 'react';
import PropTypes                from 'prop-types';
import SuspendOrUnsuspendButton from './SuspendOrUnsuspendButton';
import DeleteTokenButton        from './DeleteTokenButton';
import Grid                     from '@mui/material/Grid';


function TokensTableRowActions(props) {

  return (
    <Grid container spacing={2} wrap='nowrap'>
      <Grid item>
        <DeleteTokenButton token={props.token}/>
      </Grid>
      <Grid item>
        <SuspendOrUnsuspendButton token={props.token}/>
      </Grid>
    </Grid>
  );
}

TokensTableRowActions.propTypes = {
  token: PropTypes.shape({
    tokenReferenceId: PropTypes.string.isRequired,
    tokenStatus:      PropTypes.string.isRequired,
  }).isRequired,
};

export default TokensTableRowActions;