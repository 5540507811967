import React from 'react';

import Authorization       from 'routing/Authorization';
import CenteredSpinner     from 'components/CenteredSpinner';
import GeneralLayout       from 'GeneralLayout';
import { Outlet as Pages } from 'react-router-dom';


function RootRoute() {

  return (
    <GeneralLayout>
      <Authorization>
        <React.Suspense fallback={<CenteredSpinner/>}>
          <Pages/>
        </React.Suspense>
      </Authorization>
    </GeneralLayout>
  );
}

export default RootRoute;