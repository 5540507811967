import useGET                  from 'rest/useGET';
import { FINTECH_PATHS }       from 'rest/const';
import createRestRequestConfig from 'rest/createRestRequestConfig';
import React                   from 'react';
import { AuthContext }         from 'App';
import dayjs                   from 'dayjs';
import { DAYJS_FORMATS }       from 'pages/CardDetailsPage/tabs/components/DateFields/MaskedDateField';
import { useParams }           from 'react-router-dom';


function getDefaultDateFilterData() {
  const defaultFrom = dayjs().subtract(1, 'month').format(DAYJS_FORMATS.DATE);
  const defaultTo   = dayjs().format(DAYJS_FORMATS.DATE);

  return {
    start_date: defaultFrom,
    end_date:   defaultTo,
  };
}


export default function useMerchantOptions() {

  const { userInfo } = React.useContext(AuthContext);
  const urlParams    = useParams();
  const cardId       = urlParams.cardId;

  const axiosCfg = createRestRequestConfig(userInfo.accessToken, true);

  axiosCfg.params = getDefaultDateFilterData();

  const swrOptions = {
    revalidateIfStale:     false,
    revalidateOnFocus:     false,
    revalidateOnReconnect: false,
  };

  const path         = FINTECH_PATHS.merchants.replace('{cardId}', cardId);
  const merchantsGET = useGET(path, axiosCfg, swrOptions);
  const response     = merchantsGET.data;
  const merchants    = response?.data;
  const error        = merchantsGET.error;


  const merchantOptions = React.useMemo(() => {
    if (!merchants || !Array.isArray(merchants)) {
      return undefined;
    }
    return merchants.map(merchant => ({
      label: merchant.merchantName,
      value: merchant,
    }));
  }, [merchants]);

  React.useEffect(() => {
    if (error) {
      console.error('Error while fetching merchants', error);
    }
  }, [error]);


  return {
    merchantOptions,
    ...merchantsGET,
  };
}