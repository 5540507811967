import React              from 'react';
import useGET             from 'rest/useGET';
import useStyles          from './downloadableFileOrAlertStyles';
import { useTranslation } from 'react-i18next';

import Alert  from 'components/Alert';
import Button from '@mui/material/Button';
import Grid   from '@mui/material/Grid';

import createRestRequestConfig from 'rest/createRestRequestConfig';
import fileDownload            from 'js-file-download';
import isBlank                 from 'utils/isBlank';

import { AuthContext }   from 'App';
import { FINTECH_PATHS } from 'rest/const';
import PropTypes         from 'prop-types';
import { useSnackbar }   from 'notistack';


const NOT_FOUND_OR_UNSUPPORTED_MSG = "Document doesn't exist or is unsupported";
const FILE_TYPE_PLACEHOLDER = '{fileType}';

function DownloadableFileOrAlert(props) {

  const { t }               = useTranslation('common');
  const { classes }         = useStyles();
  const { userInfo }        = React.useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();

  const [fileInfoAlertClosed, setFileInfoAlertClosed] = React.useState(false);
  const [fileDownloadError, setFileDownloadError]     = React.useState(undefined);
  const [downloadPath, setDownloadPath]               = React.useState(undefined);

  const axiosCfg         = createRestRequestConfig(userInfo.accessToken, true);
  const axiosDownloadCfg = {
    ...axiosCfg,
    responseType: 'blob',
    headers:      {
      ...axiosCfg.headers,
      'accept': '*/*',
    },
  };

  const swrDownloadOpts = {
    onSuccess: res => {
      setDownloadPath(null);
      fileDownload(res.data, props.fileInfo.fileName);
      enqueueSnackbar(t('common:fileDownloadSuccess'), { variant: 'success' });
    },
    onError:   err => {
      setDownloadPath(null);
      setFileDownloadError(err);
      console.error("Error while downloading file " + props.fileInfo.fileName, err);
    },
  };

  useGET(downloadPath, axiosDownloadCfg, swrDownloadOpts);

  function triggerFileDownload() {
    const path = FINTECH_PATHS.getDocument.replace('{documentId}', props.fileInfo.documentId);
    setDownloadPath(path);
  }

  if (fileInfoAlertClosed) {
    return null;
  }

  const alertClasses = {
    alertContainer: classes.alertContainer,
    paper: classes.alertPaper,
  };

  const fileTypeTranslation = t(props.fileType + '_FileType');
  const alertMsgKeyForUnsupportedOrNotFound =
          t('doesntExistOrUnsupported').replace(FILE_TYPE_PLACEHOLDER, fileTypeTranslation);
  const alertMsgKeyForAnyOtherError =
          t('downloadFileInfoError').replace(FILE_TYPE_PLACEHOLDER, fileTypeTranslation);

  const fileInfoErrorMessage = props.restError?.response?.data?.message || props.restError?.message;

  function handleError() {
    if (fileDownloadError) {
      return (
        <Alert
          alert={{
            msgTranslKey: 'fileDownloadError',
            type:         'error',
          }}
          setAlert={() => setFileDownloadError(undefined)}
          classes={alertClasses}
        />
      );
    }

    let alert = {
      msgTranslKey: alertMsgKeyForAnyOtherError,
      type:         'error',
    };

    if (fileInfoErrorMessage === NOT_FOUND_OR_UNSUPPORTED_MSG) {
      alert = {
        msgTranslKey: alertMsgKeyForUnsupportedOrNotFound,
        type:         'info',
      };
    }
    return (
      <Alert
        alert={alert}
        setAlert={() => setFileInfoAlertClosed(true)}
        classes={alertClasses}
      />
    );
  }

  if (!isBlank(fileInfoErrorMessage) || fileDownloadError) {
    return handleError();
  }

  const buttonText = t('downloadFile') + ' ' + props.fileInfo.fileName;

  return (
    <Grid container marginBottom={2} alignItems='center'>
      <Grid item>
        <Button onClick={triggerFileDownload} className={classes.button}>
          {buttonText}
        </Button>
      </Grid>
    </Grid>
  );
}

DownloadableFileOrAlert.propTypes = {
  fileInfo:  PropTypes.shape({
    fileName: PropTypes.string.isRequired, documentId: PropTypes.string.isRequired,
  }),
  restError: PropTypes.object,
  fileType:  PropTypes.string,
};

export default DownloadableFileOrAlert;
