import React from 'react';

import PropTypes          from 'prop-types';
import Grid               from '@mui/material/Grid';
import Typography         from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';


function EmbossInfo(props) {

  const { t } = useTranslation('cardDetails');

  return (
    <>
      <Grid container item xs={12} spacing={1}>
        <Grid item>
          <Typography>
            {t('cardDetails:cardNumberLabel')}
          </Typography>
        </Grid>
        <Grid item>
          <Typography fontWeight='semibold'>
            {props.embossInfo.cardNumber}
          </Typography>
        </Grid>
      </Grid>

      <Grid container item xs={12} spacing={1}>
        <Grid item>
          <Typography>
            {t('cardDetails:cvv2Label')}
          </Typography>
        </Grid>
        <Grid item>
          <Typography fontWeight='semibold'>
            {props.embossInfo.cvv2}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

EmbossInfo.propTypes = {
  embossInfo: PropTypes.shape({
    cardNumber: PropTypes.string.isRequired,
    cvv2: PropTypes.string.isRequired,
  }),
};

export default EmbossInfo;