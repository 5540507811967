import React                            from 'react';
import Button                           from '@mui/material/Button';
import { useTranslation }               from 'react-i18next';
import useCurrentAndAvailable3DSMethods from '../useCurrentAndAvailable3DSMethods';
import Change3DSMethodDialog            from './Change3DSMethodDialog';


function Change3DSMethod() {

  const { t }                               = useTranslation('cardDetails');
  const { availableAuthMethods, isLoading } = useCurrentAndAvailable3DSMethods();

  const [dialogOpen, setDialogOpen] = React.useState(false);

  let buttonText = t('change3DSMethodButton');

  const noAlternativeMethods = !availableAuthMethods || availableAuthMethods.length < 2;
  if (noAlternativeMethods) {
    buttonText = t('noAlternativeMethods');
  }

  if (isLoading) {
    buttonText = t('loadingAlternativeMethods');
  }

  return (
    <>
      {dialogOpen &&
        <Change3DSMethodDialog closeDialog={() => setDialogOpen(false)}/>
      }

      <Button
        onClick={() => setDialogOpen(true)}
        disabled={isLoading || noAlternativeMethods}
      >
        {buttonText}
      </Button>
    </>
  );
}

export default Change3DSMethod;