import React           from 'react';
import { useLocation } from 'react-router-dom';

import isBlank    from 'utils/isBlank';
import jwt_decode from 'jwt-decode';

import { ALMOST_EXPIRED }            from 'utils/setAutoRefreshSession';
import { getSecondsUntilExpiration } from 'utils/getSecondsUntilExpiration';
import { getUsernameFromToken }      from 'utils/getUsernameFromToken';

export const TOKEN_SEARCH_PARAM = 'token';


export default function useURL(onError) {

  const errorEncounteredRef = React.useRef(undefined);

  const location = useLocation();

  if (errorEncounteredRef.current)
    return errorResult(errorEncounteredRef.current);

  try {
    const search       = location.search;
    const searchParams = new URLSearchParams(search);
    const token        = searchParams.get(TOKEN_SEARCH_PARAM);

    const tokenPayload = jwt_decode(token);
    const email        = getUsernameFromToken(tokenPayload);

    if (isBlank(email)) {
      const errMessage = "Token's payload doesn't contain username";
      console.error(errMessage)
      errorEncounteredRef.current = new Error(errMessage);
      onErrorHandler(onError);
    }

    return {
      token: token,
      email: email,
      hasTokenExpired: getSecondsUntilExpiration(tokenPayload) <= ALMOST_EXPIRED,
    };

  } catch (err) {
    console.error("ExtractTokenFromURL, URL error: ", err);
    errorEncounteredRef.current = err;
    onErrorHandler(onError);
    return errorResult(errorEncounteredRef.current);
  }
}

function onErrorHandler(onError) {
  if (typeof onError !== 'function') {
    return;
  }
  //Delay the call just in case someone called useURL before all useState's setters in onError were initialized
  setTimeout(() => onError(), 0);
}

function errorResult(err) {
  return {
    error:      err,
    errMessage: err.message,
  };
}
