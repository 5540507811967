import React from 'react';

import PropTypes                   from 'prop-types';
import DialogTitle                 from '@mui/material/DialogTitle';
import BlockCardConfirmationButton from './BlockCardConfirmationButton';
import Alert                       from 'components/Alert';
import Dialog                      from '@mui/material/Dialog';
import DialogContent               from '@mui/material/DialogContent';
import useStyles                   from '../confirmationDialogsStyles';


/**
 * A dialog which asks for confirmation, but doesn't require OTP.
 */
export default function BlockCardConfirmationDialog(props) {

  const { classes } = useStyles();

  //BlockCardConfirmationButton contains the confirmation text
  return (
    <Dialog
      onClose={props.onClose}
      open={true}
    >
      <DialogTitle>
        {props.dialogTitle}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {!props.alert &&
          <BlockCardConfirmationButton onConfirmation={props.onConfirmation}/>
        }

        {props.alert &&
          <Alert
            alert={props.alert}
            setAlert={props.closeAlert}
          />
        }
      </DialogContent>
    </Dialog>
  );
}

BlockCardConfirmationDialog.propTypes = {
  dialogTitle: PropTypes.node,

  onConfirmation: PropTypes.func,
  onClose:        PropTypes.func,

  alert:      PropTypes.shape({
    msgTranslKey:          PropTypes.string.isRequired,
    msgTranslSecondaryKey: PropTypes.string,
    type:                  PropTypes.string,
  }),
  closeAlert: PropTypes.func,
};
