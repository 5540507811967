import React from 'react';

import BaseErrorBoundary from "../BaseErrorBoundary";


class MostGenericErrorBoundary extends BaseErrorBoundary {
  render() {
    if (this.state.hasError) {
      return (
        <div style={{
          WebkitBoxSizing: "border-box",
          MozBoxSizing: "border-box",
          boxSizing: "border-box",
          display: "flex",
          flexFlow: "column wrap",
          alignItems: "center",
        }}
        >
          <h4>Coś poszło bardzo poważnie nie tak :( Przepraszamy.</h4>
        </div>
      );
    }

    return this.props.children;
  }
}

export default MostGenericErrorBoundary;
