import { makeStyles } from 'tss-react/mui';


const useStyles = makeStyles()(theme => ({
  button:         {
    textTransform: 'none',

    padding: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  alertContainer: {
    width:        'fit-content',
    maxWidth:     '100%',
    minHeight:    'unset',

    margin:       0,
    marginBottom: theme.spacing(2),
    paddingRight: 0,
    paddingLeft:  0,
  },
  alertPaper: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

export default useStyles;
