import axios from 'axios';

import { AUTHORIZATION_HEADER_NAME } from 'rest/const';
import { AXIOS_BASE_CONFIG }         from 'rest/const';


export default function createRestRequestConfig(authToken, remoteApiDirectly) {
  if (authToken == null) {
    console.warn('There was attempt to create Rest Request Config, but token was null');
    return null;
  }

  const headers = {...AXIOS_BASE_CONFIG.headers};
  addAuthorizationHeader(headers, authToken);

  const config = {
    ...AXIOS_BASE_CONFIG,
    headers: headers,
  };

  if (remoteApiDirectly) {
    config.withCredentials = false; //Don't send cookies to fintech
    //Object 'axios.defaults' holds URL of remote api
    //Don't delete 'baseURL' property in case someone flat copies this config with other cfg which has 'baseURL'
    config.baseURL = axios.defaults.baseURL;
  }

  return config;
}

export function addAuthorizationHeader(headers, authToken) {
  headers[AUTHORIZATION_HEADER_NAME] = `Bearer ${authToken}`;
}
