import React from 'react';

import PropTypes               from 'prop-types';
import TextField               from '@mui/material/TextField';
import OTPAdornment            from 'components/OTPAdornment';
import { useTranslation }      from 'react-i18next';
import Button                  from '@mui/material/Button';
import Grid                    from '@mui/material/Grid';
import useStyles               from './otpFieldStyles';
import { GRID_SPACING }        from '../detailsAndOperationsTabConst';
import { GRID_MARGIN_BOTTOM }  from '../detailsAndOperationsTabConst';
import { GRID_MARGIN_TOP }     from '../detailsAndOperationsTabConst';
import { preventNumberChange } from 'utils/preventNumberChange';
import isBlank                 from 'utils/isBlank';


export const OTP_FIELD_NAME = 'otp';

/**
 * This component has two modes of submitting: either by passing
 * onSubmit handler or by wrapping a <form> tag around it.
 */
function OtpField(props) {

  const { t }       = useTranslation();
  const { classes } = useStyles();

  const [value, setValue] = React.useState('');

  const otpFieldRef = React.useRef();

  function onChange(evt) {
    otpFieldRef.current.setCustomValidity('');  //reset error message
    setValue(evt.target.value);
  }

  let validationMessage = otpFieldRef.current?.validationMessage;
  if (!props.showValidityMessages)
    validationMessage = null;

  //internal grid aligns bottoms of the text field and the button
  // while external grid hold them both in the center of space
  return (
    <Grid
      container
      alignItems='center'
      marginTop={GRID_MARGIN_TOP}
      marginBottom={GRID_MARGIN_BOTTOM}
    >
      <Grid
        container
        spacing={GRID_SPACING}
        item
        alignItems='center'
      >
        <Grid item>
          <TextField
            required
            label={t('otpFieldLabel')}
            type='number'
            name={OTP_FIELD_NAME}
            value={value}
            onChange={onChange}
            onWheel={preventNumberChange}
            error={!isBlank(validationMessage)}
            helperText={validationMessage || ' '} //space preserve field height
            autoComplete='one-time-code'
            className={classes.field}
            inputRef={otpFieldRef}
            inputProps={{
              min:                 0,
              hidenumberadornment: 'true', //Firefox
            }}
            InputProps={{
              hidenumberadornment: 'true', //Chrome, Edge, Opera, Safari
              endAdornment: (
                <OTPAdornment resend={props.resend} disabled={props.disableResend}/>
              ),
            }}
          />
        </Grid>

        <Grid item>
          <Button
            type='submit'
            onClick={() => props.onSubmit?.(value)}
            disabled={props.disableSubmit}
          >
            {t('submitOtpBtn')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

OtpField.propTypes = {
  onSubmit:             PropTypes.func,
  resend:               PropTypes.func.isRequired,
  disableResend:        PropTypes.bool,
  disableSubmit:        PropTypes.bool,
  showValidityMessages: PropTypes.bool,
};

export default OtpField;
