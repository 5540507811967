import React                       from 'react';
import { useContext }              from 'react';
import { BACKEND_PATHS }           from 'rest/const';
import useGET                      from 'rest/useGET';
import { getUsernameFromToken }    from 'utils/getUsernameFromToken';
import { AuthContext }             from 'App';
import { checkIfItsTheAllowedTab } from 'utils/browserTabsSessionUtils';


export default function useAutologin() {

  const { setUserInfo } = useContext(AuthContext);

  const [autoLoginPath, setAutoLoginPath] = React.useState(undefined);

  React.useEffect(() => {
    try {
      checkIfItsTheAllowedTab();
      setAutoLoginPath(BACKEND_PATHS.autoLogin);
    } catch (ignored) {
      // It won't set autoLoginPath if any error is thrown
    }
  }, []);

  useGET(autoLoginPath, undefined, {
    onError:   () => setAutoLoginPath(null), //null path = don't retry
    onSuccess: (res) => {
      setAutoLoginPath(null); //null path = don't retry
      if (!res.data) {
        return;
      }
      setUserInfo({
        ...res.data,
        username: getUsernameFromToken(res.data.accessToken),
      });
    },
  });
}