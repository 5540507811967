import React              from 'react';
import { useTranslation } from 'react-i18next';

import Typography     from '@mui/material/Typography';


function NotFoundError() {

  const { t } = useTranslation();

  return (
    <>
      <Typography variant='h5'>{t('notFoundHeader')}</Typography>
      <Typography variant='h6'>{t('makeSureUrlCorrect')}</Typography>
    </>
  );
}

export default NotFoundError;