import React from 'react';

/**
 * This class is supposed to be extended and render function is
 * supposed to be overridden.
 */
export default class BaseErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError() {
    return {hasError: true};
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
  }

  render() {
    return this.props.children;
  }
}
