import React                      from 'react';
import createRestRequestConfig    from 'rest/createRestRequestConfig';
import { addAuthorizationHeader } from 'rest/createRestRequestConfig';
import { commonOTPErrorHandled }  from 'rest/const';
import { FINTECH_PATHS }          from 'rest/const';
import { useTranslation }         from 'react-i18next';
import isBlank                    from 'utils/isBlank';
import { AuthContext }            from 'App';
import encryptionWrapper          from 'utils/encryptionWrapper';
import getUrlAndPubKeyFromToken   from './getUrlAndPubKeyFromToken';
import { OTP_FIELD_NAME }         from 'pages/CardDetailsPage/tabs/DetailsAndOperationsTab/OTPField/OTPField';
import useREST                    from 'rest/useREST';


/**
 * A marker to indicate that the error was handler by `changePin`
 * function.
 * @type {string}
 */
const HANDLED_ERROR = "handled error";

export default function usePinChange(cardId, expDate, onSuccess, onError, formRef) {

  const { t }        = useTranslation(['common', 'cardDetails']);
  const { userInfo } = React.useContext(AuthContext);

  const [isInProgress, setIsInProgress] = React.useState(false);

  function onGetJwtError(err) {
    setIsInProgress(false);
    const otpErrorHandler = (errKey) => {
      formRef.current.elements[OTP_FIELD_NAME].setCustomValidity(t(`common:${errKey}`));
      if (errKey === 'tooManyOtpAttempts')
        onError?.(err); //setting error message on the field isn't enough, retrying won't help
    };
    if (commonOTPErrorHandled(err, otpErrorHandler)) {
      return;
    }
    console.error("Error while sending OTP code: ", err);
    onError?.(err);
  }

  function onMiddlewareRequestError(err) {
    console.error("Error while requesting PIN change in middleware: ", err);
    setIsInProgress(false);
    onError?.(err);
  }

  /**
   * Most general error handler, for anything uncaught by
   * onGetJwtError or onMiddlewareRequestError.
   */
  function onPinChangeError(err) {
    setIsInProgress(false);
    if (err === HANDLED_ERROR)
      return;
    console.error("Error while changing PIN: ", err);
    onError?.(err);
  }

  const rest = useREST();

  async function changePin(otpCode, pin) {
    setIsInProgress(true);

    const jwtGeneratorPath = FINTECH_PATHS.changePinJwtGenerator.replace('{cardId}', cardId);
    const otpBody          = {
      otp: otpCode,
    };
    const jwtGenAxiosCfg   = createRestRequestConfig(userInfo.accessToken, true);

    let jwtGenResp;
    try {
      jwtGenResp = await rest([jwtGeneratorPath, 'post', otpBody, jwtGenAxiosCfg]);
    } catch (err) {
      onGetJwtError(err);
      throw HANDLED_ERROR;
    }

    const middlewareAccessTokens = jwtGenResp.data.accessTokens;
    const { pubKey, url }        = getUrlAndPubKeyFromToken(middlewareAccessTokens.CHANGE_PIN);
    const encryptedPin           = encryptionWrapper(pin, pubKey);

    //not setting baseURL since absolute URL was provided in the
    // token payload and then passed to axios as path which will cause
    // baseURL to be ignored
    const middlewareAxiosCfg = { ...jwtGenAxiosCfg, baseURL: undefined };
    addAuthorizationHeader(middlewareAxiosCfg.headers, middlewareAccessTokens.CHANGE_PIN);

    const changePinBody = {
      pin:     encryptedPin,
      expDate: expDate,
    };

    try {
      await rest([url, 'post', changePinBody, middlewareAxiosCfg]);
    } catch (err) {
      onMiddlewareRequestError(err);
      throw HANDLED_ERROR;
    }
    setIsInProgress(false);
  }

  function trigger(otpCode, pin) {
    if (isBlank(otpCode))
      throw new Error("usePinEncryption trigger error: otpCode is blank");
    if (isBlank(pin))
      throw new Error("usePinEncryption trigger error: pin is blank");

    changePin(otpCode, pin)
      .then(() => onSuccess?.())
      .catch(onPinChangeError);
  }

  return { trigger, isInProgress }
}
