import AppSelectionPage   from 'pages/AppSelectionPage';
import CardDetailsPage    from 'pages/CardDetailsPage';
import DownloadsPage      from 'pages/DownloadsPage';
import ErrorPage          from 'pages/ErrorPage';
import ForgotPasswordPage from 'pages/ForgotPasswordPage';
import LoginPage          from 'pages/LoginPage';
import LogoutPage         from 'pages/LogoutPage';
import MyCardsPage        from 'pages/MyCardsPage';
import RegisterPage       from 'pages/RegisterPage';
import RegisterOTPPage    from 'pages/RegisterOTPPage';
import ResetPasswordPage  from 'pages/ResetPasswordPage';
import RootRoute          from 'routing/RootRoute';
import UserDataPage       from 'pages/UserDataPage';

import { createBrowserRouter } from 'react-router-dom';

import internalPaths from './internalPaths';


//Don't pass 'FINTECH_PATHS.pathKey' to the Components there, router is loaded before 'FINTECH_PATHS'
//Pass the 'pathKey' to Component and Component will get actual value of path
const router = createBrowserRouter([
  {
    path:         "/",
    element:      <RootRoute/>,
    errorElement: <ErrorPage/>,
    children:     [
      {
        path:    internalPaths.appSelection,
        element: <AppSelectionPage/>,
      },
      {
        path:    internalPaths.login,
        element: <LoginPage/>,
      },
      {
        path:    internalPaths.forgotPassword,
        element: <ForgotPasswordPage/>,
      },
      {
        path:    internalPaths.resetPassword,
        element: <ResetPasswordPage
          callOtpPathKey='resetPasswordCallOTP'
          otpType='EMAIL'
          resetPasswordPathKey='resetPassword'
        />,
      },
      {
        path:    internalPaths.changePassword,
        element: <ResetPasswordPage
          callOtpPathKey='changePasswordCallOTP'
          otpType='OTP_CHANGE_PASSWORD'
          resetPasswordPathKey='changePassword'
          includeCurrentPassword={true}
          otpOutsideCredentials={true}
        />,
      },
      {
        path:    internalPaths.myCards,
        element: <MyCardsPage/>,
      },
      {
        path:    internalPaths.cardDetails,
        element: <CardDetailsPage/>,
      },
      {
        path:    internalPaths.downloads,
        element: <DownloadsPage/>,
      },
      {
        path:    internalPaths.userData,
        element: <UserDataPage/>,
      },
      {
        path:    internalPaths.logout,
        element: <LogoutPage/>,
      },
      {
        path:    internalPaths.register,
        element: <RegisterPage/>,
      },
      {
        path:    internalPaths.registerOTP,
        element: <RegisterOTPPage/>,
      },
    ],
  },

]);

export default router;