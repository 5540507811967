import React                from 'react';
import useMerchantWhitelist from '../useMerchantWhitelist';
import { useTranslation }   from 'react-i18next';
import Typography           from '@mui/material/Typography';
import useStyles            from './merchantWhitelistBottomToolbarStyles';


function MerchantWhitelistBottomToolbar() {

  const { t }                        = useTranslation(['common', 'cardDetails']);
  const { merchantWhitelist, error } = useMerchantWhitelist();

  let text  = t('common:fetchError')
  let color = 'error'

  if (merchantWhitelist) {  //data present, but might be outdated due to a fetch error
    text  = t('cardDetails:dataMightBeOutdated')
    color = 'warning';
  }

  const { classes } = useStyles(color);

  if (!error) {
    return null;
  }

  return (
    <Typography className={classes.typography}>
      {text}
    </Typography>
  );
}

export default MerchantWhitelistBottomToolbar;