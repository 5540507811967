import { getSecondsUntilExpiration }   from 'utils/getSecondsUntilExpiration';
import { createRefreshSessionPromise } from 'rest/sendRequest';

/**
 * Refresh session before it expires in order to avoid delays
 */
export const EARLY_REFRESH = 30;
/**
 * Token expires very soon
 */
export const ALMOST_EXPIRED = 5;


/**
 * Automatically refreshes session before it expires
 */
export function setAutoRefreshSession(userInfo, setUserInfo, setRefreshTimeoutId) {
  if (userInfo.refreshPromise) {
    return; //It's already in progress
  }
  const secondsUntilExpires = getSecondsUntilExpiration(userInfo.accessToken);
  if (secondsUntilExpires <= 0) { //Already expired, skip refresh procedure
    return;
  }

  const refreshSessionHandler = createSimpleRefreshSessionPromiseHandler(userInfo, setUserInfo);

  if (secondsUntilExpires <= EARLY_REFRESH) { //Don't need setTimeout(), we refresh right now
    const refreshPromise = createRefreshSessionPromise(userInfo.refreshToken);
    if (secondsUntilExpires <= ALMOST_EXPIRED) { //Very close to expiration
      userInfo.refreshPromise = refreshPromise; //This will prevent sending other request before token is refreshed
    }
    refreshPromise.then(refreshSessionHandler);
    return;
  }

  const timeoutInSeconds      = secondsUntilExpires - EARLY_REFRESH;
  const timeoutInMilliseconds = timeoutInSeconds * 1000;

  const timeoutId = setTimeout(
    () => {
      if (userInfo.refreshPromise) {
        console.warn('Session refresh is active before token expired?');
        return;
      }
      createRefreshSessionPromise(userInfo.refreshToken)
        .then(refreshSessionHandler);
    },
    timeoutInMilliseconds
  );

  setRefreshTimeoutId(timeoutId);
}

/**
 * <pre>
 * It will set new userInfo once response returns
 * Called 'simple' to differentiate it from handler in sendRequest
 * </pre>
 * @returns {function(response)} function used in .then()
 */
function createSimpleRefreshSessionPromiseHandler(userInfo, setUserInfo) {
  //Returns function used in .then()
  return (res) => {
    setUserInfo({
      ...userInfo, //Keep username or other values
      ...res.data, //Replace expired tokens with new tokens
      refreshPromise:     null, //Do not keep the original promise
      fromRefreshPromise: true, //Will trigger hook to updateCookies
    });
  };
}