import React              from 'react';
import { useNavigate }    from 'react-router-dom';
import useStyles          from './appSelectionPageStyles';
import { useTranslation } from 'react-i18next';

import Container from '@mui/material/Container';

import classnames           from 'classnames';
import { FINTECH_PATHS }    from 'rest/const';
import INTERNAL_PATHS       from 'routing/internalPaths';
import { PREPAID_PATH_KEY } from 'App';


function AppSelectionPage() {

  const navigate    = useNavigate();
  const { t }       = useTranslation();
  const { classes } = useStyles();

  return (
    <Container className={classes.rootContainer}>
      <AppSelectionCard
        onClick={() => window.open(FINTECH_PATHS[PREPAID_PATH_KEY], '_self')}
        floatClassName={classes.left}
        title={t('appSelectionPrepaidWWWTitle')}
        subTitle={t('appSelectionPrepaidWWWSubTitle')}
      />
      <AppSelectionCard
        onClick={() => navigate(INTERNAL_PATHS.login)}
        floatClassName={classes.right}
        title={t('appSelectionFintechWWWTitle')}
      />
    </Container>
  );
}

function AppSelectionCard({ title, subTitle, floatClassName, onClick }) {
  const { classes } = useStyles();

  return (
    <span
      onClick={onClick}
      className={classnames(classes.appSelectionCard, floatClassName)}
    >
      <div className={classes.titlesContainer}>
        <div className={classes.title}>
          {title}
        </div>
        { subTitle &&
          <div className={classes.subTitle}>
            {subTitle}
          </div>
        }
      </div>
    </span>
  );
}

export default AppSelectionPage;