import reformatDate from './reformatDate';

/**
 * <pre>
 * Function that reformats date to string format "YYYY-MM-DD HH:MM:SS"
 * If string is given then converts it to Date and then reformats
 * </pre>
 *
 * @param date {Object | String} - value to reformat
 * @param isUTC {Boolean =} - answer question: "Do you want to display this date in timezone +0?"
 * @param defaultValue {String | JSX.Element =} - value to return instead of null
 * @returns {String | JSX.Element | null}
 */
export default function reformatDateTime(date, isUTC, defaultValue) {
  defaultValue = defaultValue != null ? defaultValue : null;
  if (!date) {
    return defaultValue;
  }

  if (!(date instanceof Date)) {
    date = new Date(date);
  }
  if (isNaN(date)) {
    return defaultValue;
  }

  let timeLocale;
  if (isUTC) {
    timeLocale = date.toISOString().replace("T", " ");
    timeLocale = timeLocale.slice(0, -5);
    return timeLocale;
  } else {
    timeLocale = date.toLocaleTimeString("pl-PL");
  }

  return reformatDate(date, isUTC, defaultValue) + " " + timeLocale;
}