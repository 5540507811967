import React from 'react';

import Grid    from '@mui/material/Grid';
import Spinner from './Spinner';


export default function CenteredSpinner() {
  return (
    <Grid container justifyContent="center" paddingTop={4} paddingBottom={4}>
      <Grid item>
        <Spinner/>
      </Grid>
    </Grid>
  );
}