const INTERNAL_PATHS = {
  appSelection:   '/',
  login:          '/login',
  forgotPassword: '/forgot-password',
  resetPassword:  '/reset-password', //This one is accessed through URL they have got in mail
  changePassword: '/change-password',
  myCards:        '/cards',
  cardDetails:    '/cards/:cardId',
  userData:       '/user-data',
  downloads:      '/dlc',
  logout:         '/logout',
  register:       '/register',
  registerOTP:    '/register-otp', //This one is accessed through URL they have got in mail
};
export default INTERNAL_PATHS;

/**
 * Paths without authorization. Tn other words: paths which don't
 * require the user to be logged in.
 */
const PATHS_WO_AUTHORIZATION = [
  INTERNAL_PATHS.appSelection,
  INTERNAL_PATHS.login,
  INTERNAL_PATHS.forgotPassword,
  INTERNAL_PATHS.resetPassword,
  INTERNAL_PATHS.logout,
  INTERNAL_PATHS.register,
  INTERNAL_PATHS.registerOTP,
];
export { PATHS_WO_AUTHORIZATION }
