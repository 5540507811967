import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  label: {
    fontSize: '1.25rem',
    paddingRight: theme.spacing(1),
    float: 'left',
  },
  valueOrErrorText: {
    fontSize: '1.25rem',
  },
}));

export default useStyles;
