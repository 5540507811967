import React              from 'react';
import { useTranslation } from 'react-i18next';

import Cached         from '@mui/icons-material/Cached';
import IconButton     from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import PropTypes      from 'prop-types';
import Tooltip        from '@mui/material/Tooltip';


function OtpAdornment(props) {

  const { t } = useTranslation();

  return (
    <InputAdornment position='end'>
      <Tooltip title={t('sendOtpAgain')}>
        <IconButton onClick={props.resend} disabled={props.disabled}>
          <Cached/>
        </IconButton>
      </Tooltip>
    </InputAdornment>
  );
}

OtpAdornment.propTypes = {
  resend: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default OtpAdornment;