import React                         from 'react';
import { useTranslation }            from 'react-i18next';
import prepareColumns                from 'pages/MyCardsPage/CardsTable/prepareColumns';
import InfiniteScrollTable           from 'components/InfiniteScrollTable';
import TokensTableRowActions         from './TokensTableRowActions';
import { FINTECH_PATHS }             from 'rest/const';
import { useParams }                 from 'react-router-dom';
import { ACTIVE_TOKEN_STATUS }       from '../mobilePaymetsTabConst';
import { SUSPEND_TOKEN_STATUS }      from '../mobilePaymetsTabConst';
import { SUSPENDED_TOKEN_STATUS }    from '../mobilePaymetsTabConst';
import { INACTIVE_TOKEN_STATUS }     from '../mobilePaymetsTabConst';
import { APPROVED_TOKEN_STATUS }     from '../mobilePaymetsTabConst';
import { PASSCODESENT_TOKEN_STATUS } from '../mobilePaymetsTabConst';
import { DELETED_TOKEN_STATUS }      from '../mobilePaymetsTabConst';
import { DEACTIVATE_TOKEN_STATUS }   from '../mobilePaymetsTabConst';
import { DEACTIVATED_TOKEN_STATUS }  from '../mobilePaymetsTabConst';
import { DECLINED_TOKEN_STATUS }     from '../mobilePaymetsTabConst';
import { SWRConfig }                 from 'swr';
import useREST                       from 'rest/useREST';
import PropTypes                     from 'prop-types';


const STATUSES_TO_REMOVE = [
  DELETED_TOKEN_STATUS,
  DEACTIVATE_TOKEN_STATUS,
  DEACTIVATED_TOKEN_STATUS,
  DECLINED_TOKEN_STATUS,
];

function TokensTable(props) {

  const urlParams = useParams();
  const cardId    = urlParams.cardId;
  const { t }     = useTranslation(['common', 'cardDetails']);

  const columns = React.useMemo(() => {
    const options = {
      tokenStatus: {
        Cell: ({ cell }) => {
          const status = cell.getValue();
          // translation done with switch-case instead of string
          // concatenation for the sake of plugins helping with
          // handling of the translation files
          switch (status) {
            case ACTIVE_TOKEN_STATUS:
              return t('cardDetails:ACTIVE')
            case SUSPEND_TOKEN_STATUS:
              return t('cardDetails:SUSPEND')
            case SUSPENDED_TOKEN_STATUS:
              return t('cardDetails:SUSPENDED')
            case INACTIVE_TOKEN_STATUS:
              return t('cardDetails:INACTIVE')
            case APPROVED_TOKEN_STATUS:
              return t('cardDetails:APPROVED')
            case PASSCODESENT_TOKEN_STATUS:
              return t('cardDetails:PASSCODESENT')
            case DELETED_TOKEN_STATUS:
              return t('cardDetails:DELETED')
            case DEACTIVATE_TOKEN_STATUS:
              return t('cardDetails:DEACTIVATE')
            case DEACTIVATED_TOKEN_STATUS:
              return t('cardDetails:DEACTIVATED')
            case DECLINED_TOKEN_STATUS:
              return t('cardDetails:DECLINED')
            default:
              return status;
          }
        },
      },
    };

    return prepareColumns(
      t('cardDetails:tokenTableColumnNames', { returnObjects: true }),
      options,
    );
  }, [t]);

  const fetcher = useREST(false);

  const fetcherWithFilter = React.useCallback(async key => {
    const response = await fetcher(key);
    let data       = response?.data;

    if (Array.isArray(data)) {
      data = data.filter(token => !STATUSES_TO_REMOVE.includes(token.tokenStatus));
    }

    return {
      ...response,
      data,
    }
  }, [fetcher]);

  return (
    <SWRConfig
      value={parentConfig => ({
        ...parentConfig,
        fetcher: fetcherWithFilter,
      })}
    >
      <InfiniteScrollTable
        pathFormat={FINTECH_PATHS.cardTokens.replace('{cardId}', cardId)}
        columns={columns}
        getDataFromResponse={response => response?.data}
        //MRT uses row number as row id by default which is okay until you delete a row
        // which is not the last row - in that case state leaks to following component
        getRowId={originalRow => originalRow.tokenReferenceId}

        enableRowActions={true}
        positionActionsColumn='last'
        renderRowActions={({ row }) => <TokensTableRowActions token={row.original}/>}
        mutateRef={props.mutateRef}
      />
    </SWRConfig>
  );
}

TokensTable.propTypes = {
  mutateRef: PropTypes.object,
};

export default React.memo(TokensTable);