import React                          from 'react';
import PropTypes                      from 'prop-types';
import Typography                     from '@mui/material/Typography';
import useStyles                      from './amountWithCurrencyStyles';


function AmountWithCurrency(props) {

  const { classes } = useStyles();

  const className = props.isDebit ? classes.debit : classes.credit;

  const parsedAmount = React.useMemo(() => {
    const AMOUNT_NUMBER_OPTIONS = {
      minimumFractionDigits: 2,
    };

    const value = props.amount;
    let number  = parseFloat(value);
    if (isNaN(number)) {
      console.warn("Billing amount is not a number ", value);
      return value;
    }

    number = Math.abs(number)
    if (props.isDebit) {
      //Convert to negative number
      number = -number;
    }
    return number.toLocaleString(undefined, AMOUNT_NUMBER_OPTIONS);
  }, [props.amount, props.isDebit]);

  const text = [parsedAmount, props.currency].join(' ');

  return (
    <Typography className={className}>
      {text}
    </Typography>
  );
}

AmountWithCurrency.propTypes = {
  amount:   PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  currency: PropTypes.string,
  isDebit:  PropTypes.bool,
};

export default AmountWithCurrency;