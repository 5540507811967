import React from 'react';

import AppBar    from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Footer    from '../Footer';
import Grid      from '@mui/material/Grid';
import Toolbar   from '@mui/material/Toolbar';

import PlanetPayLogo from '../resources/img/logo-pp@2x.png';


/**
 * Provides all those elements which are displayed always, regardless
 * of what state the app is in, e.g. logo and footer.
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
export default function GeneralLayout(props) {

  return (

    <Container>
        <AppBar
          position="static"
          color='transparent'
          elevation={0}
        >
          <Toolbar>
            <Grid container>
              <Grid item>
                <img src={PlanetPayLogo} alt="PlanetPay Logo" width={90}/>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        {props.children}
        <Footer/>
      </Container>
  )
}