import React              from 'react';
import { useNavigate }    from 'react-router-dom';
import usePOST            from 'rest/usePOST';
import useStyles          from 'components/CommonForm/commonFormStyles';
import { useTranslation } from 'react-i18next';
import useURL             from 'utils/useURL';

import Alert                from 'components/Alert';
import CommonForm           from 'components/CommonForm';
import COMMON_FIELDS        from 'components/CommonForm/fields';
import Container            from '@mui/material/Container';
import PasswordRequirements from 'components/PasswordRequirements';
import Paper                from '@mui/material/Paper';
import Typography           from '@mui/material/Typography';

import areNewPasswordsValid      from 'utils/areNewPasswordsValid';
import createRestRequestConfig   from 'rest/createRestRequestConfig';
import { FIELD_NAMES }           from 'utils/areNewPasswordsValid';
import { FINTECH_PATHS }         from 'rest/const';
import { commonOTPErrorHandled } from 'rest/const';
import { getErrorMessage }       from 'rest/sendRequest';
import INTERNAL_PATHS            from 'routing/internalPaths';

const IS_NOT_CREDENTIALS = ['otp'];

export function createOtpSubmitData(email, tokenExpired, otpType = 'EMAIL', otpOutsideCredentials) { //TODO? add encryption?
  if (!email || tokenExpired) {
    return null; //Don't call OTP
  }
  const otpBody = {
    type:     otpType,
    username: email,
  };
  if (otpOutsideCredentials) {
    return otpBody;
  }
  return {
    credentials: otpBody,
  };
}

function createFields(showPwdRequirements) {

  const result = {
    username:                     {
      ...COMMON_FIELDS.usernameToFill,
    },
    otp:                          {
      ...COMMON_FIELDS.otp,
    },
    [FIELD_NAMES.newPasswordAlt]: {
      ...COMMON_FIELDS.password,
      label: 'setPasswordFieldLabel',
    },
    [FIELD_NAMES.repeatPassword]: {
      ...COMMON_FIELDS.password,
      label: 'repeatPasswordFieldLabel',
    },
  };

  if (showPwdRequirements) {
    result.pwdRequirements = {
      component: PasswordRequirements,
    };
  }

  return result;
}


function RegisterOTPPage() {

  const navigate    = useNavigate();
  const { t }       = useTranslation();
  const { classes } = useStyles();

  const { token, email, hasTokenExpired } = useURL(() => setTokenInvalid(true));
  const axiosCfg = createRestRequestConfig(token, true);
  const [showPwdRequirements, setShowPwdRequirements] = React.useState(true);
  const FIELDS = React.useMemo(() => createFields(showPwdRequirements),
    [showPwdRequirements]);
  FIELDS.username.value = email; //Default value of this field

  const [alert,              setAlert]              = React.useState(undefined);
  const [tokenInvalid,       setTokenInvalid]       = React.useState(false);
  const [callOtpSubmitData,  setCallOtpSubmitData]  = React.useState(() => createOtpSubmitData(email, hasTokenExpired));
  const [registerSubmitData, setRegisterSubmitData] = React.useState(undefined);
  const [successRegister,    setSuccessRegister]    = React.useState(false);

  React.useEffect(() => {
    if (tokenInvalid) {
      setAlert({
        msgTranslKey: 'tokenInURLInvalid',
        type:         'error',
      });
    } else if (hasTokenExpired) {
      setAlert({
        msgTranslKey: 'tokenExpired',
        type:         'error',
      });
    }
  }, [tokenInvalid, hasTokenExpired]);

  const callOPTSwrOpts = {
    onSuccess: (res) => {
      setCallOtpSubmitData(null);
      setAlert({
        msgTranslKey: t('successCallOTP').replace('&{phoneNo}', res.data.phoneNo),
        type:         'success',
      });

      if (process.env.NODE_ENV === 'development') {
        console.log('RegisterOTPPage, callOTP, onRestSuccess: ', res.data);
      }
    },
    onError: (err) => {
      setCallOtpSubmitData(null);
      //TODO? add more specific handling when API is developed
      // Rozróżnić błędy?
      // telefon nie skonfigurowany dla użytkownika
      // generyczne błędy
      const otpErrorHandler = (errKey) => {
        setAlert({
          msgTranslKey: errKey,
          type:         'error',
        });
      };
      if (!commonOTPErrorHandled(err, otpErrorHandler)) {
        setAlert({
          msgTranslKey: 'serverError',
          type:         'warning',
        });
      }

      if (process.env.NODE_ENV === 'development') {
        console.log('RegisterOTPPage, callOTP, onRestError: ', getErrorMessage(err), err.response);
        console.error('RegisterOTPPage, callOTP, onRestError: ', err);
      }
    },
  };

  const CALL_OTP_PATH = callOtpSubmitData && !tokenInvalid ? FINTECH_PATHS.registerCallOTP : null; //null = don't send
  const CALL_OTP_POST = usePOST(CALL_OTP_PATH, callOtpSubmitData, axiosCfg, callOPTSwrOpts);

  function handleSubmit(evt) {
    evt.preventDefault();
    setAlert(null);
    const formData      = new FormData(evt.target);
    const toBeSubmitted = {
      credentials: {
        type:     'EMAIL',
        username: email,
      },
      trustedDevice: true, //To register trusted device (default: false)
    };

    for (const [key, value] of formData.entries()) {
      if (IS_NOT_CREDENTIALS.includes(key)) {
        toBeSubmitted[key] = value;
      } else {
        toBeSubmitted.credentials[key] = value;
      }
    }

    const newAlert = areNewPasswordsValid(toBeSubmitted.credentials, true);

    if (newAlert) {
      setShowPwdRequirements(true);
      setAlert(newAlert);
    } else {
      delete toBeSubmitted.credentials.repeatPassword;
      setRegisterSubmitData(toBeSubmitted); //TODO? add encryption?
    }
  }

  const registerSwrOpts = {
    onSuccess: (res) => {
      setRegisterSubmitData(null);
      setSuccessRegister(true);
      setAlert({
        msgTranslKey: 'successRegisterOTP',
        type:         'success',
      });
      //Wait short moment and open login page
      setTimeout(() => navigate(INTERNAL_PATHS.login), 1000);

      if (process.env.NODE_ENV === 'development') {
        console.log('RegisterOTPPage, register, onRestSuccess: ', res.data);
      }
    },
    onError: (err) => {
      setRegisterSubmitData(null);
      //TODO? add more specific handling when API is developed
      // Rozróżnić błędy?
      // użytkownik źle wpisał OTP
      // OTP wygasł
      // generyczne błędy
      const otpErrorHandler = (errKey) => {
        setAlert({
          msgTranslKey: errKey,
          type:         'error',
        });
      };
      if (!commonOTPErrorHandled(err, otpErrorHandler)) {
        setAlert({
          msgTranslKey: 'serverError',
          type:         'warning',
        });
      }

      if (process.env.NODE_ENV === 'development') {
        console.log('RegisterOTPPage, register, onRestError: ', getErrorMessage(err), err.response);
        console.error('RegisterOTPPage, register, onRestError: ', err);
      }
    },
  };

  const REGISTER_PATH = registerSubmitData ? FINTECH_PATHS.register : null; //null = don't send
  const REGISTER_POST = usePOST(REGISTER_PATH, registerSubmitData, axiosCfg, registerSwrOpts);

  function submitBtnTooltipFn() {
    if (tokenInvalid) {
      return t('tokenInvalid');
    }
    if (hasTokenExpired) {
      return t('tokenExpired');
    }
    if (CALL_OTP_POST.isValidating || REGISTER_POST.isValidating) {
      return t('inProgress');
    }
    if (successRegister) {
      return t('successRegister');
    }
    return '';
  }

  return (
    <Container maxWidth='xs'>
      <Alert alert={alert} setAlert={setAlert}/>
      <Paper elevation={6} className={classes.mainPaper}>
        <Paper elevation={0} className={classes.titlePaper}>
          <Typography
            align='center'
            variant='subtitle1'
            className={classes.title}
          >
            {t('registerTitle')}
          </Typography>
        </Paper>
        <CommonForm
          t={t}
          fields={FIELDS}
          onSubmit={handleSubmit}
          submitBtnText='registerButton'
          submitBtnTooltipFn={submitBtnTooltipFn}
          everythingDisabled={CALL_OTP_POST.isValidating || REGISTER_POST.isValidating || successRegister || tokenInvalid || hasTokenExpired}
        />
      </Paper>
    </Container>
  );
}

export default RegisterOTPPage;