//interesting statuses (i.e. having additional button)
export const ACTIVE_TOKEN_STATUS    = 'ACTIVE';
export const SUSPEND_TOKEN_STATUS   = 'SUSPEND';
export const SUSPENDED_TOKEN_STATUS = 'SUSPENDED';

//uninteresting statuses (i.e. having just delete button)
export const INACTIVE_TOKEN_STATUS     = 'INACTIVE';
export const APPROVED_TOKEN_STATUS     = 'APPROVED';
export const PASSCODESENT_TOKEN_STATUS = 'PASSCODESENT';


//statuses to be deleted from the table
export const DELETED_TOKEN_STATUS     = 'DELETED';
export const DEACTIVATE_TOKEN_STATUS  = 'DEACTIVATE';
export const DEACTIVATED_TOKEN_STATUS = 'DEACTIVATED';
export const DECLINED_TOKEN_STATUS    = 'DECLINED';
