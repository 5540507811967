import React from 'react';

import useStyles from './spinnerStyles';

const ELEMENT_ID = "react-Spinner";


/**
 * Spinner designed as loading or work-in-progress indicator.
 */
function Spinner() {

  const { classes } = useStyles();

  React.useEffect(() => {
    document.getElementById(ELEMENT_ID).animate([
      { transform: "rotate(0deg)" },
      { transform: "rotate(360deg)" }
    ], {
      duration:   1000,
      iterations: Infinity
    })
  }, []);

  return (
    <div id={ELEMENT_ID} className={classes.spinner}/>
  );
}

export default Spinner;