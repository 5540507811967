import React from 'react';

import { useTranslation }        from 'react-i18next';
import { FINTECH_PATHS }         from 'rest/const';
import useGET                    from 'rest/useGET';
import ConfirmationAndOtpDialog  from 'pages/CardDetailsPage/tabs/DetailsAndOperationsTab/OperationsDialogs/ConfirmationAndOtpDialog';
import createRestRequestConfig   from 'rest/createRestRequestConfig';
import { AuthContext }           from 'App';
import CardLimit                 from 'pages/CardDetailsPage/tabs/CardLimitsTab/CardLimit';
import Button                    from '@mui/material/Button';
import { OTP_TYPES }             from 'pages/CardDetailsPage/tabs/DetailsAndOperationsTab/detailsAndOperationsTabConst';
import usePATCH                  from 'rest/usePATCH';
import useStyles                 from './cardLimitsTabStyles';
import { commonOTPErrorHandled } from 'rest/const';
import Typography                from '@mui/material/Typography';
import CenteredSpinner           from 'components/CenteredSpinner';
import Alert                     from 'components/Alert';
import { OTP_FIELD_NAME }        from 'pages/CardDetailsPage/tabs/DetailsAndOperationsTab/OTPField/OTPField';


export default function CardLimitsTab({ card }) {

  const { classes }  = useStyles();
  const { t }        = useTranslation(['common', 'cardDetails']);
  const { userInfo } = React.useContext(AuthContext);

  const [formKey, setFormKey] = React.useState(0);
  const [alert, setAlert]     = React.useState(undefined);

  const [changedLimits, setChangedLimits]               = React.useState(undefined);
  const [otpCode, setOtpCode]                           = React.useState(undefined);
  const [otpType, setOtpType]                           = React.useState(undefined);
  const [disableButtons, setDisableButtons]             = React.useState(true);
  const [showValidityMessages, setShowValidityMessages] = React.useState(false);

  const otpFormRef = React.useRef();

  function resetOtpState() {
    setOtpCode(undefined);
    setOtpType(undefined);
    setDisableButtons(true);
    setShowValidityMessages(false);
    setFormKey(prevKey => ++prevKey);
  }

  const axiosCfg = createRestRequestConfig(userInfo.accessToken, true);

  const commonSwrOpts = {
    revalidateIfStale:     false,
    revalidateOnFocus:     false,
    revalidateOnReconnect: false,
    shouldRetryOnError:    false,
  };

  const swrGetOpts = {
    ...commonSwrOpts,
    onSuccess: (res) => {
      setFormKey(prevKey => ++prevKey);
      const data = res.data || [];
      //sorts in place, modification is preserved in response object and by that in SWR cache
      data.sort((a, b) => a?.desc?.localeCompare(b?.desc) || 0);
    },
  }

  const pathGET = FINTECH_PATHS.cardLimits.replace('{cardId}', card.cardId);
  const { data: response, error: errorGET, mutate, isValidating: isValidatingGET }
                = useGET(pathGET, axiosCfg, swrGetOpts);
  const limits  = response?.data || [];

  const actionSwrOpts = {
    ...commonSwrOpts,
    onSuccess: () => {
      setAlert({
        msgTranslKey: 'success',
        type:         'success',
      });
      mutate();
      resetOtpState();
    },
    onError:   (err) => {
      const otpErrorHandler = (errKey) => {
        otpFormRef.current.elements[OTP_FIELD_NAME].setCustomValidity(t(`common:${errKey}`));
        setShowValidityMessages(true);
        if (errKey === 'tooManyOtpAttempts') {
          setAlert({  //setting error message on the field isn't enough, retrying won't help
            msgTranslKey: errKey,
            type:         'error',
          });
        }
        setOtpCode(undefined);
      };
      if (commonOTPErrorHandled(err, otpErrorHandler)) {
        return;
      }
      resetOtpState();
      setAlert({
        msgTranslKey: 'failure',
        type:         'error',
      });
      console.error("Error while saving new limits", err);
    },
  }

  let body;
  if (otpCode && changedLimits) {
    body = {
      otp:    otpCode,
      limits: changedLimits,
    };
  }
  let patchPath;
  if (body) //wait for body to send
    patchPath = FINTECH_PATHS.changeLimits.replace('{cardId}', card.cardId);

  const { isValidating } = usePATCH(patchPath, body, axiosCfg, actionSwrOpts);

  function onSubmit(evt) {
    setOtpType(OTP_TYPES.changeLimits);
    setDisableButtons(true);
    evt.preventDefault();
    const formData      = new FormData(evt.target);
    const toBeSubmitted = [];
    for (const [key, valueStr] of formData.entries()) {
      const previousValue = limits.filter(limit => limit.name === key)[0].value;
      const newValue      = parseFloat(valueStr);
      if (newValue === previousValue)
        continue;
      toBeSubmitted.push({
        name:  key,
        value: newValue,
      });
    }
    setChangedLimits(toBeSubmitted);
  }

  function onDialogClose() {
    resetOtpState();
    setAlert(undefined);
  }

  if (!response && isValidatingGET) {
    return (
      <CenteredSpinner/>
    )
  }

  if (errorGET) {
    const alertGET = {
      msgTranslKey:          'serverError',
      msgTranslSecondaryKey: 'closeAlertToRetry',
      type:                  'error',
    }
    return (
      <Alert alert={alertGET} setAlert={() => mutate()}/>
    )
  }

  //this table uses basic html tags instead of MUI components which
  //makes it impossible to influence via theme (except for Typography)
  //on the other hand MUI components change too much
  return (
    <>
      <form onSubmit={onSubmit} key={formKey}>
        <table className={classes.table}>
          <thead>
            <tr>
              <th/>
              <th/>
              <th>
                <Typography className={classes.headerCell}>
                  {t('cardDetails:limitMaxColumnName')}
                </Typography>
              </th>
            </tr>
          </thead>
          <tbody>
            {limits.map(limit =>
              <CardLimit
                key={limit.name}
                displayOnly={card.status !== 'ACTIVE'}
                limit={limit}
                onChange={() => setDisableButtons(false)}
                currency={card.currency || ''}
              />
            )}
          </tbody>
        </table>

        <Button
          type='submit'
          disabled={disableButtons}
          className={classes.saveButton}
        >
          {t('cardDetails:saveLimits')}
        </Button>
      </form>

      <ConfirmationAndOtpDialog
        skipUserOtpRequest={true}
        dialogTitle={t('cardDetails:changeLimitsDialogTitle')}
        setOtpCode={setOtpCode}
        onClose={onDialogClose}
        disableSubmit={isValidating}
        //display alert or otp field, not both
        otpType={alert == null ? otpType : null}
        alert={alert}
        closeAlert={setAlert}
        formRef={otpFormRef}
        showValidityMessages={showValidityMessages}
      />
    </>
  );
}
