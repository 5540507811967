import useSWR from 'swr';

export default function useGET(path, axiosCfg, swrOpts) {
  const method = 'GET';
  const body = null;

  let swrKey = [path, method, body, axiosCfg];
  if (path == null)
    swrKey = null; //sending request conditionally

  return useSWR(swrKey, swrOpts);
}