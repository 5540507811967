import { useParams }           from 'react-router-dom';
import React                   from 'react';
import { AuthContext }         from 'App';
import useREST                 from 'rest/useREST';
import { FINTECH_PATHS }       from 'rest/const';
import createRestRequestConfig from 'rest/createRestRequestConfig';
import { MutateContext }       from './MobilePaymentsTab';


export default function useTokensManagement() {

  const urlParams    = useParams();
  const cardId       = urlParams.cardId;
  const { userInfo } = React.useContext(AuthContext);
  const mutateRef    = React.useContext(MutateContext);

  const [inProgress, setInProgress] = React.useState(false);

  const rest = useREST();

  function resetStateAndMutate() {
    setInProgress(false);
    mutateRef.current?.();
  }

  function createPathAndAxiosCfg(tokenReferenceId, pathFormat) {
    const path     = pathFormat
      .replace('{cardId}', cardId)
      .replace('{tokenReferenceId}', tokenReferenceId);
    const axiosCfg = createRestRequestConfig(userInfo.accessToken, true);
    return { path, axiosCfg };
  }

  function suspendToken(otp, token, onSuccess, onError) {
    const tokenReferenceId   = token.tokenReferenceId;
    const { path, axiosCfg } = createPathAndAxiosCfg(tokenReferenceId, FINTECH_PATHS.suspendCardToken);

    rest([path, 'post', { otp }, axiosCfg])
      .then(onSuccess)
      .catch(err => {
        console.error(`Error while suspending token of card ${cardId} with referenceId ${tokenReferenceId}`, err);
        onError(err);
      })
      .then(resetStateAndMutate);
  }

  function unsuspendToken(otp, token, onSuccess, onError) {
    const tokenReferenceId = token.tokenReferenceId;

    const { path, axiosCfg } = createPathAndAxiosCfg(tokenReferenceId, FINTECH_PATHS.unsuspendCardToken);

    rest([path, 'post', { otp }, axiosCfg])
      .then(onSuccess)
      .catch(err => {
        console.error(`Error while unsuspending token of card ${cardId} with referenceId ${tokenReferenceId}`, err);
        onError(err);
      })
      .then(resetStateAndMutate);
  }

  function deleteToken(otp, token, onSuccess, onError) {
    const tokenReferenceId   = token.tokenReferenceId;
    const { path, axiosCfg } = createPathAndAxiosCfg(tokenReferenceId, FINTECH_PATHS.deleteCardToken);

    rest([path, 'delete', { otp }, axiosCfg])
      .then(onSuccess)
      .catch(err => {
        console.error(`Error while deleting token of card ${cardId} with referenceId ${tokenReferenceId}`, err);
        onError(err);
      })
      .then(resetStateAndMutate);
  }

  return {
    inProgress,
    suspendToken,
    unsuspendToken,
    deleteToken,
  };
}