import React     from 'react';
import useStyles from './cardDetailsDialogStyles';

import { useTranslation } from 'react-i18next';

import AppBar            from '@mui/material/AppBar';
import Box               from '@mui/material/Box';
import CardDetailsHeader from 'pages/CardDetailsPage/CardDetailsHeader';
import CardLimitsTab     from 'pages/CardDetailsPage/tabs/CardLimitsTab';
import CenteredSpinner   from 'components/CenteredSpinner';
import MobilePaymentsTab from 'pages/CardDetailsPage/tabs/MobilePaymentsTab';
import NavBar            from 'NavBar';
import Paper             from '@mui/material/Paper';
import Tab               from '@mui/material/Tab';
import Tabs              from '@mui/material/Tabs';
import ThreeDSTab        from 'pages/CardDetailsPage/tabs/ThreeDSTab';

import AccountInfoTab          from 'pages/CardDetailsPage/tabs/AccountInfoTab';
import createRestRequestConfig from 'rest/createRestRequestConfig';
import isBlank                 from 'utils/isBlank';
import HistoryTab              from './tabs/HistoryTab';
import DetailsAndOperationsTab from './tabs/DetailsAndOperationsTab';
import useGET                  from 'rest/useGET';
import { useParams }           from 'react-router-dom';
import { useSnackbar }         from 'notistack';

import { AuthContext }   from 'App';
import { FINTECH_PATHS } from 'rest/const';
import { FLAGS }         from 'rest/const';


const CARD_KIND_CREDIT = 'CREDIT';

export default function CardDetailsPage() {

  const { t }               = useTranslation(['common', 'cardDetails']);
  const { classes }         = useStyles();
  const { userInfo }        = React.useContext(AuthContext);
  const urlParams           = useParams();
  const cardId              = urlParams.cardId;
  const { enqueueSnackbar } = useSnackbar();

  const [path, setPath]               = React.useState(FINTECH_PATHS.cardDetails.replace('{cardId}', cardId));
  const [cardDetails, setCardDetails] = React.useState(undefined);

  const axiosCfg = createRestRequestConfig(userInfo.accessToken, true);
  const swrOpts  = {
    revalidateIfStale:     true,
    revalidateOnFocus:     false,
    revalidateOnReconnect: false,
    onSuccess:             (response) => {
      setCardDetails(response.data);
      setPath(undefined); //to avoid redownloading when session refreshes
    },
    onError:               () => {
      enqueueSnackbar(t('common:fetchError'), { variant: 'error' });
      setPath(undefined); //to avoid redownloading on error
    },
  }

  useGET(path, axiosCfg, swrOpts);

  const [selectedTab, setSelectedTab] = React.useState(0);

  function commonTabProps() {
    return {
      card:   cardDetails,
      cardId: cardId,
      // refreshData: mutate, //FIXME mutate won't work since path is nulled
      refreshData: () => setPath(FINTECH_PATHS.cardDetails.replace('{cardId}', cardId)),
    };
  }

  let tabs = [
    {
      label:     t('cardDetails:operationsTab'),
      component: <DetailsAndOperationsTab
                   {...commonTabProps()}
                 />,
    },
  ];

  if (isCreditCard(cardDetails)) {
    tabs.push({
      label:     t('cardDetails:accountTab'),
      component: <AccountInfoTab
                   {...commonTabProps()}
                 />
    });
  }

  tabs.push({ //pushed here to display in specific order
    label:     t('cardDetails:historyTab'),
    component: <HistoryTab
                 {...commonTabProps()}
               />,
  });

  if (!cardDetails) {
    tabs = [{
      label:     t('cardDetails:loadingDetails'),
      component: <CenteredSpinner/>,
    }];
  }

  function shouldShowLimitsAndWhitelist() {
    if (!cardDetails)
      return false;
    const status = cardDetails.status;
    if (status === 'ORDERED')
      return true;
    if (status === 'BLOCKED')
      return true;
    if (status === 'INACTIVE')
      return true;
    return status === 'ACTIVE';
  }

  if (shouldShowLimitsAndWhitelist()) {
    tabs.push({
      label:     t('cardDetails:cardLimitsTab'),
      component: <CardLimitsTab
                   {...commonTabProps()}
                 />,
    });

    tabs.push({
      label:     t('cardDetails:3dsTab'),
      component: <ThreeDSTab
                   {...commonTabProps()}
                 />,
    });
  }

  if (FLAGS.hideMobilePaymentsTab !== true) {
    tabs.push({
      label:     t('cardDetails:mobilePaymentsTab'),
      component: <MobilePaymentsTab
                   {...commonTabProps()}
                 />,
    });
  }

  return (
    <>
      <NavBar/>
      <Paper elevation={3} className={classes.paper}>
        <CardDetailsHeader card={cardDetails}/>
        <AppBar position='sticky'>
          <Tabs
            value={selectedTab}
            onChange={(evt, index) => setSelectedTab(index)}
            variant='fullWidth'
          >
            {tabs.map((tab, index) =>
              <Tab label={tab.label} {...tabHeaderProps(index, classes)} />
            )}
          </Tabs>
        </AppBar>
        <div className={classes.tabContent}>
          {tabs.map((tab, index) => (
            <TabContent key={index + 'tabBody'} index={index} selectedTab={selectedTab}>
              {tab.component}
            </TabContent>
          ))}
        </div>
      </Paper>
    </>
  );
}

function tabHeaderProps(index, classes) {
  const id = 'tabHeader-' + index;
  return {
    id:      id,
    key:     id,
    value:   index,
    classes: {
      root:     classes.tab,
      selected: classes.tabSelected,
    },
  };
}

function isCreditCard(cardDetails) {
  const kind = cardDetails?.kind;
  if (isBlank(kind))
    return false;
  return kind.toUpperCase() === CARD_KIND_CREDIT;
}

function TabContent({ children, index, selectedTab, ...props }) {
  const id = 'tabContent-' + index;
  return (
    <div id={id} key={id} {...props}>
      <Box>
        {index === selectedTab ? children : null /*Don't display tabs that aren't selected*/}
      </Box>
    </div>
  );
}
