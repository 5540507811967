import React                             from 'react';
import Button                            from '@mui/material/Button';
import Change3DSAnswerDialog             from './Change3DSAnswerDialog';
import useCurrentAndAvailable3DSMethods  from 'pages/CardDetailsPage/tabs/ThreeDSTab/ThreeDSActions/useCurrentAndAvailable3DSMethods';
import { useTranslation }                from 'react-i18next';
import { AUTH_METHOD_SMS_PLUS_QUESTION } from 'pages/CardDetailsPage/tabs/ThreeDSTab/ThreeDSActions/threeDSActionsConst';


/**
 * It's referred to as 'answer' because usually the client sets
 * a question and an answer, but in ITCARD there is no question and
 * the answer is a PIN code for ecommerce transactions. So in
 * practice this is setting a PIN code.
 */
function Change3DSAnswer() {

  const { t }                 = useTranslation('cardDetails');
  const { currentAuthMethod } = useCurrentAndAvailable3DSMethods();

  const [dialogOpen, setDialogOpen] = React.useState(false);

  if (currentAuthMethod !== AUTH_METHOD_SMS_PLUS_QUESTION) {
    return null;
  }

  if (dialogOpen) {
    return (
      <Change3DSAnswerDialog closeDialog={() => setDialogOpen(false)}/>
    );
  }

  return (
    <Button onClick={() => setDialogOpen(true)}>
      {t('change3DSAnswerButton')}
    </Button>
  );
}

export default Change3DSAnswer;