import React from 'react';

import PropTypes          from 'prop-types';
import Grid               from '@mui/material/Grid';
import Typography         from '@mui/material/Typography';
import Button             from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import TextField          from '@mui/material/TextField';
import useStyles          from 'components/CommonForm/commonFormStyles';
import Checkbox           from '@mui/material/Checkbox';
import FormControlLabel   from '@mui/material/FormControlLabel';


function DeviceDescriptionForm(props) {

  const { t }       = useTranslation('common');
  const { classes } = useStyles();

  const [description, setDescription] = React.useState('');
  const [accept,      setAccept]      = React.useState(false);

  return (
    <Grid
      container
      spacing={2}
      paddingTop={2}
      paddingBottom={3}
      paddingLeft={4}
      paddingRight={4}
      className={classes.centerText}
    >
      <Grid item xs={12}>
        <Typography variant='h5' fontWeight='bold'>
          {t('addingToTrustedTitle')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          {t('addingToTrustedInfo')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={accept}
              onChange={(evt) => setAccept(evt.target.checked)}
              value={accept}
            />
          }
          label={t('acceptTrustedDeviceCheckbox')}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label={t('deviceDescriptionFieldLabel')}
          value={description}
          onChange={(evt) => setDescription(evt.target.value)}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          disabled={!accept}
          onClick={() => props.onAddToTrusted(description)}
        >
          {t('addToTrustedButton')}
        </Button>
      </Grid>
    </Grid>
  );
}

DeviceDescriptionForm.propTypes = {
  onAddToTrusted: PropTypes.func.isRequired,
};

export default DeviceDescriptionForm;