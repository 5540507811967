import React              from 'react';
import useStyles          from './alertStyles';
import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon  from '@mui/icons-material/Close';
import Grid       from '@mui/material/Grid';
import Paper      from '@mui/material/Paper';

import classnames from 'classnames';
import PropTypes  from 'prop-types';


function Alert(props) {

  const { classes } = useStyles(props.alert?.type);

  const propsClasses = props.classes || {};

  const { t } = useTranslation();

  return (
    <div className={classnames(classes.alertContainer, propsClasses.alertContainer)}>
      {props.alert &&
        <Paper className={classnames(classes.paper, propsClasses.paper)}>
          <Grid
            container
            justifyContent='space-between'
            alignItems='center'
            wrap='nowrap'
          >
            <Grid item xs={12} /* textAlign: 'center' -> doesn't work if width (aka 'xs') not specified */>
              <Typography
                className={classnames(
                  classes.typography, classes.typographyFont,
                  propsClasses.typography, propsClasses.typographyFont
                )}
                align='center'
              >
                {t(props.alert.msgTranslKey)}
              </Typography>
              <Typography
                className={classnames(
                  classes.typography, classes.typographyFontSecondary,
                  propsClasses.typography, propsClasses.typographyFontSecondary
                )}
                align='center'
              >
                {t(props.alert.msgTranslSecondaryKey)}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                onClick={() => props.setAlert?.(null)}
                className={classnames(classes.alertContainerCloseButton, propsClasses.alertContainerCloseButton)}
              >
                <CloseIcon/>
              </IconButton>
            </Grid>
          </Grid>
        </Paper>
      }
    </div>
  );
}

Alert.propTypes = {
  alert:    PropTypes.shape({
    msgTranslKey:          PropTypes.string.isRequired,
    msgTranslSecondaryKey: PropTypes.string,
    type:                  PropTypes.string,
  }),
  setAlert: PropTypes.func,
  classes:  PropTypes.shape({
    alertContainer:            PropTypes.string,
    paper:                     PropTypes.string,
    typography:                PropTypes.string,
    typographyFont:            PropTypes.string,
    typographyFontSecondary:   PropTypes.string,
    alertContainerCloseButton: PropTypes.string,
  }),
};

export default Alert;