export const DATA_TABLE_NS = 'cardsTable';

export const BILLING_AMOUNT_COLUMN_NAME = 'billingAmount';
export const BILLING_DATE_COLUMN_NAME   = 'billingDate';
export const DEBIT_CREDIT_COLUMN_NAME   = 'debitCredit';
export const OPERATION_TYPE_COLUMN_NAME = 'operationType';

export const HOLD = 'H'; //Possible value of OPERATION_TYPE column

export const DEBIT = 'D'; //Possible value of DEBIT_CREDIT column
