import { makeStyles } from 'tss-react/mui';


const useStyles = makeStyles()(theme => ({
  spinner: {
    border:            "1rem solid #ddd",
    borderTop:         "1rem solid " + theme.palette.primary.main,
    borderRadius:      "50%",
    width:             "7.5rem",
    height:            "7.5rem",
    marginLeft:        "auto",
    marginLight:       "auto",
    "WebkitBoxSizing": "border-box",
    "MozBoxSizing":    "border-box",
    boxSizing:         "border-box",
  }
}));

export default useStyles;
