import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    padding:   theme.spacing(2),
  },
}));

export default useStyles;
