import React from 'react';

import { useTranslation } from 'react-i18next';

import Button        from '@mui/material/Button';
import Dialog        from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle   from '@mui/material/DialogTitle';


export default function ConfirmDeviceDeletionDialog({ deviceName, closeDialog, onSubmit }) {

  const { t } = useTranslation();

  return (
    <Dialog
      open={true}
      fullWidth={true}
      maxWidth={'sm'}
      onClose={(evt, type) => {
        if (type === 'backdropClick') {
          return; //Don't close on backdrop click
        }
        closeDialog();
      }}
    >
      <DialogTitle>
        {t('confirmDeviceDeletionDialogTitle')}
      </DialogTitle>
      <DialogContent dividers>
        {t('confirmDeviceDeletionDialogContent').replace('{deviceName}', deviceName)}
      </DialogContent>
      <DialogActions>
        <Button
          id='submitButton'
          onClick={onSubmit}
          color='primary'
          variant='contained'
        >
          {t('yes')}
        </Button>
        <Button
          id="cancelButton"
          onClick={closeDialog}
          color='primary'
          variant='outlined'
        >
          {t('no')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
