import ConfirmationAndOtpDialog    from './ConfirmationAndOtpDialog';
import PinChangeDialog             from './PinChangeDialog';
import EmbossInfoAccessDialog      from './EmbossInfoAccessDialog';
import React                       from 'react';
import * as PropTypes              from 'prop-types';
import { OPERATIONS }              from '../detailsAndOperationsTabConst';
import { OTP_TYPES }               from '../detailsAndOperationsTabConst';
import { useTranslation }          from 'react-i18next';
import BlockCardConfirmationDialog from 'pages/CardDetailsPage/tabs/DetailsAndOperationsTab/OperationsDialogs/BlockCardConfirmationDialog';


/**
 * This is an aggregating component - it implicitly derives as much
 * information from props as possible to open and supply the correct
 * dialog window, thus taking a lot of pressure of its parent
 * component.
 *
 * PIN change and embossed info access dialogs are almost
 * self-sufficient, they just need card data, while all other dialogs
 * rely heavily on parent component.
 */
export default function OperationsDialogs(props) {

  const { t } = useTranslation(['common', 'cardDetails']);

  let dialogTitle;
  let confirmationText;
  let permanentBlock = false;
  let otpType;

  switch (props.operation) {
    case OPERATIONS.activateCard:
      dialogTitle      = t('cardDetails:confirmationDialogTitle.activateCard');
      confirmationText = t('cardDetails:areYouSure.activateCard');
      otpType          = OTP_TYPES.activateCard;
      break;
    case OPERATIONS.temporarilyBlockCard:
      dialogTitle      = t('cardDetails:confirmationDialogTitle.temporarilyBlockCard');
      confirmationText = t('cardDetails:areYouSure.temporarilyBlockCard');
      otpType          = OTP_TYPES.temporarilyBlockCard;
      break;
    case OPERATIONS.unblockCard:
      dialogTitle      = t('cardDetails:confirmationDialogTitle.unblockCard');
      confirmationText = t('cardDetails:areYouSure.unblockCard');
      otpType          = OTP_TYPES.unblockCard;
      break;
    case OPERATIONS.blockLostCard:
      dialogTitle      = t('cardDetails:confirmationDialogTitle.blockCard');
      confirmationText = t('cardDetails:areYouSure.blockCard');
      permanentBlock   = true;
      break;
    case OPERATIONS.blockStolenCard:
      dialogTitle      = t('cardDetails:confirmationDialogTitle.blockCard');
      permanentBlock   = true;
      break;
    case OPERATIONS.changePin:
      dialogTitle      = t('cardDetails:confirmationDialogTitle.blockCard');
      break;
    case OPERATIONS.enableContactless:
      dialogTitle      = t('cardDetails:confirmationDialogTitle.toggleContactless');
      confirmationText = t('cardDetails:areYouSure.enableContactless');
      otpType          = OTP_TYPES.enableContactless;
      break;
    case OPERATIONS.disableContactless:
      dialogTitle      = t('cardDetails:confirmationDialogTitle.toggleContactless');
      confirmationText = t('cardDetails:areYouSure.disableContactless');
      otpType          = OTP_TYPES.disableContactless;
      break;
    default:
      break
  }

  if (permanentBlock) { //permanent blockade doesnt require OTP
    return (
      <BlockCardConfirmationDialog
        dialogTitle={dialogTitle}
        onConfirmation={props.onConfirmation}
        onClose={props.onClose}
        alert={props.alert}
        closeAlert={props.closeAlert}
      />
    );
  }

  const pinAndEmbossDialogsProps = {
    open:    true,
    onClose: props.onClose,
    cardId:  props.cardId,
    expDate: props.expDate,
  };

  if (props.pinChangeDialogOpen) {
    return (
      <PinChangeDialog {...pinAndEmbossDialogsProps}/>
    );
  }

  if (props.embossInfoDialogOpen) {
    //dismounting on close is important to clear state and the emboss info with it
    return (
      <EmbossInfoAccessDialog{...pinAndEmbossDialogsProps}/>
    );
  }

  let sendOtpButtonText;
  const yesSendOtpTypes = [OTP_TYPES.activateCard, OTP_TYPES.temporarilyBlockCard,
    OTP_TYPES.unblockCard, OTP_TYPES.disableContactless, OTP_TYPES.disableContactless];
  if (yesSendOtpTypes.includes(props.otpType))
    sendOtpButtonText = t('common:yesSendOtp');

  return (
    <ConfirmationAndOtpDialog
      dialogTitle={dialogTitle}
      confirmationText={confirmationText}
      sendOtpButtonText={sendOtpButtonText}

      otpType={props.alert ? undefined : otpType} //show either alert or the form, not both
      setOtpCode={props.setOtpCode}
      onClose={props.onClose}
      alert={props.alert}
      closeAlert={props.closeAlert}
      disableSubmit={props.disableSubmit}
      showValidityMessages={props.showValidityMessages}
      formRef={props.formRef}
    />
  );
}

OperationsDialogs.propTypes = {
  operation:              PropTypes.oneOf(Object.values(OPERATIONS)),
  setOtpCode:             PropTypes.func,
  onConfirmation:         PropTypes.func,
  onClose:                PropTypes.func,
  alert:                  PropTypes.any,
  closeAlert:             PropTypes.func,
  formRef:                PropTypes.any,
  disableSubmit:          PropTypes.any,
  showConfirmationButton: PropTypes.bool,
  showValidityMessages:   PropTypes.bool,

  confirmationText:  PropTypes.any,
  sendOtpButtonText: PropTypes.string,
  dialogTitle:       PropTypes.any,

  embossInfoDialogOpen: PropTypes.bool,
  pinChangeDialogOpen:  PropTypes.bool,
  cardId:               PropTypes.any,
  expDate:              PropTypes.any,
};