import React              from 'react';
import { useTranslation } from 'react-i18next';
import Typography         from '@mui/material/Typography';


function PasswordRequirements() {

  const { t } = useTranslation('common');

  return (
    <Typography component='span'>
      <ul>
        <li>{t('common:passwordTooShort')}</li>
        <li>{t('common:passwordTooLong')}</li>
        <li>{t('common:passwordMustHaveADigit')}</li>
        <li>{t('common:passwordMustHaveASpecialChar')}</li>
        <li>{t('common:passwordIncludesUsername')}</li>
      </ul>
    </Typography>
  );
}

export default PasswordRequirements;