import React from 'react';

import { AuthContext } from 'App';

import sendRequest from 'rest/sendRequest';

/**
 * @returns {function([relativePath: string, method: string, body?: any, config?: object]): Promise<any>}
 */
export default function useREST(throwOnSessionError = true) {
  const authContext = React.useContext(AuthContext);

  const [error, setError] = React.useState(undefined);

  const onSessionError = React.useCallback((err) => {
    setError(err)
  }, []);

  /**
   * The purpose of this effect is to throw an error which will be
   * caught by React Router.
   */
  React.useEffect(() => {
    if (error && throwOnSessionError)
      throw error;
  }, [error, throwOnSessionError]);

  return React.useCallback(
    ([path, method, body, cfg]) => sendRequest(path, method, body, cfg, authContext, onSessionError),
    [authContext, onSessionError]
  );
}
