import React from 'react';

import PropTypes        from 'prop-types';
import Grid             from '@mui/material/Grid';
import AvailableBalance from './AvailableBalance';
import Typography       from '@mui/material/Typography';


function CardDetailsHeader(props) {

  if (!props.card)
    return null;

  return (
    <Grid container justifyContent='space-between' marginBottom={4}>
      <Grid item>
        <Grid container direction='column' spacing={2}>
          <Grid item>
            <Typography variant='h3'>
              {props.card.productName}
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              {props.card.cardMask}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <AvailableBalance
          availableBalance={props.card?.availableBalance}
          currency={props.card?.currency}
        />
      </Grid>
    </Grid>
  );
}

CardDetailsHeader.propTypes = {
  card: PropTypes.shape({
    productName:      PropTypes.string.isRequired,
    cardMask:         PropTypes.string.isRequired,
    availableBalance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    currency:         PropTypes.string,
  }),
};

export default CardDetailsHeader;