import React                             from 'react';
import Typography                        from '@mui/material/Typography';
import useCurrentAndAvailable3DSMethods  from '../useCurrentAndAvailable3DSMethods';
import { useTranslation }                from 'react-i18next';
import { LinearProgress }                from '@mui/material';
import useStyles                         from './currentMethodInfoStyles';
import Grid                              from '@mui/material/Grid';
import { AUTH_METHOD_MOBILE_APP }        from '../threeDSActionsConst';
import { AUTH_METHOD_SMS_PLUS_QUESTION } from '../threeDSActionsConst';


function CurrentMethodInfo() {

  const { classes }                  = useStyles();
  const { currentAuthMethod, error } = useCurrentAndAvailable3DSMethods();
  const { t }                        = useTranslation('cardDetails');

  const displayedAuthMethod = React.useMemo(() => {
    switch (currentAuthMethod) {
      case AUTH_METHOD_MOBILE_APP:
        return t('mobileApp3DSAuthMethod');
      case AUTH_METHOD_SMS_PLUS_QUESTION:
        return t('smsPlusQuestion3DSAuthMethod');
      default:
        return currentAuthMethod;
    }
  }, [currentAuthMethod, t]);

  if (error && !currentAuthMethod) {
    return (
      <Typography color='error' className={classes.valueOrErrorText}>
        {t('fetchingCurrent3DSMethodFailed')}
      </Typography>
    );
  }


  return (
    <Grid container alignItems='center'>
      <Grid item>
        <Typography
          component='span'
          className={classes.label}
        >
          {t('currentAuthMethod')}
        </Typography>
      </Grid>

      <Grid item minWidth={200}>
        <Typography
          component='span'
          fontWeight='bold'
          className={classes.valueOrErrorText}
        >
          {displayedAuthMethod || <LinearProgress/>}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default CurrentMethodInfo;