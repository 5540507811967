import React              from 'react';
import Button             from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import AddMerchantDialog  from './AddMerchantDialog';


function AddMerchantButton() {

  const [dialogOpen, setDialogOpen] = React.useState(false);

  const { t } = useTranslation('cardDetails');

  if (dialogOpen) {
    return (
      <AddMerchantDialog closeDialog={() => setDialogOpen(false)}/>
    );
  }

  return (
    <Button onClick={() => setDialogOpen(true)}>
      {t('addMerchantToWhitelist.addMerchantToWhitelistButton')}
    </Button>
  );
}

export default AddMerchantButton;