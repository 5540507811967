import React              from 'react';
import { useTranslation } from 'react-i18next';

import EastIcon   from '@mui/icons-material/East';
import Grid       from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip    from '@mui/material/Tooltip';
import WestIcon   from '@mui/icons-material/West';

import isBlank from 'utils/isBlank';

import dayjs                        from 'dayjs';
import PropTypes                    from 'prop-types';
import { DAYJS_FORMATS }            from './MaskedDateField';
import { DEFAULT_PLACEHOLDER_CHAR } from './MaskedDateField';
import MaskedDateField              from './MaskedDateField';
import { MASKS }                    from './MaskedDateField';


const DATE_COMPARISON_GRANULARITY = 'day';


/**
 * Displays two date fields next to each other: 'date from' and 'date
 * to'. They have a number of features for better UX:
 * * a mask when input value is incomplete,
 * * buttons for copying value from one to the other,
 * * a calendar for mouse-based input.
 */
function DateFields(props) {

  const { t } = useTranslation('cardDetails', { keyPrefix: 'filters' });

  const [wasArrowClicked, setArrowClicked]    = React.useState(false);
  const [temporaryData, setTemporaryData]     = React.useState(props.date || {});
  const [helperTextsKeys, setHelperTextsKeys] = React.useState({});

  const setter = props.setDate; //extracted for dependency arrays

  /**
   * Updates helper texts and parent's state.
   */
  React.useEffect(() => {
    const temporaryFromParsed = dayjs(temporaryData.from, DAYJS_FORMATS.DATE, true);
    const temporaryToParsed   = dayjs(temporaryData.to, DAYJS_FORMATS.DATE, true);

    function isFuture(parsed) {
      const now = dayjs();
      return parsed.isAfter(now, DATE_COMPARISON_GRANULARITY);
    }

    function validate(raw, parsed) {
      if (isBlank(raw))
        return { valid: false, eligibleForParent: true, helperTextKey: 'dateRequired' };
      if (raw.includes(DEFAULT_PLACEHOLDER_CHAR))
        return { valid: false, eligibleForParent: false, helperTextKey: '' };

      let helperTextKey = null;
      if (!parsed.isValid())
        helperTextKey = 'dateMalformed';
      else if (isFuture(parsed))
        helperTextKey = 'futureDate';
      else if (temporaryToParsed.isBefore(temporaryFromParsed, DATE_COMPARISON_GRANULARITY))  //false if any invalid
        helperTextKey = 'dateToBeforeDateFrom';

      const valid = !helperTextKey;
      return {
        valid:             valid,
        eligibleForParent: valid,
        helperTextKey:     helperTextKey,
      };
    }

    const dateFromValidation = validate(temporaryData.from, temporaryFromParsed);
    const dateToValidation   = validate(temporaryData.to, temporaryToParsed);

    //FIXME this can cause resetting of a helper text on change even
    // if the corresponding field hasn't been changed
    setHelperTextsKeys({
      from: dateFromValidation.helperTextKey,
      to:   dateToValidation.helperTextKey,
    });

    setter(prevDate => {
      const isDateFromTheSame = prevDate.from === temporaryData.from;
      const isDateToTheSame   = prevDate.to === temporaryData.to;

      const isDateFromEligible = dateFromValidation.eligibleForParent;
      const isDateToEligible   = dateToValidation.eligibleForParent;

      const shouldUpdateDateFrom = !isDateFromTheSame && isDateFromEligible;
      const shouldUpdateDateTo   = !isDateToTheSame && isDateToEligible;

      if (!shouldUpdateDateFrom && !shouldUpdateDateTo)
        return prevDate;

      const newDate = { ...prevDate };
      if (isDateFromEligible)
        newDate.from = temporaryData.from;
      if (isDateToEligible)
        newDate.to = temporaryData.to;

      newDate.errorKey = dateFromValidation.helperTextKey || dateToValidation.helperTextKey;
      return newDate; // It flat-copied prevDate already
    });
  }, [setter, t, temporaryData]);

  /**
   * Runs validation on a field assuming the input is finished (the
   * user is not typing at least for now). This mostly means not
   * allowing placeholder characters.
   */
  const validateFinished = React.useCallback((value, fieldName) => {
      const newHelperTexts = {};

      if (isBlank(value)) {
        newHelperTexts[fieldName] = 'dateRequired';
        setHelperTextsKeys(prevState => ({ ...prevState, ...newHelperTexts }));
        return;
      }

      const parsed = dayjs(value, DAYJS_FORMATS.DATE, true);
      if (!parsed.isValid()) {
        newHelperTexts[fieldName] = 'dateMalformed';
        setHelperTextsKeys(prevState => ({ ...prevState, ...newHelperTexts }));
      }
    }, []
  );

  /**
   * Validates fields after a value was copied from one field to the
   * other.
   */
  React.useEffect(() => {
    if (!wasArrowClicked)
      return;
    setArrowClicked(false);

    validateFinished(temporaryData.from, 'from');
    validateFinished(temporaryData.to, 'to');
  }, [temporaryData.from, temporaryData.to, validateFinished, wasArrowClicked]);

  function onChange(newValue, fieldName) {
    setTemporaryData(prevDate => {
      if (prevDate[fieldName] === newValue)
        return prevDate;

      const newDate = { ...prevDate };
      newDate[fieldName] = newValue;
      return newDate; // It flat-copied prevDate already
    });
  }

  function onDateFromArrowClick() {
    setArrowClicked(true);
    onChange(temporaryData.from, 'to');
  }

  function onDateToArrowClick() {
    setArrowClicked(true);
    onChange(temporaryData.to, 'from');
  }

  /**
   * Validation of incomplete inputs is skipped in onChange in order
   * to not bother the users as they are typing. This is why another
   * validation layer is necessary when they leave the field.
   */
  function onBlur(evt, fieldName) {
    const value = evt.target.value;
    validateFinished(value, fieldName);
  }

  const dateFromInputProps = {
    endAdornment:
      <Tooltip title={t('dateCopyButtonTooltip')}>
        <IconButton onClick={onDateFromArrowClick} disabled={!!helperTextsKeys.from}>
          <EastIcon/>
        </IconButton>
      </Tooltip>,
  };

  const dateToInputProps = {
    endAdornment:
      <Tooltip title={t('dateCopyButtonTooltip')}>
        <IconButton onClick={onDateToArrowClick} disabled={!!helperTextsKeys.to}>
          <WestIcon/>
        </IconButton>
      </Tooltip>,
  };

  return (
    <Grid container spacing={2}>
      <Grid item sm={6} xs={12}>
        <MaskedDateField
          label={t('dateFrom')}
          mask={MASKS.DATE}
          value={temporaryData.from || ''}
          setter={newValue => onChange(newValue, 'from')}
          onBlur={(evt) => onBlur(evt, 'from')}
          disableFuture
          InputProps={dateFromInputProps}
          error={!!helperTextsKeys.from}
          helperText={helperTextsKeys.from ? t(helperTextsKeys.from) : ' '}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <MaskedDateField
          label={t('dateTo')}
          mask={MASKS.DATE}
          value={temporaryData.to || ''}
          setter={newValue => onChange(newValue, 'to')}
          onBlur={(evt) => onBlur(evt, 'to')}
          disableFuture
          InputProps={dateToInputProps}
          error={!!helperTextsKeys.to}
          helperText={helperTextsKeys.to ? t(helperTextsKeys.to) : ' '}
        />
      </Grid>
    </Grid>
  );
}

DateFields.propTypes = {
  //FIXME komponent wykoleja się, jeśli parametry są klasy Date
  date: PropTypes.shape(({
    from: PropTypes.oneOfType([
      PropTypes.string, PropTypes.instanceOf(Date),
    ]),
    to:   PropTypes.oneOfType([
      PropTypes.string, PropTypes.instanceOf(Date),
    ]),
    errorKey: PropTypes.string,
  })).isRequired,
  setDate: PropTypes.func.isRequired,
};

export default React.memo(DateFields)