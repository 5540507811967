export function setCookie(name, value, options) {
  const opts = {
    path: '/',
    ...options,
  };

  if (!navigator.cookieEnabled) {
    return;
  }
  const cookieName = encodeURIComponent(name);
  const cookieVal  = encodeURIComponent(value);
  let cookieText   = cookieName + '=' + cookieVal;

  if (opts.days && typeof opts.days === 'number') {
    const data = new Date();
    data.setTime(data.getTime() + (opts.days * 24 * 60 * 60 * 1000));
    cookieText += '; expires=' + data.toUTCString();
  }

  if (opts.path) { // spreading options into opts above might overwrite path, so we have to check
    cookieText += '; path=' + opts.path;
  }
  if (opts.domain) {
    cookieText += '; domain=' + opts.domain;
  }
  if (opts.secure) {
    cookieText += '; secure';
  }
  if (opts.sameSite) {
    cookieText += '; SameSite=' + opts.sameSite;
  } else {
    cookieText += '; SameSite=Lax'; // This way it doesn't throw warning that SameSite is missing
  }

  document.cookie = cookieText;
}

export function getCookie(name) {
  if (!document.cookie) {
    return undefined;
  }
  const cookies = document.cookie.split(/; */);

  for (const cookie of cookies) {
    const [cookieName, cookieVal] = cookie.split('=');
    if (cookieName === decodeURIComponent(name)) {
      return decodeURIComponent(cookieVal);
    }
  }
}