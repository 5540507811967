import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  paginationStyles: {
    backgroundColor: '#FFF',
  },
  fetchingToolbar: {
    textAlign: 'center',
    width:     '100%',
  },
  alertContainer: {
    width:         '100%',
    minHeight:     0,
    maxHeight:     '2.5vh',
    paddingTop:    0,
    paddingBottom: 0,
    marginTop:     0,
    marginBottom:  0,
  },
  alertPaper: {
    padding: 0,
  },
}));

export default useStyles;