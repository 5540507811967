import forge from 'node-forge'


const ENCRYPTION_ALGORITHM = 'RSA-OAEP';

/**
 * Takes payload and public key and returns a ready to be sent
 * cryptogram.
 *
 * Applies RSA/None/OAEPWITHSHA-256ANDMGF1PADDING algorithm.
 *
 * @param payload the message to encrypt as a byte string.
 * @param pubKey the RSA key to use, in PEM format
 * @returns {string} base64-encoded cryptogram
 */
export default function encryptionWrapper(payload, pubKey) {
  const forgePublicKey = forge.pki.publicKeyFromPem(pubKey);

  //copied from
  //https://github.com/enketo/enketo-express/blob/master/public/js/src/module/encryptor.js
  //(search for "RSA/NONE/OAEPWithSHA256AndMGF1Padding")
  const encryptedPin = forgePublicKey.encrypt(payload, ENCRYPTION_ALGORITHM, {
    mgf: forge.mgf.mgf1.create(forge.md.sha1.create()), //mgf = mask generation function, the created one is based on sha-1
    md: forge.md.sha256.create(), //md = message digest, the called function creates a SHA-256 message digest object
  });

  return forge.util.encode64(encryptedPin);
}