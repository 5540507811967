import React from 'react';

import PropTypes  from 'prop-types';
import Grid       from '@mui/material/Grid';
import Typography from '@mui/material/Typography';


function LabelledPieceOfInfo(props) {
  return (
    <Grid container direction='column'>
      <Grid item>
        <Typography>
          {props.label}
        </Typography>
      </Grid>
      <Grid item>
        <Typography fontWeight='semibold'>
          {props.info}
        </Typography>
      </Grid>
    </Grid>
  );
}

LabelledPieceOfInfo.propTypes = {
  label: PropTypes.node.isRequired,
  info:  PropTypes.node.isRequired,
};

export default LabelledPieceOfInfo;