import React        from 'react';
import { useMatch } from 'react-router-dom';
import useStyles    from './buttonNavLinkStyles';

import Button      from '@mui/material/Button';
import { NavLink } from 'react-router-dom';
import PropTypes   from 'prop-types';


function ButtonNavLink(props) {

  const { classes } = useStyles();
  const isMatch     = useMatch(props.to);

  const defaultButtonProps   = {
    className: classes.defaultButton,
  }
  const chosenTabButtonProps = {
    disabled:  true,
    className: classes.selectedButton,
  };

  const buttonProps = isMatch ? chosenTabButtonProps : defaultButtonProps;

  if (props.disabled) {
    buttonProps.disabled = props.disabled;
    return (
      <Button {...buttonProps}>
        {props.children}
      </Button>
    )
  }

  return (
    <NavLink {...props}>
      <Button {...buttonProps}>
        {props.children}
      </Button>
    </NavLink>
  );
}

ButtonNavLink.propTypes = {
  disabled: PropTypes.bool,
  ...NavLink.propTypes
};

export default ButtonNavLink;