import useGET                  from 'rest/useGET';
import React                   from 'react';
import { AuthContext }         from 'App';
import createRestRequestConfig from 'rest/createRestRequestConfig';
import { FINTECH_PATHS }       from 'rest/const';
import { useParams }           from 'react-router-dom';


export default function useMerchantWhitelist() {

  const urlParams    = useParams();
  const cardId       = urlParams.cardId;
  const { userInfo } = React.useContext(AuthContext);
  const axiosCfg     = createRestRequestConfig(userInfo.accessToken, true);

  const path = FINTECH_PATHS.merchantWhitelist.replace("{cardId}", cardId);

  const merchantWhitelistGET = useGET(path, axiosCfg, undefined);
  const response             = merchantWhitelistGET.data;
  const merchantWhitelist    = response?.data;

  return {
    merchantWhitelist,
    ...merchantWhitelistGET,
  };
}