import { makeStyles } from 'tss-react/mui';


const useStyles = makeStyles()(() => ({
  dialogPaper: {
    minHeight: 311,
  },
}));

export default useStyles;
