import React from 'react';

import PropTypes          from 'prop-types';
import Accordion          from '@mui/material/Accordion';
import useStyles          from './historyTableToolbarStyles';
import AccordionSummary   from '@mui/material/AccordionSummary';
import Typography         from '@mui/material/Typography';
import AccordionDetails   from '@mui/material/AccordionDetails';
import Grid               from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon     from '@mui/icons-material/ExpandMore';
import DateFields         from '../../components/DateFields';
import DescriptionField   from './DescriptionField';
import AmountFields       from './AmountFields';


function HistoryTableToolbar(props) {

  const { classes } = useStyles();
  const { t }       = useTranslation('cardDetails');

  return (
    <Accordion
      expanded={props.filtersExpanded}
      onChange={(_, expanded) => props.setFiltersExpanded(expanded)}
      className={classes.tableToolbar}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon/>}
        className={classes.accordionSummary}
      >
        <Typography className={classes.accordionSummaryTitle}>
          {t('accordionSummary')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.detailsContainer}>
        <Grid container direction='column' spacing={2}>
          <Grid container item spacing={2}>
            <Grid item sm={4} xs={12} minWidth={'min(100%, 320px)'}>
              <DateFields
                date={props.dateFilterData}
                setDate={props.setDateFilterData}
              />
            </Grid>
            <Grid item xs minWidth={100}>
              <DescriptionField
                description={props.descriptionFilterData}
                setDescription={props.setDescriptionFilterData}
              />
            </Grid>
          </Grid>
          <Grid item>
            <AmountFields
              amount={props.amountFilterData}
              setAmount={props.setAmountFilterData}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

HistoryTableToolbar.propTypes = {
  filtersExpanded:    PropTypes.bool.isRequired,
  setFiltersExpanded: PropTypes.func.isRequired,

  amountFilterData:      PropTypes.shape({
    from:     PropTypes.string,
    to:       PropTypes.string,
    errorKey: PropTypes.string,
    include:  PropTypes.oneOf(['debit', 'credit', 'all']),
  }),
  dateFilterData:        PropTypes.shape(({
    from: PropTypes.oneOfType([
      PropTypes.string, PropTypes.instanceOf(Date),
    ]),
    to:   PropTypes.oneOfType([
      PropTypes.string, PropTypes.instanceOf(Date),
    ]),
    errorKey: PropTypes.string,
  })),
  descriptionFilterData: PropTypes.string,

  setAmountFilterData:      PropTypes.func.isRequired,
  setDateFilterData:        PropTypes.func.isRequired,
  setDescriptionFilterData: PropTypes.func.isRequired,
};

export default HistoryTableToolbar;