import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  dialogPaper: {
    //minimal size selected by trial and error to prevent resizing
    //when internal elements change
    minHeight: 478,
  },
}));

export default useStyles;
