import { makeStyles } from 'tss-react/mui';


const useStyles = makeStyles()((theme) => ({
  table: {
    borderSpacing: theme.spacing(2),
  },
  saveButton: {
    marginLeft: theme.spacing(2),
  },
  headerCell: {
    fontWeight: 'normal',
  },
}),);

export default useStyles;