import { makeStyles } from 'tss-react/mui';


//CAUTION, by design these styles are used by more than one component
const useStyles = makeStyles()(() => ({
  dialogContent:   {
    //on Chrome scroll showed up for no real reason...
    overflowY: 'unset',
  },
}));

export default useStyles;
