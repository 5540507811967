import React                        from 'react';
import PropTypes                    from 'prop-types';
import { DeleteTokenDialogContext } from 'pages/CardDetailsPage/tabs/MobilePaymentsTab/MobilePaymentsTab';
import { useTranslation }           from 'react-i18next';
import Button                       from '@mui/material/Button';


function DeleteTokenButton(props) {

  const { t } = useTranslation('common');

  const setTokenToDelete = React.useContext(DeleteTokenDialogContext);

  return (
    <Button
      color='error'
      onClick={() => setTokenToDelete(props.token)}
    >
      {t('deleteButton')}
    </Button>
  );
}

DeleteTokenButton.propTypes = {
  token: PropTypes.shape({
    tokenReferenceId: PropTypes.string.isRequired,
  }),
};

export default DeleteTokenButton;