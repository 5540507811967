import { createTheme } from '@mui/material/styles';

import backgroundImage from 'resources/img/background.jpg';

const secondaryBackgroundColor = {
  main:         '#ddd',
  contrastText: 'black',
};

const fontWeights = {
  fontWeightThin:     '100 !important',
  fontWeightLight:    '300 !important',
  fontWeightRegular:  '400 !important',
  fontWeightMedium:   '500 !important',
  fontWeightSemibold: '600 !important',
  fontWeightBold:     '700 !important',
};


const theme = createTheme({
  typography: {
    fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
    ...fontWeights,
  },
  palette: {
    background: {
      default: "rgb(1, 51, 148)",  //Background behind the background image
    },
    warning: {
      main: '#ed6c02', //Default https://mui.com/material-ui/customization/palette/#default-values
      backgroundColor: '#ff8b00',
    },
    primary: {
      main: '#213983',
    },
    secondary: {
      main: '#ec008c',
    },

    //custom fields:
    secondaryBackgroundColor: secondaryBackgroundColor,
  },

  components: {
    MuiButton:      {
      defaultProps:   {
        variant: 'contained'
      },
      styleOverrides: {
        root: {
          fontWeight:    fontWeights.fontWeightMedium,
          textTransform: 'capitalize',
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard'
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundImage:  `url(${backgroundImage})`,
          backgroundSize:   'cover',
          backgroundRepeat: 'no-repeat'
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          textAlign: 'center',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          minHeight: '0px !important', //To prevent expanded Accordion from inflating its header
        },
        content: {
          margin: '0.75rem !important', //To prevent expanded Accordion from inflating its header
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          marginBottom: '0px !important', //To prevent expanded Accordion from creating gap between Accordion and next element
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontFamily: '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif !important',
          fontWeight: fontWeights.fontWeightRegular,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
          fontWeight: fontWeights.fontWeightRegular,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          //Select for whatever reason changes height to 'auto' and it becomes '1.9em'
          height: '1.4375em', //Default height of TextField
        },
      },
    },

    MuiTableRow: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFF !important',
        },
        head: {
          backgroundColor: secondaryBackgroundColor.main + ' !important',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          whiteSpace: 'nowrap !important',
        },
        body: {
          fontFamily: '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
          fontWeight: fontWeights.fontWeightRegular,
          fontSize:   '1rem',
        },
        head: {
          fontWeight: fontWeights.fontWeightSemibold,
          fontSize:   '1rem',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          fontWeight: fontWeights.fontWeightRegular,
          fontSize:   '1rem',
        },
        selectLabel: {
          fontWeight: fontWeights.fontWeightRegular,
          fontSize:   '1rem',
        },
        displayedRows: {
          fontWeight: fontWeights.fontWeightRegular,
          fontSize:   '1rem',
        },
      },
    },

    //custom fields, notice no 'Mui' prefix:
    Cell: {
      debit:  {
        fontWeight: 'normal',
      },
      credit: {
        color:      'green',
        fontWeight: 'bold',
      }
    },
  }

});

export default theme;